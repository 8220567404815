import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import { ArticleContentBlockModel } from "@admin/domain/articles/models/ArticleContentBlockModel";
import { ColorTabBannersModel } from "@admin/domain/articles/models/ArticleBlockValueModel";
import { ArticleBlockType } from "@admin/domain/articles/enums/ArticleBlockType";
import MultiLanguages from "@admin/components/MultiLanguage";

type Banner = Partial<ColorTabBannersModel[`color_tab_banners`][0]>;

type Props = {
    block: ArticleContentBlockModel & {
        type: ArticleBlockType.ColorTabs;
    };
    index: number;
};

export default function ColorTabsBlock({ block: { id, value }, index }: Props) {
    const initBanners = (value?.color_tab_banners || []).map(
        (value: Banner) => ({
            ...value,
            id: uuid(),
        }),
    );
    const [banners, setBanners] = useState<Banner[]>(initBanners);

    return (
        <>
            <div>
                <input
                    type="hidden"
                    name={`blocks[${index}][value][id]`}
                    value={id}
                />

                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <div className="mb-5">
                                <label className="form-label">Заголовок</label>
                                <input
                                    className="form-control"
                                    name={`blocks[${index}][value][title][${language}]`}
                                    defaultValue={
                                        value?.title?.[language] || ``
                                    }
                                />
                            </div>
                        </div>
                    )}
                />
            </div>

            <div className="row row-cols-auto">
                {banners.map((banner, index) => (
                    <div key={banner.id} className="col mb-7">
                        <MultiLanguages
                            languageElement={(language) => (
                                <div>
                                    <div className="mb-7">
                                        <label className="form-label">
                                            Заголовок
                                        </label>
                                        <textarea
                                            className="form-control"
                                            rows={5}
                                            cols={50}
                                            name={`blocks[${index}][value][color_tab_banners][${index}][title][${language}]`}
                                            defaultValue={
                                                banner?.title?.[language] || ``
                                            }
                                        />
                                    </div>
                                    <div className="mb-7">
                                        <label className="form-label">
                                            Текст
                                        </label>
                                        <textarea
                                            className="form-control"
                                            rows={5}
                                            cols={50}
                                            name={`blocks[${index}][value][color_tab_banners][${index}][text][${language}]`}
                                            defaultValue={
                                                banner?.text?.[language] || ``
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        />
                        <div>
                            <a
                                className="card-link text-danger"
                                onClick={() => {
                                    setBanners((prev) =>
                                        prev.filter(
                                            (value) => value.id !== banner.id,
                                        ),
                                    );
                                }}
                            >
                                Удалить
                            </a>
                        </div>
                    </div>
                ))}

                <div className="mb-5 d-flex">
                    <div className="me-5">
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={() =>
                                setBanners((prevState) => [
                                    ...prevState,
                                    {
                                        id: uuid(),
                                        title: undefined,
                                        text: undefined,
                                    },
                                ])
                            }
                        >
                            Добавить
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
