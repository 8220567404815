import React from "react";
import { usePromotionProductsContext } from "./ContentPromotionProvider";
import Input from "@admin/components/Input";
import { ContentPromotionBasketModel } from "@admin/domain/complexPromotions/content/models/ContentPromotionBasketModel";
import ProductsSearch from "@admin/components/ProductsSearch";

const ContentPromotionBasketProducts: React.FC = () => {
    const { basketProducts, addBasketProduct, deleteBasketProduct } =
        usePromotionProductsContext();

    return (
        <>
            <h2>Товары в корзине</h2>

            <div className="rounded border p-10 my-5">
                {basketProducts?.map(
                    (
                        basket: ContentPromotionBasketModel,
                        basketIndex: number,
                    ): React.JSX.Element => (
                        <div
                            className="rounded border p-10 my-5"
                            key={basket.id || `new-basket-${basketIndex}`}
                        >
                            {basket.id && (
                                <input
                                    type="hidden"
                                    value={basket.id}
                                    name={`basket_products[${basketIndex}][id]`}
                                />
                            )}
                            <ProductsSearch
                                label="Товар"
                                inputName={`basket_products[${basketIndex}][product_id]`}
                                productId={basket.product_id}
                                productTitle={basket.product_title}
                            />
                            <Input
                                label="Количество"
                                defaultValue={basket.quantity}
                                name={`basket_products[${basketIndex}][quantity]`}
                            />

                            {basketProducts.length > 1 && (
                                <button
                                    type="button"
                                    className="btn btn-danger me-2 mt-5"
                                    onClick={(): void =>
                                        deleteBasketProduct(basketIndex)
                                    }
                                >
                                    Убрать товар
                                </button>
                            )}
                        </div>
                    ),
                )}

                <button
                    type="button"
                    className="btn btn-primary me-2 mt-5"
                    onClick={addBasketProduct}
                >
                    Добавить товар
                </button>
            </div>
        </>
    );
};

export default ContentPromotionBasketProducts;
