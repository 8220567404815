import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import { FileModel } from "@admin/domain/files/model/FileModel";
import { CheckerboardBannerModel } from "@admin/domain/articles/models/ArticleBlockValueModel";
import { ArticleContentBlockModel } from "@admin/domain/articles/models/ArticleContentBlockModel";
import { ArticleBlockType } from "@admin/domain/articles/enums/ArticleBlockType";
import { makePath } from "@admin/utils/makePath";
import { loadFile } from "@admin/domain/products/api";
import LoadingImageInput from "@admin/components/LoadingImageInput";
import MultiLanguages from "@admin/components/MultiLanguage";

type List = Partial<CheckerboardBannerModel>;

type Props = {
    block: ArticleContentBlockModel & {
        type:
            | ArticleBlockType.HorizontalImageWithTextCheckerboard
            | ArticleBlockType.VerticalImageWithTextCheckerboard;
    };
    index: number;
};

export default function ImageWithTextCheckerboardBlock({
    block: { value },
    index,
}: Props) {
    const initList = (value?.checkerboard_banners || []).map((value) => ({
        ...value,
        id: uuid(),
    }));

    const [list, setList] = useState<List[]>(initList);

    async function addFile(video: File | null | undefined, index: number) {
        if (!video) return;

        await loadFile(video).then((video) =>
            setList((prev) =>
                prev.map((prevVal, prevIndex) => {
                    if (prevIndex === index) {
                        prevVal.video_file = video;
                    }
                    return prevVal;
                }),
            ),
        );
    }

    return (
        <>
            <MultiLanguages
                languageElement={(language) => (
                    <>
                        <div className="mb-5">
                            <label className="form-label">Заголовок</label>
                            <textarea
                                className="form-control"
                                name={`blocks[${index}][value][title][${language}]`}
                                defaultValue={value?.title?.[language] || ``}
                            />
                        </div>

                        <div className="mb-5">
                            <label className="form-label">
                                Текст под заголовком
                            </label>
                            <textarea
                                className="form-control"
                                name={`blocks[${index}][value][sub_title][${language}]`}
                                defaultValue={
                                    value?.sub_title?.[language] || ``
                                }
                            />
                        </div>
                    </>
                )}
            />

            <div className="row row-cols-auto">
                {list.map((el, index) => (
                    <div key={el.id} className="col mb-5">
                        <MultiLanguages
                            languageElement={(language) => (
                                <>
                                    <div className="mb-5">
                                        <label className="form-label">
                                            Заголовок
                                        </label>
                                        <input
                                            className="form-control"
                                            name={`blocks[${index}][value][checkerboard_banners][${index}][title][${language}]`}
                                            defaultValue={
                                                el.title?.[language] || ``
                                            }
                                        />
                                    </div>

                                    <div className="mb-5">
                                        <label className="form-label">
                                            Описание
                                        </label>
                                        <textarea
                                            className="form-control"
                                            name={`blocks[${index}][value][checkerboard_banners][${index}][description][${language}]`}
                                            defaultValue={
                                                el.description?.[language] || ``
                                            }
                                        />
                                    </div>

                                    <div className="mb-5">
                                        <label className="form-label">
                                            Текст кнопки ссылки
                                        </label>
                                        <input
                                            className="form-control"
                                            name={`blocks[${index}][value][checkerboard_banners][${index}][button_text][${language}]`}
                                            defaultValue={
                                                el.button_text?.[language] || ``
                                            }
                                        />
                                    </div>
                                </>
                            )}
                        />

                        <div className="mb-5">
                            <label className="form-label">Ссылка</label>
                            <input
                                className="form-control"
                                name={`blocks[${index}][value][checkerboard_banners][${index}][url]`}
                                defaultValue={el.url || ``}
                            />
                        </div>

                        <div className="col mb-5">
                            <LoadingImageInput
                                width="300px"
                                height="300px"
                                src={
                                    el.file
                                        ? makePath(el.file.path as string)
                                        : ``
                                }
                                onChange={(file) =>
                                    setList((image) =>
                                        image.map((imageVal, imageIndex) => {
                                            if (imageIndex === index) {
                                                imageVal.file = file;
                                            }
                                            return imageVal;
                                        }),
                                    )
                                }
                            />

                            <input
                                type="hidden"
                                name={`blocks[${index}][value][checkerboard_banners][${index}][file_id]`}
                                value={el.file?.id}
                            />
                        </div>

                        <h4>Видео</h4>

                        <MultiLanguages
                            languageElement={(language) => (
                                <div className="mb-5">
                                    <label className="form-label">
                                        Текст кнопки просмотра видео
                                    </label>
                                    <input
                                        className="form-control"
                                        name={`blocks[${index}][value][checkerboard_banners][${index}][video_button_text][${language}]`}
                                        defaultValue={
                                            el.video_button_text?.[language] ||
                                            ``
                                        }
                                    />
                                </div>
                            )}
                        />

                        <div className="mb-5">
                            <label className="form-label">Ссылка</label>
                            <input
                                className="form-control"
                                name={`blocks[${index}][value][checkerboard_banners][${index}][video_url]`}
                                defaultValue={el.video_url || ``}
                            />
                        </div>

                        <div className="row">
                            <h4 className="mb-5">Видео файл</h4>

                            {!el.video_file && (
                                <div className="mb-5">
                                    <div className="mb-5">
                                        <div>
                                            <label className="form-label">
                                                Видео файл
                                            </label>

                                            <input
                                                type="file"
                                                className="form-control"
                                                name="file"
                                                accept="video/*"
                                                onChange={(event) =>
                                                    setList((prev) =>
                                                        prev.map(
                                                            (
                                                                val,
                                                                prevIndex,
                                                            ) => {
                                                                if (
                                                                    prevIndex ===
                                                                    index
                                                                ) {
                                                                    val.add_video_file =
                                                                        event.target.files?.item(
                                                                            0,
                                                                        ) as File;
                                                                }
                                                                return val;
                                                            },
                                                        ),
                                                    )
                                                }
                                            />

                                            <input
                                                type="hidden"
                                                name={`blocks[${index}][value][checkerboard_banners][${index}][video_file_id]`}
                                                defaultValue={undefined}
                                            />
                                        </div>
                                    </div>

                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() =>
                                            addFile(el?.add_video_file, index)
                                        }
                                    >
                                        Загрузить файл
                                    </button>
                                </div>
                            )}

                            {el.video_file && (
                                <div className="card border mb-4 position-relative">
                                    <div className="card-body">
                                        <div className="d-flex flex-column justify-content-center">
                                            <div>
                                                <span className="fw-bolder">
                                                    Название файла:
                                                </span>
                                                {el.video_file?.name}
                                            </div>

                                            <div>
                                                <span className="fw-bolder">
                                                    Формат файла:
                                                </span>
                                                {el.video_file?.mime}
                                            </div>

                                            <div>
                                                <span className="fw-bolder">
                                                    Дата загрузки:
                                                </span>
                                                {el.video_file?.created_at}
                                            </div>

                                            <a
                                                href={
                                                    el.video_file
                                                        ? makePath(
                                                              el.video_file
                                                                  .path,
                                                          )
                                                        : ``
                                                }
                                                target="_blank"
                                                className="fw-bolder"
                                            >
                                                Скачать видео
                                            </a>

                                            <input
                                                type="hidden"
                                                name={`blocks[${index}][value][checkerboard_banners][${index}][video_file_id]`}
                                                defaultValue={el.video_file?.id}
                                            />
                                        </div>
                                    </div>

                                    <i
                                        className="bi bi-x-circle position-absolute text-danger end-0 mt-5 me-5 cursor-pointer"
                                        onClick={() => {
                                            setList((prev) =>
                                                prev.map((val, prevIndex) => {
                                                    if (prevIndex === index) {
                                                        val.video_file =
                                                            undefined;
                                                    }
                                                    return val;
                                                }),
                                            );
                                        }}
                                    />
                                </div>
                            )}

                            <div>
                                <a
                                    className="card-link text-danger"
                                    onClick={() => {
                                        setList((prev) =>
                                            prev.filter(
                                                (value) => value.id !== el.id,
                                            ),
                                        );
                                    }}
                                >
                                    Удалить
                                </a>
                            </div>
                        </div>

                        {el.files?.map((file: FileModel, subIndex: number) => (
                            <div key={file.id} className="col mb-5">
                                <div className="card border mb-4 position-relative">
                                    <div className="card-body">
                                        <div className="d-flex flex-column justify-content-center">
                                            <div>
                                                <span className="fw-bolder">
                                                    Название файла:
                                                </span>
                                                {file?.name}
                                            </div>
                                            <div>
                                                <span className="fw-bolder">
                                                    Формат файла:
                                                </span>
                                                {file?.mime}
                                            </div>
                                            <a
                                                href={
                                                    file
                                                        ? makePath(file.path)
                                                        : ``
                                                }
                                                target="_blank"
                                                className="fw-bolder"
                                            >
                                                Скачать файл
                                            </a>
                                            <input
                                                type="hidden"
                                                name={`block[${index}][value][checkerboard_banners][${index}][files][${subIndex}][file_id]`}
                                                defaultValue={file?.id}
                                            />
                                        </div>
                                    </div>

                                    <i
                                        className="bi bi-x-circle position-absolute text-danger end-0 mt-5 me-5 cursor-pointer"
                                        onClick={() => {
                                            setList((prev) =>
                                                prev.map((val, prevIndex) => {
                                                    if (
                                                        prevIndex === subIndex
                                                    ) {
                                                        val.file = undefined;
                                                    }
                                                    return val;
                                                }),
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            <div className="mb-5 d-flex">
                <div className="me-5">
                    <button
                        type="button"
                        className="btn btn-success"
                        onClick={() =>
                            setList((elState) => [
                                ...elState,
                                {
                                    id: uuid(),
                                    title: undefined,
                                },
                            ])
                        }
                    >
                        Добавить
                    </button>
                </div>
            </div>
        </>
    );
}
