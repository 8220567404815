import { post } from "../api";
import { ProductCategoryModel } from "@admin/domain/productCategory/models/ProductCategoryModel";

async function createProductCategory(
    body: FormData,
): Promise<ProductCategoryModel> {
    return await post({ url: `/admin/product_categories`, body });
}

async function updateProductCategory(
    id: ProductCategoryModel[`id`],
    body: FormData,
): Promise<ProductCategoryModel> {
    return await post({ url: `/admin/product_categories/` + id, body });
}

export async function saveProductCategory(
    category: ProductCategoryModel | null,
    body: FormData,
): Promise<ProductCategoryModel> {
    return category
        ? updateProductCategory(category.id, body)
        : await createProductCategory(body);
}
