import React from "react";

import { type PageSeoParamsModel } from "@admin/domain/seo/models/PageSeoParamsModel";
import { makePath } from "@admin/utils/makePath";
import Input from "@admin/components/ImageInput";

type Props = {
    label: string;
    name: string;
    defaultValue: PageSeoParamsModel[`image`] | undefined;
    fallback: PageSeoParamsModel[`fallback`][`image`] | undefined;
};

export default function ImageInput({
    label,
    name,
    defaultValue,
    fallback,
}: Props) {
    return (
        <div className="p-10 my-5 rounded border">
            <Input
                label={label}
                name={name}
                defaultValue={defaultValue}
                height={200}
                thumbnailHeight={200}
                thumbnailWidth={200}
                className={{
                    container: ``,
                }}
            />

            {fallback && fallback.value && (
                <div>
                    <div className="form-label mb-0">
                        Значение из{` `}
                        <a href={`/admin/seo/templates/${fallback.id}`}>
                            родительского шаблона
                        </a>
                        :
                    </div>
                    <a
                        href={makePath(fallback.value.path)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={makePath(fallback.value.path)}
                            className="img-thumbnail"
                            style={{ height: `100px` }}
                            alt=""
                        />
                    </a>
                </div>
            )}
        </div>
    );
}
