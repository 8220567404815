import React, { useEffect, useState } from "react";
import { ArticleVideoSourceType } from "@admin/domain/articles/enums/ArticleVideoSourceType";
import Input from "@admin/components/Input";
import { makePath } from "@admin/utils/makePath";
import { FileModel } from "@admin/domain/files/model/FileModel";
import { loadFile } from "@admin/domain/products/api";

type ArticleVideoProps = {
    article?: any;
};

export default function ArticleVideo({ article }: ArticleVideoProps) {
    const [files, setFiles] = useState<{
        video: File | null;
        preview: File | null;
    }>({ video: null, preview: null });
    const [video, setVideo] = useState<FileModel | null>(article?.video?.file);
    const [videoSourceType, setVideoSourceType] = useState(
        article?.video?.source_type,
    );

    const onChangeVideoSourceType = (type: ArticleVideoSourceType) => {
        setVideoSourceType(type);
    };

    async function addFile() {
        if (!files.video || !files.preview) return;

        await loadFile(files.video, files.preview).then((video) =>
            setVideo(video),
        );
    }

    useEffect(() => {
        if (article?.video?.file) {
            setVideo(article?.video?.file);
        }
        if (article?.video?.source_type) {
            setVideoSourceType(article?.video?.source_type);
        }
    }, [article?.video?.file]);
    return (
        <div>
            <h2>Выберите тип видео</h2>
            <div className="mb-10 p-10">
                <div className="form-check form-check-inline form-check-solid">
                    <input
                        key="checkbox-link"
                        className="form-check-input"
                        type="radio"
                        checked={
                            videoSourceType === ArticleVideoSourceType.Youtube
                        }
                        onChange={() =>
                            onChangeVideoSourceType(
                                ArticleVideoSourceType.Youtube,
                            )
                        }
                    />
                    <label
                        className="form-check-label"
                        htmlFor={`checkbox-link`}
                    >
                        Ссылка на видео в Youtube
                    </label>
                </div>
                <div className="form-check form-check-inline form-check-solid">
                    <input
                        key="checkbox-file"
                        className="form-check-input"
                        type="radio"
                        checked={
                            videoSourceType === ArticleVideoSourceType.File
                        }
                        onChange={() =>
                            onChangeVideoSourceType(ArticleVideoSourceType.File)
                        }
                    />
                    <label
                        className="form-check-label"
                        htmlFor={`checkbox-file`}
                    >
                        Видео файл
                    </label>
                </div>
            </div>

            {videoSourceType === ArticleVideoSourceType.File && (
                <>
                    {!video && (
                        <div className="mb-5">
                            <div className="mb-5">
                                <div>
                                    <label className="form-label">
                                        Видео файл
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        name="file"
                                        accept="video/*"
                                        onChange={(event) =>
                                            setFiles((prevState) => ({
                                                ...prevState,
                                                video: event.target.files?.item(
                                                    0,
                                                ) as File,
                                            }))
                                        }
                                    />
                                </div>
                                <div className="mt-5">
                                    <label className="form-label">
                                        Превью для видео файла
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        name="file"
                                        accept="image/*"
                                        onChange={(event) =>
                                            setFiles((prevState) => ({
                                                ...prevState,
                                                preview:
                                                    event.target.files?.item(
                                                        0,
                                                    ) as File,
                                            }))
                                        }
                                    />
                                </div>
                            </div>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={addFile}
                            >
                                Загрузить файл
                            </button>
                        </div>
                    )}
                    {video && (
                        <div>
                            <input
                                type="hidden"
                                name={`video[type]`}
                                defaultValue={ArticleVideoSourceType.File}
                            />
                            <div className="card border mb-4 position-relative">
                                <div className="card-body">
                                    <div className="d-flex flex-column justify-content-center">
                                        <div>
                                            <span className="fw-bolder">
                                                Название файла:
                                            </span>
                                            {video?.name}
                                        </div>
                                        <div>
                                            <span className="fw-bolder">
                                                Формат файла:
                                            </span>
                                            {video?.mime}
                                        </div>
                                        <div>
                                            <span className="fw-bolder">
                                                Дата загрузки:
                                            </span>
                                            {video?.created_at}
                                        </div>
                                        <a
                                            href={
                                                article?.video?.file
                                                    ? makePath(video.path)
                                                    : ``
                                            }
                                            target="_blank"
                                            className="fw-bolder"
                                        >
                                            Скачать видео
                                        </a>
                                        <input
                                            type="hidden"
                                            name={`video[file_id]`}
                                            defaultValue={video.id}
                                        />
                                    </div>
                                </div>
                                <h4 className="mb-5">Превью файл</h4>
                                <div className="card-body">
                                    <div className="d-flex flex-column justify-content-center">
                                        <div>
                                            <span className="fw-bolder">
                                                Название файла:
                                            </span>
                                            {video.preview_file?.name}
                                        </div>
                                        <div>
                                            <span className="fw-bolder">
                                                Формат файла:
                                            </span>
                                            {video.preview_file?.mime}
                                        </div>
                                        <div>
                                            <span className="fw-bolder">
                                                Дата загрузки:
                                            </span>
                                            {video.preview_file?.created_at}
                                        </div>
                                        <a
                                            href={
                                                video.preview_file
                                                    ? makePath(
                                                          video.preview_file
                                                              .path,
                                                      )
                                                    : ``
                                            }
                                            target="_blank"
                                            className="fw-bolder"
                                        >
                                            Скачать превью
                                        </a>
                                        <input
                                            type="hidden"
                                            name={`video[preview_file_id]`}
                                            defaultValue={
                                                video.preview_file?.id
                                            }
                                        />
                                    </div>
                                </div>
                                <i
                                    className="bi bi-x-circle position-absolute text-danger end-0 mt-5 me-5 cursor-pointer"
                                    onClick={() => {
                                        setVideo(null);
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
            {videoSourceType === ArticleVideoSourceType.Youtube && (
                <>
                    <input
                        type="hidden"
                        name={`video[type]`}
                        defaultValue={ArticleVideoSourceType.Youtube}
                    />

                    <Input
                        label="Ссылка на видео"
                        defaultValue={article?.video?.youtube_link}
                        name="video[youtube_link]"
                    />
                </>
            )}
        </div>
    );
}
