import React, { createRef, useState } from "react";
import { getProductByCharacteristicId } from "@admin/domain/products/api";
import { ProductModel } from "@admin/domain/products/models/ProductModel";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import convertToMapLanguageObject from "@admin/domain/languages/Languages";
import { useIsInvalid } from "@admin/components/FormErrorsContext";

export default function ProductVariation({
    productVariations,
}: {
    productVariations: ProductModel[];
}) {
    const currentLanguages = useLanguageContext().currentLanguage;
    const inputRef = createRef<HTMLInputElement>();

    const [variations, setVariations] = useState(productVariations);

    const isInvalid = useIsInvalid(
        `additional_product_attributes[product_variations]`,
    );

    async function addVariation() {
        const characteristicId = inputRef.current?.value;
        if (!characteristicId) return;

        await getProductByCharacteristicId(characteristicId).then(
            (variation) => {
                if (!variation) return;

                variation.textsMap = convertToMapLanguageObject(
                    variation.texts,
                );
                setVariations((prevState) => [...prevState, variation]);
            },
        );
    }

    return (
        <div
            className={
                `rounded border p-10 mb-5` + (isInvalid ? ` border-danger` : ``)
            }
        >
            <h4 className="mb-5">Вариации товара</h4>

            <div className="input-group mb-5 w-50">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Характеристика товара"
                    ref={inputRef}
                />
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => addVariation()}
                >
                    Добавить
                </button>
            </div>

            {variations.map((variation) => (
                <div
                    className="card border mb-4 position-relative"
                    key={variation.id}
                >
                    <div className="card-body">
                        <input
                            type="hidden"
                            defaultValue={variation.id}
                            name="additional_product_attributes[product_variations][]"
                        />

                        <div>
                            <span className="fw-bolder">UUID:</span>
                            {` `}
                            {variation.characteristic_id}
                        </div>
                        <div>
                            <span className="fw-bolder">Название:</span>
                            {` `}
                            {variation.textsMap.get(currentLanguages)?.title}
                        </div>
                    </div>

                    <i
                        className="bi bi-x-circle position-absolute text-danger end-0 mt-5 me-5 cursor-pointer"
                        onClick={() =>
                            setVariations((prevState) =>
                                prevState.filter(
                                    (prevVariation) =>
                                        prevVariation.id !== variation.id,
                                ),
                            )
                        }
                    />
                </div>
            ))}
        </div>
    );
}
