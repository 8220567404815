import React from "react";

import { type AmountPromotionDiscountModel } from "@admin/domain/complexPromotions/amount/models/AmountPromotionDiscountModel";
import ProductsSearch from "@admin/components/ProductsSearch";

type AmountPromotionDiscountsProps = {
    discountProducts: ReadonlyArray<AmountPromotionDiscountModel>;
    levelIndex: number;
    deleteDiscount: (levelIndex: number, discountIndex: number) => void;
};

const AmountPromotionDiscounts: React.FC<AmountPromotionDiscountsProps> = ({
    discountProducts,
    levelIndex,
    deleteDiscount,
}) => {
    if (discountProducts.length === 0) {
        return null;
    }

    return (
        <>
            <h3>Скидки</h3>
            {discountProducts.map(
                (
                    discount: AmountPromotionDiscountModel,
                    discountIndex: number,
                ): React.JSX.Element => (
                    <div key={discount.id || `new-discount-${discountIndex}`}>
                        <div className="d-flex">
                            <div className="rounded border p-10 my-5 me-5 w-100">
                                <ProductsSearch
                                    label="Заменяемый товар"
                                    productId={discount.replaceable_product_id}
                                    productTitle={
                                        discount.replaceable_product_title
                                    }
                                    inputName={`levels[${levelIndex}][discount_products][${discountIndex}][replaceable_product_id]`}
                                />
                            </div>
                            <div className="rounded border p-10 my-5 me-5 w-100">
                                <ProductsSearch
                                    label="Товар по скидке"
                                    productId={discount.discount_product_id}
                                    productTitle={
                                        discount.discount_product_title
                                    }
                                    inputName={`levels[${levelIndex}][discount_products][${discountIndex}][discount_product_id]`}
                                />
                            </div>
                        </div>

                        <div className="d-flex">
                            {discountProducts.length > 1 && (
                                <button
                                    type="button"
                                    className="btn btn-primary me-2 mb-5"
                                    onClick={(): void =>
                                        deleteDiscount(
                                            levelIndex,
                                            discountIndex,
                                        )
                                    }
                                >
                                    Удалить скидку
                                </button>
                            )}
                        </div>
                    </div>
                ),
            )}
        </>
    );
};

export default AmountPromotionDiscounts;
