import React, { useState } from "react";
import MultiLanguages from "@admin/components/MultiLanguage";
import { makePath } from "@admin/utils/makePath";
import { v4 as uuid } from "uuid";
import { loadFile } from "@admin/domain/products/api";
import LoadingImageInput from "@admin/components/LoadingImageInput";
import { FileModel } from "@admin/domain/files/model/FileModel";
import { CheckerboardBannerModel } from "@admin/domain/content/models/ContentBlockValueModel";
import { ContentBlockModel } from "@admin/domain/content/models/ContentBlockModel";

type List = Partial<CheckerboardBannerModel>;

type Props = {
    block: ContentBlockModel;
    index: number;
    key: number;
    onSubmit: (blockId: number) => void;
};

export default function ImageWithTextCheckerboardBlock(props: Props) {
    const initList = (props?.block?.value?.checkerboard_banners || []).map(
        (value: CheckerboardBannerModel) => {
            return { ...value, id: uuid() };
        },
    );

    const [list, setEl] = useState<List[]>(initList);

    async function addFile(video: File | null | undefined, index: number) {
        if (!video) return;

        await loadFile(video).then((video) =>
            setEl((prev) =>
                prev.map((prevVal, prevIndex) => {
                    if (prevIndex === index) {
                        prevVal.video_file = video;
                    }
                    return prevVal;
                }),
            ),
        );
    }

    return (
        <div className="mb-10">
            <h4 className="mb-5">
                Блок с изображениями и текстом в шахматном порядке
            </h4>
            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <div className="mb-5">
                                <label className="form-label" />
                                <textarea
                                    className="form-control"
                                    name={`blocks[${props.index}][value][title][${language}]`}
                                    defaultValue={
                                        props?.block?.value?.title?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                        </div>
                    )}
                />

                <div className="row row-cols-auto">
                    {list.map((el, index) => (
                        <div key={el.id} className="col mb-5">
                            <div>
                                <MultiLanguages
                                    languageElement={(language) => (
                                        <div>
                                            <div className="mb-5">
                                                <label className="form-label">
                                                    Заголовок
                                                </label>
                                                <input
                                                    className="form-control"
                                                    name={`blocks[${props.index}][value][checkerboard_banners][${index}][title][${language}]`}
                                                    defaultValue={
                                                        el.title?.[language] ||
                                                        ``
                                                    }
                                                />
                                            </div>
                                            <div className="mb-5">
                                                <label className="form-label">
                                                    Описание
                                                </label>
                                                <textarea
                                                    className="form-control"
                                                    name={`blocks[${props.index}][value][checkerboard_banners][${index}][description][${language}]`}
                                                    defaultValue={
                                                        el.description?.[
                                                            language
                                                        ] || ``
                                                    }
                                                />
                                            </div>

                                            <div className="mb-5">
                                                <label className="form-label">
                                                    Текст кнопки ссылки
                                                </label>
                                                <input
                                                    className="form-control"
                                                    name={`blocks[${props.index}][value][checkerboard_banners][${index}][button_text][${language}]`}
                                                    defaultValue={
                                                        el.button_text?.[
                                                            language
                                                        ] || ``
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                />
                                <div className="mb-5">
                                    <label className="form-label">Ссылка</label>
                                    <input
                                        className="form-control"
                                        name={`blocks[${props.index}][value][checkerboard_banners][${index}][url]`}
                                        defaultValue={el.url || ``}
                                    />
                                </div>

                                <div key={index} className="col mb-5">
                                    <LoadingImageInput
                                        width="300px"
                                        height="300px"
                                        src={
                                            el.file
                                                ? makePath(
                                                      el.file.path as string,
                                                  )
                                                : ``
                                        }
                                        onChange={(file) =>
                                            setEl((image) =>
                                                image.map(
                                                    (imageVal, imageIndex) => {
                                                        if (
                                                            imageIndex === index
                                                        ) {
                                                            imageVal.file =
                                                                file;
                                                        }
                                                        return imageVal;
                                                    },
                                                ),
                                            )
                                        }
                                    />
                                    <input
                                        type="hidden"
                                        name={`blocks[${props.index}][value][checkerboard_banners][${index}][file_id]`}
                                        value={el.file?.id}
                                    />
                                </div>

                                <h4>Видео</h4>
                                <div>
                                    <MultiLanguages
                                        languageElement={(language) => (
                                            <div>
                                                <div className="mb-5">
                                                    <label className="form-label">
                                                        Текст кнопки просмотра
                                                        видео
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        name={`blocks[${props.index}][value][checkerboard_banners][${index}][video_button_text][${language}]`}
                                                        defaultValue={
                                                            el
                                                                .video_button_text?.[
                                                                language
                                                            ] || ``
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    />
                                    <div className="mb-5">
                                        <label className="form-label">
                                            Ссылка
                                        </label>
                                        <input
                                            className="form-control"
                                            name={`blocks[${props.index}][value][checkerboard_banners][${index}][video_url]`}
                                            defaultValue={el.video_url || ``}
                                        />
                                    </div>
                                    <div className="row">
                                        <h4 className="mb-5">Видео файл</h4>
                                        <br />
                                        <div>
                                            {!el.video_file && (
                                                <div className="mb-5">
                                                    <div className="mb-5">
                                                        <div>
                                                            <label className="form-label">
                                                                Видео файл
                                                            </label>
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                name="file"
                                                                accept="video/*"
                                                                onChange={(
                                                                    event,
                                                                ) =>
                                                                    setEl(
                                                                        (
                                                                            prev,
                                                                        ) =>
                                                                            prev.map(
                                                                                (
                                                                                    val,
                                                                                    prevIndex,
                                                                                ) => {
                                                                                    if (
                                                                                        prevIndex ===
                                                                                        index
                                                                                    ) {
                                                                                        val.add_video_file =
                                                                                            event.target.files?.item(
                                                                                                0,
                                                                                            ) as File;
                                                                                    }
                                                                                    return val;
                                                                                },
                                                                            ),
                                                                    )
                                                                }
                                                            />
                                                            <input
                                                                type="hidden"
                                                                name={`blocks[${props.index}][value][checkerboard_banners][${index}][video_file_id]`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={() =>
                                                            addFile(
                                                                el?.add_video_file,
                                                                index,
                                                            )
                                                        }
                                                    >
                                                        Загрузить файл
                                                    </button>
                                                </div>
                                            )}
                                            {el.video_file && (
                                                <div>
                                                    <div className="card border mb-4 position-relative">
                                                        <div className="card-body">
                                                            <div className="d-flex flex-column justify-content-center">
                                                                <div>
                                                                    <span className="fw-bolder">
                                                                        Название
                                                                        файла:
                                                                    </span>
                                                                    {
                                                                        el
                                                                            .video_file
                                                                            ?.name
                                                                    }
                                                                </div>
                                                                <div>
                                                                    <span className="fw-bolder">
                                                                        Формат
                                                                        файла:
                                                                    </span>
                                                                    {
                                                                        el
                                                                            .video_file
                                                                            ?.mime
                                                                    }
                                                                </div>
                                                                <div>
                                                                    <span className="fw-bolder">
                                                                        Дата
                                                                        загрузки:
                                                                    </span>
                                                                    {
                                                                        el
                                                                            .video_file
                                                                            ?.created_at
                                                                    }
                                                                </div>
                                                                <a
                                                                    href={
                                                                        el.video_file
                                                                            ? makePath(
                                                                                  el
                                                                                      .video_file
                                                                                      .path,
                                                                              )
                                                                            : ``
                                                                    }
                                                                    target="_blank"
                                                                    className="fw-bolder"
                                                                >
                                                                    Скачать
                                                                    видео
                                                                </a>
                                                                <input
                                                                    type="hidden"
                                                                    name={`blocks[${props.index}][value][checkerboard_banners][${index}][video_file_id]`}
                                                                    defaultValue={
                                                                        el
                                                                            .video_file
                                                                            ?.id
                                                                    }
                                                                />
                                                            </div>
                                                        </div>

                                                        <i
                                                            className="bi bi-x-circle position-absolute text-danger end-0 mt-5 me-5 cursor-pointer"
                                                            onClick={() => {
                                                                setEl((prev) =>
                                                                    prev.map(
                                                                        (
                                                                            val,
                                                                            prevIndex,
                                                                        ) => {
                                                                            if (
                                                                                prevIndex ===
                                                                                index
                                                                            ) {
                                                                                val.video_file =
                                                                                    undefined;
                                                                            }
                                                                            return val;
                                                                        },
                                                                    ),
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            <div>
                                                <a
                                                    className="card-link text-danger"
                                                    onClick={() => {
                                                        setEl((prev) =>
                                                            prev.filter(
                                                                (value) =>
                                                                    value.id !==
                                                                    el.id,
                                                            ),
                                                        );
                                                    }}
                                                >
                                                    Удалить
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {el.files?.map(
                                    (file: FileModel, subIndex: number) => (
                                        <div key={file.id} className="col mb-5">
                                            banner.video_file &&
                                            <div>
                                                <div className="card border mb-4 position-relative">
                                                    <div className="card-body">
                                                        <div className="d-flex flex-column justify-content-center">
                                                            <div>
                                                                <span className="fw-bolder">
                                                                    Название
                                                                    файла:
                                                                </span>
                                                                {file?.name}
                                                            </div>
                                                            <div>
                                                                <span className="fw-bolder">
                                                                    Формат
                                                                    файла:
                                                                </span>
                                                                {file?.mime}
                                                            </div>
                                                            <a
                                                                href={
                                                                    file
                                                                        ? makePath(
                                                                              file.path,
                                                                          )
                                                                        : ``
                                                                }
                                                                target="_blank"
                                                                className="fw-bolder"
                                                            >
                                                                Скачать файл
                                                            </a>
                                                            <input
                                                                type="hidden"
                                                                name={`block[${props.index}][value][checkerboard_banners][${index}][files][${subIndex}][file_id]`}
                                                                defaultValue={
                                                                    file?.id
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    <i
                                                        className="bi bi-x-circle position-absolute text-danger end-0 mt-5 me-5 cursor-pointer"
                                                        onClick={() => {
                                                            setEl((prev) =>
                                                                prev.map(
                                                                    (
                                                                        val,
                                                                        prevIndex,
                                                                    ) => {
                                                                        if (
                                                                            prevIndex ===
                                                                            subIndex
                                                                        ) {
                                                                            val.file =
                                                                                undefined;
                                                                        }
                                                                        return val;
                                                                    },
                                                                ),
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="mb-5 d-flex">
                    <div className="me-5">
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={() =>
                                setEl((elState) => [
                                    ...elState,
                                    {
                                        id: uuid(),
                                        title: undefined,
                                    },
                                ])
                            }
                        >
                            Добавить
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <a
                    className="card-link text-danger"
                    onClick={() => {
                        props.onSubmit(props.block.id);
                    }}
                >
                    Удалить блок
                </a>
            </div>
        </div>
    );
}
