import React from "react";

import { saveArticle } from "@admin/domain/articles/api";
import Editor from "@admin/components/Editor";
import ArticleGeneralData from "./ArticleGeneralData";
import ArticleBlocks from "./ArticleBlocks";
import ArticleThemes from "./ArticleThemes";
import ArticleRubrics from "./ArticleRubrics";
import PageSeoParamsEditor from "@admin/components/PageSeoParamsEditor";

const LIST_URL = `/admin/articles` as const;

const ArticleEditor: React.FC = () => {
    const article = window.CMS.article || null;

    const execute = async (body: FormData) => {
        await saveArticle(article, body);
    };

    return (
        <Editor backUrl={LIST_URL} onSubmit={execute}>
            <ArticleGeneralData />
            <ArticleThemes article={article} />
            <ArticleRubrics article={article} />
            <PageSeoParamsEditor />
            <ArticleBlocks blocks={article?.blocks || []} />
        </Editor>
    );
};

export default ArticleEditor;
