export enum AccessType {
    All = `all`,
    Auth = `auth`,
    Individual = `individual`,
}

export enum ApplicationType {
    Amount = `amount`,
    Products = `products`,
    Categories = `categories`,
}

export enum DiscountType {
    Percentage = `percentage`,
    FixedAmount = `fixed_amount`,
}

export enum ApplicationLimitType {
    Limited = `limited`,
    Unlimited = `unlimited`,
}

export type PromoCodeModel = {
    id: number;
    creator_id: number;
    editor_id: number;
    max_uses_count: number;
    amount: number;
    code: string;
    description: string;
    access_type: AccessType;
    application_type: ApplicationType;
    discount_type: DiscountType;
    application_limit_type: ApplicationLimitType;
    is_visible: boolean;
    free_shipping: boolean;
    disable_promotional_products: boolean;
    total_usage_limit: number;
    ended_at: string | null;
    product_ids: number[];
    user_personal_numbers: number[];
    product_category_ids: number[];
    unsuitable_product_category_ids: number[];
    unsuitable_product_ids: number[];
    created_at: string | null;
};
