import ValidationError from "./ValidationError";
import ResponseError from "./ResponseError";

export interface ResponseJson<T> {
    description: string;
    payload: T;
}

export type ValidationResponseJson = {
    description: string;
    payload: {
        messages: { [fieldName: string]: Array<string> };
    };
};

const parseResponse = async <T>(
    response: Response,
): Promise<ResponseJson<T>> => {
    let json: ResponseJson<T>;

    try {
        json = await response.json();
    } catch (error) {
        throw new ResponseError(
            `The response from the server could not be parsed`,
        );
    }

    if (response.status === 422) {
        throw new ValidationError(json as ValidationResponseJson);
    } else if (response.status !== 200) {
        throw new ResponseError(json.description);
    }

    return json as ResponseJson<T>;
};

export default parseResponse;
