import React from "react";
import MultiLanguages from "@admin/components/MultiLanguage";
import { LanguageKeyObject } from "@admin/domain/languages/Languages";
import Input from "@admin/components/Input";

type Props = {
    broadcastIndex: number;
    title: LanguageKeyObject;
    description: LanguageKeyObject;
};

export default function Texts({ broadcastIndex, title, description }: Props) {
    return (
        <MultiLanguages
            languageElement={(language) => (
                <>
                    <Input
                        label="Заголовок"
                        name={`content[broadcasts][${broadcastIndex}][title][${language}]`}
                        defaultValue={title[language]}
                    />

                    <Input
                        label="Описание"
                        name={`content[broadcasts][${broadcastIndex}][description][${language}]`}
                        defaultValue={description[language]}
                    />
                </>
            )}
        />
    );
}
