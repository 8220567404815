import { type UniquePageProps } from "../index";
import { ReturnPageModel } from "@admin/domain/uniquePages/models/ReturnPageModel";
import Input from "@admin/components/Input";

export default function ReturnPage({ page }: UniquePageProps<ReturnPageModel>) {
    return (
        <>
            <h2 className="mb-5">Контент страницы "Оплата"</h2>
            <Input
                className={{ block: `col-6` }}
                label="Почта для заявления о возврате"
                name="content[email]"
                defaultValue={page.contentObject.email}
            />
        </>
    );
}
