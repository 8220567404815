import React, { createContext, useContext, useReducer } from "react";
import { v4 as uuid } from "uuid";

import { ProductVideoModel } from "@admin/domain/products/models/edit/ProductVideoModel";

type ProductVideoDispatchContextProps = {
    type?: `delete` | `add` | `drag` | `play`;
    index?: number;
    newProductVideo?: ProductVideos;
    newState?: ProductVideoModel[];
};

type ProductVideos = {
    id?: number | string;
} & Omit<ProductVideoModel, `id`>;

const ProductVideosContext = createContext<ProductVideoModel[]>([]);
const ProductVideoDispatchContext = createContext<
    React.Dispatch<ProductVideoDispatchContextProps>
>(undefined as any);

type ProductVideoProviderProps = {
    productVideosInit: ProductVideoModel[];
    children: React.ReactNode;
};

export function ProductVideoProvider({
    productVideosInit,
    children,
}: ProductVideoProviderProps) {
    const [productVideos, dispatch] = useReducer<
        React.Reducer<ProductVideoModel[], ProductVideoDispatchContextProps>
    >(productVideosReducer, productVideosInit);

    return (
        <ProductVideosContext.Provider value={productVideos}>
            <ProductVideoDispatchContext.Provider value={dispatch}>
                <div>{children}</div>
            </ProductVideoDispatchContext.Provider>
        </ProductVideosContext.Provider>
    );
}

function productVideosReducer(
    productVideos: ProductVideoModel[],
    action: ProductVideoDispatchContextProps,
): ProductVideoModel[] {
    switch (action.type) {
        case `add`: {
            const product = action.newProductVideo;
            if (!product) return productVideos;
            if (typeof product.id === `undefined`) product.id = uuid();

            return [...productVideos, product as ProductVideoModel];
        }
        case `delete`: {
            return productVideos.filter(
                (_, prevIndex) => (action.index as number) !== prevIndex,
            );
        }
        case `drag`: {
            if (!action.newState) return productVideos;
            return [...action.newState];
        }
        default: {
            throw Error(`Unknown action: ` + action.type);
        }
    }
}

export function useProductVideos() {
    return useContext(ProductVideosContext);
}

export function useProductVideosDispatch() {
    return useContext(ProductVideoDispatchContext);
}
