import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { GeneralCondition } from "@admin/pages/FooterEditor/FooterBlocksEditor";
import Sortable from "@admin/components/Sortable";
import { FooterBlockPageModel } from "@admin/domain/footer/Models/FooterBlockModel";
import { v4 as uuid } from "uuid";

type Props = {
    showModal: boolean;
    closeModal: () => void;
    onSubmit: (data: GeneralCondition) => void;
    editableGeneralCondition: GeneralCondition | null;
};

export default function FooterBlocksModal({
    closeModal,
    editableGeneralCondition,
    onSubmit,
    showModal,
}: Props) {
    const [generalCondition, setGeneralCondition] = useState<GeneralCondition>(
        editableGeneralCondition || {
            id: uuid(),
            title: ``,
            value: [],
        },
    );
    const [blockPages, setBlockPages] = useState<FooterBlockPageModel[]>(
        generalCondition.value || [],
    );

    function onChange(event, index: number) {
        setBlockPages((prev) =>
            prev.map((prevVal, prevIndex) => {
                if (prevIndex === index) {
                    prevVal.title = event.target.value;
                }
                return prevVal;
            }),
        );
    }

    useEffect(() => {
        if (generalCondition?.value) {
            setBlockPages(generalCondition.value);
        }
    }, [generalCondition.value]);

    useEffect(() => {
        if (blockPages) {
            generalCondition.value = blockPages;
            setGeneralCondition(generalCondition);
        }
    }, [blockPages]);

    useEffect(() => {
        if (!showModal) {
            return;
        }

        if (editableGeneralCondition) {
            setGeneralCondition(editableGeneralCondition);
        } else {
            setGeneralCondition({
                id: ``,
                title: ``,
                value: [],
            });
        }
    }, [editableGeneralCondition, showModal]);

    const submit = () => {
        onSubmit(generalCondition);
        closeModal();
    };

    return (
        <Modal centered show={showModal} onHide={closeModal} size="lg">
            <Modal.Header>
                <Modal.Title>
                    {editableGeneralCondition ? `Обновление` : `Добавление`}
                    {` `}
                    блока
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label htmlFor="pageTitle">Название блока</label>
                <input
                    className="form-control"
                    id="pageTitle"
                    value={generalCondition.title}
                    onChange={(event) =>
                        setGeneralCondition((prev) => ({
                            ...prev,
                            title: event.target.value,
                        }))
                    }
                    required
                />
                <h3>Страницы блока</h3>
                <Sortable list={blockPages} setList={setBlockPages}>
                    {blockPages.map((blockPage, index) => (
                        <div
                            key={blockPage.id}
                            className="card rounded border mb-4"
                        >
                            <div className="card-body">
                                <label htmlFor="pageTitle">
                                    Название ссылки
                                </label>
                                <input
                                    className="form-control"
                                    value={blockPage.title}
                                    onChange={(event) => onChange(event, index)}
                                    required
                                />

                                <label htmlFor="pageTitle"> Url ссылки </label>
                                <input
                                    className="form-control"
                                    value={blockPage.url}
                                    onChange={(event) =>
                                        setBlockPages((prev) =>
                                            prev.map((prevVal, prevIndex) => {
                                                if (prevIndex === index) {
                                                    prevVal.url =
                                                        event.target.value;
                                                }
                                                return prevVal;
                                            }),
                                        )
                                    }
                                    required
                                />
                            </div>
                            <div>
                                <a
                                    className="card-link text-danger"
                                    onClick={() => {
                                        setBlockPages((prev) =>
                                            prev.filter(
                                                (value) =>
                                                    value.id !== blockPage.id,
                                            ),
                                        );
                                    }}
                                >
                                    Удалить
                                </a>
                            </div>
                        </div>
                    ))}
                </Sortable>
                <div className="mb-5 d-flex">
                    <div className="me-5">
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={() =>
                                setBlockPages((prevState) => [
                                    ...prevState,
                                    {
                                        id: uuid(),
                                        title: ``,
                                        url: ``,
                                    },
                                ])
                            }
                        >
                            Добавить страницу
                        </button>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={closeModal}>
                    Закрыть
                </button>
                <button className="btn btn-primary" onClick={submit}>
                    {editableGeneralCondition ? `Обновить` : `Сохранить`}
                </button>
            </Modal.Footer>
        </Modal>
    );
}
