import React from "react";

type Props = {
    breadCrumbs: { title: string; url?: string }[];
} & React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
>;

export default function BreadCrumbs({ breadCrumbs, ...props }: Props) {
    return (
        <nav aria-label="breadcrumb" {...props}>
            <ol className="breadcrumb">
                {breadCrumbs.map((breadCrumb, index) => (
                    <li key={index} className="breadcrumb-item">
                        {breadCrumb.url ? (
                            <a href={breadCrumb.url}>{breadCrumb.title}</a>
                        ) : (
                            <span
                                className="breadcrumb-item"
                                aria-current="page"
                            >
                                {breadCrumb.title}
                            </span>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    );
}
