import isPlainObject from "lodash-es/isPlainObject";

import makeURL from "./makeURL";
import parseResponse from "./parseResponse";

type RequestProps = {
    headers?: Record<string, string>;
    method?: `GET` | `POST` | `PUT` | `DELETE`;
    url: string;
    query?: Record<string, string>;
    body?: BodyInit;
};

export async function request<T>({
    headers = {},
    method = `GET`,
    url,
    query = undefined,
    body = undefined,
}: RequestProps): Promise<T> {
    const csrfToken = document
        .querySelector(`meta[name="csrf-token"]`)
        ?.getAttribute(`content`);

    headers = {
        "X-CSRF-TOKEN": csrfToken as string,
        Accept: `application/json`,
        ...headers,
    };

    if (isPlainObject(body) || Array.isArray(body)) {
        body = JSON.stringify(body);
        headers[`Content-Type`] = `application/json`;
    }

    const response = await fetch(makeURL(url, query), {
        headers: headers,
        method: method,
        body: body,
    } as RequestInit);

    const json = await parseResponse<T>(response);

    return json.payload;
}

export async function post<T>({
    headers = {},
    url,
    query = undefined,
    body = undefined,
}: Omit<RequestProps, `method`>): Promise<T> {
    return request({
        headers: headers,
        method: `POST`,
        url: url,
        query: query,
        body: body,
    });
}
