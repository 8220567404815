import React, { useState } from "react";
import MultiLanguages from "@admin/components/MultiLanguage";
import LoadingImageInput from "@admin/components/LoadingImageInput";
import { makePath } from "@admin/utils/makePath";
import {
    HowToEarnBannersModel,
    MainPageModel,
} from "@admin/domain/uniquePages/models/MainPageModel";
import { type UniquePageProps } from "../index";
import VideoLoader from "@admin/components/VideoLoader";

type Banner = Partial<HowToEarnBannersModel>;

export default function MainPageHowToEarn({
    page,
}: UniquePageProps<MainPageModel>) {
    const initBanners = page.contentObject.how_to_earn__banners || [
        { file: undefined },
    ];
    const [banners, setBanners] = useState<Banner[]>(initBanners);

    return (
        <div className="mb-10">
            <h4 className="mb-5">
                Блок Как зарабатывать с компанией Тенториум
            </h4>
            <MultiLanguages
                languageElement={(language) => (
                    <div>
                        <div className="mb-5">
                            <label className="form-label">Заголовок</label>
                            <input
                                className="form-control"
                                name={`content[how_to_earn__title][${language}]`}
                                defaultValue={
                                    page.contentObject.how_to_earn__title[
                                        language
                                    ] || ``
                                }
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label">
                                Описание (Текст под заголовком)
                            </label>
                            <textarea
                                className="form-control"
                                name={`content[how_to_earn__description][${language}]`}
                                defaultValue={
                                    page.contentObject.how_to_earn__description[
                                        language
                                    ] || ``
                                }
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Текст кнопки 1</label>
                            <input
                                className="form-control"
                                name={`content[how_to_earn__business_button__text][${language}]`}
                                defaultValue={
                                    page.contentObject
                                        .how_to_earn__business_button__text[
                                        language
                                    ] || ``
                                }
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Текст кнопки 2</label>
                            <input
                                className="form-control"
                                name={`content[how_to_earn__video_button__text][${language}]`}
                                defaultValue={
                                    page.contentObject
                                        .how_to_earn__video_button__text[
                                        language
                                    ] || ``
                                }
                            />
                        </div>
                    </div>
                )}
            />

            <div className="mb-5">
                <label className="form-label">Ссылка на видео</label>
                <input
                    className="form-control"
                    name={`content[how_to_earn__video_url]`}
                    defaultValue={
                        page.contentObject.how_to_earn__video_url || ``
                    }
                />
            </div>

            <VideoLoader
                video={page?.contentObject?.how_to_earn__video}
                videoInputName="content[how_to_earn__video][file_id]"
                previewInputName="content[how_to_earn__video][metadata][file_id]"
            />

            <div className="row row-cols-auto">
                {banners.map((banner, index) => (
                    <div key={index} className="col mb-5">
                        <LoadingImageInput
                            width="800px"
                            src={
                                banner.file
                                    ? makePath(banner.file.path as string)
                                    : ``
                            }
                            onChange={(file) =>
                                setBanners((prev) =>
                                    prev.map((prevVal, prevIndex) => {
                                        if (prevIndex === index) {
                                            prevVal.file = file;
                                        }
                                        return prevVal;
                                    }),
                                )
                            }
                        />
                        <input
                            type="hidden"
                            name={`content[how_to_earn__banners][${index}][file_id]`}
                            value={banner.file?.id}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}
