import React from "react";

import {
    DeliveryPointModel,
    DeliveryPointProvider,
} from "@admin/domain/deliveryPoints/models/DeliveryPointModel";
import Input from "@admin/components/Input";

type Props = {
    deliveryPoint: DeliveryPointModel | null;
    provider: DeliveryPointProvider | null;
};

const Identifiers: React.FC<Props> = ({ deliveryPoint, provider }) => {
    return (
        <>
            <h2>Идентификаторы</h2>

            <Input
                label="Код пункта"
                name="external_id"
                defaultValue={deliveryPoint?.external_id}
            />

            {provider === DeliveryPointProvider.Tentorium && (
                <Input
                    label="Личный номер Дистрибьютора"
                    name="distributor_personal_number"
                    defaultValue={
                        deliveryPoint?.distributor_personal_number || ``
                    }
                />
            )}
        </>
    );
};

export default Identifiers;
