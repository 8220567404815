import React, { useState } from "react";

import type { ArticleContentBlockModel } from "@admin/domain/articles/models/ArticleContentBlockModel";
import { ArticleBlockType } from "@admin/domain/articles/enums/ArticleBlockType";
import { makePath } from "@admin/utils/makePath";
import LoadingImageInput from "@admin/components/LoadingImageInput";
import MultiLanguages from "@admin/components/MultiLanguage";

type Props = {
    block: ArticleContentBlockModel & {
        type: ArticleBlockType.TitleWithTextImage;
    };
    index: number;
};

export default function TitleWithTextImageBlock({
    block: { value },
    index,
}: Props) {
    const [image, setImage] = useState(value?.file);

    return (
        <>
            <MultiLanguages
                languageElement={(language) => (
                    <>
                        <div className="mb-5">
                            <label className="form-label">Заголовок</label>
                            <input
                                className="form-control"
                                name={`blocks[${index}][value][title][${language}]`}
                                defaultValue={value?.title?.[language] || ``}
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label">Текст</label>
                            <input
                                className="form-control"
                                name={`blocks[${index}][value][description][${language}]`}
                                defaultValue={
                                    value?.description?.[language] || ``
                                }
                            />
                        </div>
                    </>
                )}
            />

            <div className="mb-5">
                <label className="form-label">Изображение</label>

                <LoadingImageInput
                    src={makePath(image?.path)}
                    onChange={setImage}
                />

                <input
                    type="hidden"
                    name={`blocks[${index}][value][file_id]`}
                    value={image?.id}
                />
            </div>
        </>
    );
}
