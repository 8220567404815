import React from "react";

import { saveProductCategory } from "@admin/domain/productCategory/api";
import Editor from "@admin/components/Editor";
import Input from "@admin/components/Input";
import CheckBox from "@admin/components/CheckBox";
import MultiLanguages from "@admin/components/MultiLanguage";
import Text from "./Text";
import AdditionalProducts from "./AdditionalProducts";
import LoadingImageInput from "@admin/components/LoadingImageInput";
import PageSeoParamsEditor from "@admin/components/PageSeoParamsEditor";

const LIST_URL = `/admin/product_categories` as const;

export default function ProductCategoryEditor() {
    const productCategory = window.CMS.productCategory || null;

    async function execute(body: FormData) {
        await saveProductCategory(productCategory, body);
    }

    return (
        <Editor backUrl={LIST_URL} onSubmit={execute}>
            <CheckBox
                label="Активная категория"
                name="is_active"
                defaultChecked={productCategory?.is_active}
            />

            <Input
                label="Указание родительской категории"
                name="parent_accounting_id"
                placeholder="Uuid родительской категории"
                defaultValue={productCategory?.parent?.accounting_id}
            />

            <MultiLanguages
                languageElement={(language) => (
                    <Text language={language} texts={productCategory?.texts} />
                )}
            />

            <Input
                label="Uuid категории в 1С"
                name="accounting_id"
                defaultValue={productCategory?.accounting_id}
            />

            <CheckBox
                label="В меню каталога товаров"
                name="is_listed"
                defaultChecked={productCategory?.is_listed || true}
            />

            <Input
                label="Порядок отображения"
                type="number"
                name="order"
                defaultValue={productCategory?.order}
            />

            <LoadingImageInput
                label="Иконка"
                name="picture"
                src={productCategory?.picture?.path}
                thumbnailWidth={100}
                thumbnailHeight={100}
            />

            <AdditionalProducts productCategory={productCategory} />

            <PageSeoParamsEditor />
        </Editor>
    );
}
