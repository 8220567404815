import { LanguageKeyObject } from "../../../languages/Languages";

export enum AmountType {
    Price = `price`,
    Points = `points`,
}

export type AmountPromotionModel = {
    id: number;
    amount_type: AmountType;
    active_from: string | null;
    active_to: string | null;
    only_for_site: boolean;

    texts: LanguageKeyObject<AmountPromotionTextModel>;
};

export type AmountPromotionTextModel = {
    title: string;
    description: string;
};
