import React, { useState } from "react";
import MultiLanguages from "@admin/components/MultiLanguage";
import LoadingImageInput from "@admin/components/LoadingImageInput";
import { ContentBlockModel } from "@admin/domain/content/models/ContentBlockModel";
import { BannerModel } from "@admin/domain/content/models/ContentBlockValueModel";

type Props = {
    block: ContentBlockModel;
    index: number;
    key: number;
    onSubmit: (blockId: number) => void;
};

export default function BannerBlock(props: Props) {
    const value = props.block?.value as BannerModel | undefined;
    const [image, setImage] = useState(value?.file);

    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок с баннером</h4>
            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <div className="mb-5">
                                <label className="form-label">Заголовок</label>
                                <input
                                    className="form-control"
                                    name={`blocks[${props.index}][value][title][${language}]`}
                                    defaultValue={
                                        value?.title?.[language] || ``
                                    }
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">Текст</label>
                                <input
                                    className="form-control"
                                    name={`blocks[${props.index}][value][description][${language}]`}
                                    defaultValue={
                                        value?.description?.[language] || ``
                                    }
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">
                                    Текст кнопки
                                </label>
                                <input
                                    className="form-control"
                                    name={`blocks[${props.index}][value][button_text][${language}]`}
                                    defaultValue={
                                        value?.button_text?.[language] || ``
                                    }
                                />
                            </div>
                        </div>
                    )}
                />
                <div className="mb-5">
                    <label className="form-label">Ссылка</label>
                    <input
                        className="form-control"
                        name={`blocks[${props.index}][value][url]`}
                        defaultValue={value?.url || ``}
                    />
                </div>
            </div>
            <div className="mb-5">
                <label className="form-label">Изображение</label>
                <LoadingImageInput
                    width="800px"
                    height="200px"
                    src={image?.path}
                    onChange={setImage}
                />
                <input
                    type="hidden"
                    name={`blocks[${props.index}][value][file_id]`}
                    value={image?.id}
                />
            </div>
            <div>
                <a
                    className="card-link text-danger"
                    onClick={() => props.onSubmit(props.block.id)}
                >
                    Удалить блок
                </a>
            </div>
        </div>
    );
}
