import React from "react";
import LoadingImageInput from "@admin/components/LoadingImageInput";
import { FileModel } from "@admin/domain/files/model/FileModel";
import Input from "@admin/components/Input";
import { Banner } from "./";
import { Language } from "@admin/domain/languages/Languages";
import MultiLanguages from "@admin/components/MultiLanguage";

type Props = {
    setBanner: React.Dispatch<React.SetStateAction<Banner>>;
    banner: Banner;
};

export default function Fields({ setBanner, banner }: Props) {
    return (
        <>
            <div className="d-flex align-items-center justify-content-center">
                <LoadingImageInput
                    onChange={(file: FileModel): void =>
                        setBanner((prev) => ({ ...prev, file }))
                    }
                    src={banner?.file?.path}
                />
            </div>

            <MultiLanguages
                languageElement={(language: Language) => (
                    <>
                        <Input
                            label="Заголовок"
                            onChange={(event) =>
                                setBanner((prev) => {
                                    const { title } = prev;
                                    title[language] = event.target.value;
                                    return { ...prev, title };
                                })
                            }
                            value={banner.title[language]}
                        />

                        <Input
                            label="Текст под заголовком"
                            onChange={(event) =>
                                setBanner((prev) => {
                                    const { description } = prev;
                                    description[language] = event.target.value;
                                    return { ...prev, description };
                                })
                            }
                            value={banner.description[language]}
                        />

                        <Input
                            label="Текст кнопки"
                            onChange={(event) =>
                                setBanner((prev) => {
                                    const { button_text } = prev;
                                    button_text[language] = event.target.value;
                                    return { ...prev, button_text };
                                })
                            }
                            value={banner.button_text[language]}
                        />

                        <Input
                            label="Тег"
                            onChange={(event) =>
                                setBanner((prev) => {
                                    const tag = prev.tag;
                                    tag[language] = event.target.value;
                                    return { ...prev, tag };
                                })
                            }
                            value={banner.tag[language]}
                        />
                    </>
                )}
            />

            <Input
                label="Ссылка"
                onChange={(event) =>
                    setBanner((prev) => ({ ...prev, url: event.target.value }))
                }
                value={banner.url}
            />
        </>
    );
}
