import React, { useEffect, useState } from "react";

import { ProgramRulesRecommendationsModel } from "@admin/domain/articles/models/ArticleBlockValueModel";
import { ArticleProgramRuleRecomendationType } from "@admin/domain/articles/enums/ArticleProgramRuleRecomendationType";
import MultiLanguages from "@admin/components/MultiLanguage";

type Props = {
    recommendations: ProgramRulesRecommendationsModel[];
    blockIndex: number;
    index: number;
};

export default function ProgramRulesRecommendations(props: Props) {
    const recommendations =
        props.recommendations as ProgramRulesRecommendationsModel[];

    const [checkBoxes, setCheckBoxes] = useState<string[]>([]);

    const onChangeCheckBox = (type: ArticleProgramRuleRecomendationType) => {
        if (checkBoxes.indexOf(type) != -1) {
            setCheckBoxes(checkBoxes.filter((p) => p !== type));
        } else {
            setCheckBoxes([...checkBoxes, type]);
        }
    };

    const recommendationTypes = [
        `additional_condition_admission`,
        `reception_frequency`,
        `receipt_time`,
        `accepted_quantity`,
    ];

    useEffect(() => {
        if (recommendations && recommendations.length > 0) {
            setCheckBoxes(recommendations?.map((item) => item.type) || []);
        }
    }, [recommendations]);

    const getRecommendationTitle = (type: string): string => {
        switch (type) {
            case `additional_condition_admission`:
                return `дополнительное условие приёма`;

            case `reception_frequency`:
                return `частота приёма`;

            case `receipt_time`:
                return `время приёма`;

            case `accepted_quantity`:
                return `принимаемое количество`;

            default:
                return ``;
        }
    };

    return (
        <div className="mb-10">
            <h4 className="mb-5">Рекомендации</h4>

            {recommendationTypes.map((recommendation: string) => (
                <div
                    className="mb-5"
                    key={`checkbox-${props.index}-${recommendation}`}
                >
                    <div className="form-check form-check-custom form-check-solid">
                        <input
                            id={`checkbox-${props.index}-${recommendation}`}
                            className="form-check-input"
                            type="checkbox"
                            name={`blocks[${props.blockIndex}][value][program_rules][${props.index}][recommendations][${checkBoxes.indexOf(recommendation)}][type]`}
                            defaultChecked={
                                checkBoxes.indexOf(recommendation) != -1
                            }
                            checked={checkBoxes.indexOf(recommendation) != -1}
                            onChange={() =>
                                onChangeCheckBox(
                                    recommendation as ArticleProgramRuleRecomendationType,
                                )
                            }
                            defaultValue={recommendation}
                        />
                        <label
                            className="form-check-label"
                            htmlFor={`checkbox-${props.index}-${recommendation}`}
                        >
                            {getRecommendationTitle(recommendation)}
                        </label>
                    </div>

                    {checkBoxes.indexOf(recommendation) != -1 && (
                        <div className="pt-5">
                            <MultiLanguages
                                languageElement={(language) => (
                                    <div className="mb-5">
                                        <label className="form-label">
                                            Описание рекомендации
                                        </label>
                                        <input
                                            className="form-control"
                                            name={`blocks[${props.blockIndex}][value][program_rules][${props.index}][recommendations][${checkBoxes.indexOf(recommendation)}][text][${language}]`}
                                            defaultValue={
                                                recommendations &&
                                                recommendations.length > 0
                                                    ? recommendations?.find(
                                                          (
                                                              ruleRecommendation,
                                                          ) =>
                                                              ruleRecommendation.type ===
                                                              recommendation,
                                                      )?.text?.[language] || ``
                                                    : ``
                                            }
                                        />
                                    </div>
                                )}
                            />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}
