import React, { useState } from "react";
import MultiLanguages from "@admin/components/MultiLanguage";
import { v4 as uuid } from "uuid";
import LoadingImageInput from "@admin/components/LoadingImageInput";
import { makePath } from "@admin/utils/makePath";
import { SliderModel } from "@admin/domain/content/models/ContentBlockValueModel";
import { ContentBlockModel } from "@admin/domain/content/models/ContentBlockModel";

type Slider = Partial<SliderModel>;

type Props = {
    block: ContentBlockModel;
    index: number;
    key: number;
    onSubmit: (blockId: number) => void;
};
export default function PhotoWithTextSliderBlock(props: Props) {
    const initSlider = (props?.block?.value?.slides || []).map(
        (value: SliderModel) => {
            return { ...value, id: uuid() };
        },
    );

    const [slider, setSlider] = useState<Slider[]>(initSlider);

    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок слайдера фото с текстом</h4>
            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <div className="mb-5">
                                <label className="form-label">Заголовок</label>
                                <input
                                    className="form-control"
                                    name={`blocks[${props.index}][value][title][${language}]`}
                                    defaultValue={
                                        props?.block?.value?.title?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="row row-cols-auto">
                {slider.map((slide, index) => (
                    <div key={slide.id} className="col mb-5">
                        <div>
                            <MultiLanguages
                                languageElement={(language) => (
                                    <div>
                                        <div className="mb-5">
                                            <label className="form-label">
                                                Заголовок
                                            </label>
                                            <input
                                                className="form-control"
                                                name={`blocks[${props.index}][value][slides][${index}][title][${language}]`}
                                                defaultValue={
                                                    slide?.title?.[language] ||
                                                    ``
                                                }
                                            />
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label">
                                                Подзаголовок
                                            </label>
                                            <input
                                                className="form-control"
                                                name={`blocks[${props.index}][value][slides][${index}][sub_title][${language}]`}
                                                defaultValue={
                                                    slide?.sub_title?.[
                                                        language
                                                    ] || ``
                                                }
                                            />
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label">
                                                Описание
                                            </label>
                                            <input
                                                className="form-control"
                                                name={`blocks[${props.index}][value][slides][${index}][description][${language}]`}
                                                defaultValue={
                                                    slide?.description?.[
                                                        language
                                                    ] || ``
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                            />

                            <div key={index} className="col mb-5">
                                <LoadingImageInput
                                    width="300px"
                                    height="300px"
                                    src={
                                        slide.file
                                            ? makePath(
                                                  slide.file.path as string,
                                              )
                                            : ``
                                    }
                                    onChange={(file) =>
                                        setSlider((image) =>
                                            image.map(
                                                (imageVal, imageIndex) => {
                                                    if (imageIndex === index) {
                                                        imageVal.file = file;
                                                    }
                                                    return imageVal;
                                                },
                                            ),
                                        )
                                    }
                                />
                                <input
                                    type="hidden"
                                    name={`blocks[${props.index}][value][slides][${index}][file_id]`}
                                    value={slide.file?.id}
                                />
                            </div>
                        </div>

                        <div>
                            <a
                                className="card-link text-danger"
                                onClick={() => {
                                    setSlider((slideState) =>
                                        slideState.filter(
                                            (value) => value.id !== slide.id,
                                        ),
                                    );
                                }}
                            >
                                Удалить
                            </a>
                        </div>
                    </div>
                ))}
            </div>
            <div className="mb-5 d-flex">
                <div className="me-5">
                    <button
                        type="button"
                        className="btn btn-success"
                        onClick={() =>
                            setSlider((slideState) => [
                                ...slideState,
                                {
                                    id: uuid(),
                                    file: undefined,
                                    title: undefined,
                                    sub_title: undefined,
                                    description: undefined,
                                },
                            ])
                        }
                    >
                        Добавить слайд
                    </button>
                </div>
            </div>
            <div>
                <a
                    className="card-link text-danger"
                    onClick={() => {
                        props.onSubmit(props.block.id);
                    }}
                >
                    Удалить блок
                </a>
            </div>
        </div>
    );
}
