import React from "react";

import { savePromoCode } from "@admin/domain/promoCodes/api";
import Editor from "@admin/components/Editor";
import PromoCodeFields from "./PromoCodeFields";

const LIST_URL = `/admin/promo_codes` as const;

const PromoCodeEditor: React.FC = () => {
    const promoCode = window.CMS.promoCode?.promoCode || null;

    const prepareBody = (body: FormData): void => {
        const personalNumbers = body.get(`user_personal_numbers`);
        const productIds = body.get(`product_ids`);
        const unsuitableProductIds = body.get(`unsuitable_product_ids`);
        const categoryIds = body.get(`product_category_ids`);
        const unsuitableCategoryIds = body.get(
            `unsuitable_product_category_ids`,
        );

        if (typeof productIds === `string` && productIds) {
            const productIdsArray = productIds.split(`,`);
            body.delete(`product_ids`);
            for (let i = 0; i < productIdsArray.length; i++) {
                body.append(`product_ids[]`, productIdsArray[i]);
            }
        }

        if (typeof unsuitableProductIds === `string` && unsuitableProductIds) {
            const unsuitableProductIdsArray = unsuitableProductIds.split(`,`);
            body.delete(`unsuitable_product_ids`);
            for (let i = 0; i < unsuitableProductIdsArray.length; i++) {
                body.append(
                    `unsuitable_product_ids[]`,
                    unsuitableProductIdsArray[i],
                );
            }
        }

        if (typeof categoryIds === `string` && categoryIds) {
            const categoryIdsArray = categoryIds.split(`,`);
            body.delete(`product_category_ids`);
            for (let i = 0; i < categoryIdsArray.length; i++) {
                body.append(`product_category_ids[]`, categoryIdsArray[i]);
            }
        }

        if (
            typeof unsuitableCategoryIds === `string` &&
            unsuitableCategoryIds
        ) {
            const unsuitableCategoryIdsArray = unsuitableCategoryIds.split(`,`);
            body.delete(`unsuitable_product_category_ids`);
            for (let i = 0; i < unsuitableCategoryIdsArray.length; i++) {
                body.append(
                    `unsuitable_product_category_ids[]`,
                    unsuitableCategoryIdsArray[i],
                );
            }
        }

        if (typeof personalNumbers === `string`) {
            const personalNumbersArray = personalNumbers.split(`,`);
            body.delete(`user_personal_numbers`);
            for (let i = 0; i < personalNumbersArray.length; i++) {
                body.append(`user_personal_numbers[]`, personalNumbersArray[i]);
            }
        }
    };

    const execute = async (body: FormData) => {
        await savePromoCode(promoCode, body);
    };

    return (
        <Editor backUrl={LIST_URL} onSubmit={execute} prepareBody={prepareBody}>
            <PromoCodeFields />
        </Editor>
    );
};

export default PromoCodeEditor;
