import { useState } from "react";

import { type AmountPromotionGiftModel } from "@admin/domain/complexPromotions/amount/models/AmountPromotionGiftModel";
import { type AmountPromotionLevelData } from "@admin/domain/complexPromotions/amount/api";
import { type AmountPromotionDiscountModel } from "@admin/domain/complexPromotions/amount/models/AmountPromotionDiscountModel";

type UseLevelHook = {
    levels: ReadonlyArray<AmountPromotionLevelData>;
    addNewLevel: () => void;
    addNewGiftToLevel: (levelIndex: number) => void;
    addNewDiscountToLevel: (levelIndex: number) => void;
    deleteLevel: (levelIndex: number) => void;
    deleteGift: (levelIndex: number, giftIndex: number) => void;
    deleteDiscount: (levelIndex: number, discountIndex: number) => void;
};

const useLevels = (
    initialLevels: ReadonlyArray<AmountPromotionLevelData> | undefined,
): UseLevelHook => {
    const [levels, setLevels] = useState<
        ReadonlyArray<AmountPromotionLevelData>
    >(initialLevels || []);

    const addNewLevel = (): void =>
        setLevels([
            ...levels,
            {
                level: {
                    amount: 0,
                },
                discount_products: [],
                gift_products: [],
            },
        ]);

    const addNewGiftToLevel = (levelIndex: number): void => {
        const newLevels = levels.map(
            (
                level: AmountPromotionLevelData,
                index: number,
            ): AmountPromotionLevelData => {
                if (index !== levelIndex) {
                    return level;
                }

                return {
                    ...level,
                    gift_products: [
                        ...level.gift_products,
                        {
                            quantity: 1,
                        },
                    ],
                };
            },
        );

        setLevels(newLevels);
    };

    const addNewDiscountToLevel = (levelIndex: number): void => {
        const newLevels = levels.map(
            (
                level: AmountPromotionLevelData,
                index: number,
            ): AmountPromotionLevelData => {
                if (index !== levelIndex) {
                    return level;
                }

                return {
                    ...level,
                    discount_products: [...level.discount_products, {}],
                };
            },
        );

        setLevels(newLevels);
    };

    const deleteLevel = (levelIndex: number): void => {
        setLevels(
            levels.filter(
                (_level: AmountPromotionLevelData, index: number): boolean =>
                    levelIndex !== index,
            ),
        );
    };

    const deleteGift = (levelIndex: number, giftIndex: number): void => {
        const newLevels = levels.map(
            (
                level: AmountPromotionLevelData,
                index: number,
            ): AmountPromotionLevelData => {
                if (index !== levelIndex) {
                    return level;
                }

                return {
                    ...level,
                    gift_products: level.gift_products.filter(
                        (
                            _gift: AmountPromotionGiftModel,
                            index: number,
                        ): boolean => index !== giftIndex,
                    ),
                };
            },
        );

        setLevels(newLevels);
    };

    const deleteDiscount = (
        levelIndex: number,
        discountIndex: number,
    ): void => {
        const newLevels = levels.map(
            (
                level: AmountPromotionLevelData,
                index: number,
            ): AmountPromotionLevelData => {
                if (index !== levelIndex) {
                    return level;
                }

                return {
                    ...level,
                    discount_products: level.discount_products.filter(
                        (
                            _gift: AmountPromotionDiscountModel,
                            index: number,
                        ): boolean => index !== discountIndex,
                    ),
                };
            },
        );

        setLevels(newLevels);
    };

    return {
        addNewLevel,
        addNewGiftToLevel,
        addNewDiscountToLevel,
        deleteLevel,
        deleteGift,
        deleteDiscount,
        levels,
    };
};

export default useLevels;
