import React, { useEffect, useRef } from "react";

// @ts-ignore
import videojs, { VideoJsPlayerOptions } from "video.js";

import "video.js/dist/video-js.css";

type VideoPlayerProps = {
    source: string;
    options?: VideoJsPlayerOptions;
};

export function VideoJs(props: VideoPlayerProps) {
    const defaultOptions: VideoJsPlayerOptions = {
        controls: true,
        sources: [
            {
                src: props.source,
                type: `video/mp4`,
            },
        ],
        height: 400,
    };

    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        const player = videojs(
            videoRef.current as HTMLVideoElement,
            props.options || defaultOptions,
        );

        return () => {
            if (player) player.dispose();
        };
    }, [props.source]);

    return (
        <div data-vjs-player="" className="position-relative">
            <video ref={videoRef} className="video-js vjs-default-skin" />
        </div>
    );
}
