import React, { useState } from "react";
import { type UniquePageProps } from "../index";
import {
    LiveBroadcastModel,
    LiveBroadcastsPageModel,
} from "@admin/domain/uniquePages/models/LiveBroadcastsPageModel";
import DateTimeInput from "@admin/components/DateTimeInput";
import Speakers from "./Speakers";
import Texts from "./Texts";
import CheckBox from "@admin/components/CheckBox";
import FilesList from "@admin/components/FilesList";
import Input from "@admin/components/Input";
import VideoLoader from "@admin/components/VideoLoader";
import { initLanguageKeyObject } from "@admin/domain/languages/Languages";
import MultiLanguages from "@admin/components/MultiLanguage";
import TextArea from "@admin/components/TextArea";

export default function LiveBroadcastsPage({
    page,
}: UniquePageProps<LiveBroadcastsPageModel>) {
    const [broadcasts, setBroadcasts] = useState<LiveBroadcastModel[]>(
        page.contentObject.broadcasts || [],
    );
    const sub_title = page.contentObject.sub_title || initLanguageKeyObject();
    const description =
        page.contentObject.description || initLanguageKeyObject();

    const addBroadcast = (): void =>
        setBroadcasts([
            ...broadcasts,
            {
                starts_at: null,
                is_finished: false,
                title: initLanguageKeyObject(),
                description: initLanguageKeyObject(),
                speakers: [initLanguageKeyObject()],
                documents: null,
                video_link: null,
                video: null,
            },
        ]);

    const deleteBroadcast = (broadcastIndex: number): void =>
        setBroadcasts(
            broadcasts.filter((_broadcast, index) => broadcastIndex !== index),
        );

    return (
        <div>
            <h2>Контент страницы "Живые эфиры"</h2>

            <MultiLanguages
                languageElement={(language) => (
                    <>
                        <Input
                            label="Подзаголовок"
                            name={`content[sub_title][${language}]`}
                            defaultValue={sub_title[language] || undefined}
                        />

                        <TextArea
                            label="Описание"
                            name={`content[description][${language}]`}
                            defaultValue={description[language] || undefined}
                            required
                        />
                    </>
                )}
            />

            <div className="row">
                {broadcasts.map(
                    (broadcast: LiveBroadcastModel, broadcastIndex: number) => {
                        return (
                            <div
                                className="rounded border p-10 m-5"
                                key={broadcastIndex}
                            >
                                <DateTimeInput
                                    label="Время начала эфира"
                                    value={broadcast.starts_at || undefined}
                                    name={`content[broadcasts][${broadcastIndex}][starts_at]`}
                                />
                                <CheckBox
                                    label="Эфир закончен"
                                    defaultChecked={broadcast.is_finished}
                                    name={`content[broadcasts][${broadcastIndex}][is_finished]`}
                                />
                                <Texts
                                    broadcastIndex={broadcastIndex}
                                    title={broadcast.title}
                                    description={broadcast.description}
                                />
                                <Speakers
                                    speakers={broadcast.speakers}
                                    broadcastIndex={broadcastIndex}
                                />
                                <FilesList
                                    files={broadcast.documents}
                                    fileInputFunction={(fileIndex: number) =>
                                        `content[broadcasts][${broadcastIndex}][documents][${fileIndex}]`
                                    }
                                    title="Документы"
                                />
                                <Input
                                    label="Ссылка на видео"
                                    name={`content[broadcasts][${broadcastIndex}][video_link]`}
                                    defaultValue={
                                        broadcast.video_link || undefined
                                    }
                                />
                                <VideoLoader
                                    video={broadcast.video}
                                    videoInputName={`content[broadcasts][${broadcastIndex}][video_id]`}
                                    previewInputName={`content[broadcasts][${broadcastIndex}][video_preview_id]`}
                                />

                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() =>
                                        deleteBroadcast(broadcastIndex)
                                    }
                                >
                                    Удалить эфир
                                </button>
                            </div>
                        );
                    },
                )}
            </div>

            <button
                type="button"
                className="btn btn-primary mb-5"
                onClick={addBroadcast}
            >
                Добавить эфир
            </button>
        </div>
    );
}
