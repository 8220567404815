import React, { createContext, useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";

type ContextProps = {
    show: boolean;
    body?: React.JSX.Element;
};

const SetModalVideoContext = createContext<
    React.Dispatch<React.SetStateAction<ContextProps>>
>(undefined as any);

export default function ModalVideoProvider({
    children,
}: {
    children: React.JSX.Element;
}) {
    const init: ContextProps = { show: false };
    const [context, setContext] = useState(init);

    return (
        <SetModalVideoContext.Provider value={setContext}>
            {children}
            <Modal
                show={context.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setContext({ show: false })}
            >
                <Modal.Body className="modal_video_viewing_window_body">
                    {context.body}
                </Modal.Body>
            </Modal>
        </SetModalVideoContext.Provider>
    );
}

export function useSetModalVideo() {
    return useContext(SetModalVideoContext);
}
