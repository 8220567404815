import React from "react";
import { ContentBlockModel } from "@admin/domain/content/models/ContentBlockModel";

type Props = {
    block: ContentBlockModel;
    index: number;
    key: number;
    onSubmit: (blockId: number) => void;
};

export default function OtherNewsBlock(props: Props) {
    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок других новостей</h4>

            <input
                type="hidden"
                name={`blocks[${props.index}][value]`}
                defaultValue=""
            />

            <div>
                <a
                    className="card-link text-danger"
                    onClick={() => props.onSubmit(props.block.id)}
                >
                    Удалить блок
                </a>
            </div>
        </div>
    );
}
