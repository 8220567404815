import React, { useEffect, useState } from "react";
import MultiLanguages from "@admin/components/MultiLanguage";
import { makePath } from "@admin/utils/makePath";
import { FileModel } from "@admin/domain/files/model/FileModel";
import { loadFile } from "@admin/domain/products/api";
import { ContentBlockModel } from "@admin/domain/content/models/ContentBlockModel";

type Props = {
    block: ContentBlockModel;
    index: number;
    key: number;
    onSubmit: (blockId: number) => void;
};

export default function VideoBlock(props: Props) {
    const [files, setFiles] = useState<{
        video: File | null;
        preview: File | null;
    }>({ video: null, preview: null });
    const [video, setVideo] = useState<FileModel | null>(null);

    useEffect(() => {
        if (props?.block?.value?.file) {
            setVideo(props?.block?.value?.file);
        }
    }, [props?.block?.value?.file]);

    async function addFile() {
        if (!files.video || !files.preview) return;

        await loadFile(files.video, files.preview).then((video) =>
            setVideo(video),
        );
    }

    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок с видео</h4>
            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <div className="mb-5">
                                <label className="form-label">Заголовок</label>
                                <input
                                    className="form-control"
                                    name={`blocks[${props.index}][value][title][${language}]`}
                                    defaultValue={
                                        props?.block?.value?.title?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">Описание</label>
                                <textarea
                                    className="form-control"
                                    name={`blocks[${props.index}][value][description][${language}]`}
                                    defaultValue={
                                        props?.block?.value?.description?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="mb-5">
                <label className="form-label">Ссылка на видео</label>
                <input
                    className="form-control"
                    name={`blocks[${props.index}][value][url]`}
                    defaultValue={props?.block?.value?.url}
                />
            </div>
            <div className="row">
                <h4 className="mb-5">Видео файл</h4>
                <br />
                <div>
                    {!video && (
                        <div className="mb-5">
                            <div className="mb-5">
                                <div>
                                    <label className="form-label">
                                        Видео файл
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        name="file"
                                        accept="video/*"
                                        onChange={(event) =>
                                            setFiles((prevState) => ({
                                                ...prevState,
                                                video: event.target.files?.item(
                                                    0,
                                                ) as File,
                                            }))
                                        }
                                    />
                                </div>
                                <div className="mt-5">
                                    <label className="form-label">
                                        Превью для видео файла
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        name="file"
                                        accept="image/*"
                                        onChange={(event) =>
                                            setFiles((prevState) => ({
                                                ...prevState,
                                                preview:
                                                    event.target.files?.item(
                                                        0,
                                                    ) as File,
                                            }))
                                        }
                                    />
                                </div>
                            </div>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={addFile}
                            >
                                Загрузить файл
                            </button>
                        </div>
                    )}
                    {video && (
                        <div>
                            <div className="card border mb-4 position-relative">
                                <div className="card-body">
                                    <div className="d-flex flex-column justify-content-center">
                                        <div>
                                            <span className="fw-bolder">
                                                Название файла:
                                            </span>
                                            {video?.name}
                                        </div>
                                        <div>
                                            <span className="fw-bolder">
                                                Формат файла:
                                            </span>
                                            {video?.mime}
                                        </div>
                                        <div>
                                            <span className="fw-bolder">
                                                Дата загрузки:
                                            </span>
                                            {video?.created_at}
                                        </div>
                                        <a
                                            href={
                                                props?.block?.file
                                                    ? makePath(video.path)
                                                    : ``
                                            }
                                            target="_blank"
                                            className="fw-bolder"
                                        >
                                            Скачать видео
                                        </a>
                                        <input
                                            type="hidden"
                                            name={`blocks[${props.index}][value][file_id]`}
                                            defaultValue={video.id}
                                        />
                                    </div>
                                </div>
                                <h4 className="mb-5">Превью файл</h4>
                                <div className="card-body">
                                    <div className="d-flex flex-column justify-content-center">
                                        <div>
                                            <span className="fw-bolder">
                                                Название файла:
                                            </span>
                                            {video.preview_file?.name}
                                        </div>
                                        <div>
                                            <span className="fw-bolder">
                                                Формат файла:
                                            </span>
                                            {video.preview_file?.mime}
                                        </div>
                                        <div>
                                            <span className="fw-bolder">
                                                Дата загрузки:
                                            </span>
                                            {video.preview_file?.created_at}
                                        </div>
                                        <a
                                            href={
                                                video.preview_file
                                                    ? makePath(
                                                          video.preview_file
                                                              .path,
                                                      )
                                                    : ``
                                            }
                                            target="_blank"
                                            className="fw-bolder"
                                        >
                                            Скачать превью
                                        </a>
                                        <input
                                            type="hidden"
                                            name={`blocks[${props.index}][value][file][metadata][file_id]`}
                                            defaultValue={
                                                video.preview_file?.id
                                            }
                                        />
                                    </div>
                                </div>
                                <i
                                    className="bi bi-x-circle position-absolute text-danger end-0 mt-5 me-5 cursor-pointer"
                                    onClick={() => {
                                        setVideo(null);
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div>
                <a
                    className="card-link text-danger"
                    onClick={() => props.onSubmit(props.block.id)}
                >
                    Удалить блок
                </a>
            </div>
        </div>
    );
}
