import React, { createContext, useContext, useReducer } from "react";
import { FileModel } from "@admin/domain/files/model/FileModel";

type ProductMediaFilesDispatchContextProps = {
    type?: `delete` | `add` | `drag`;
    deletedId?: number;
    productMediaFile?: FileModel;
    newState?: FileModel[];
};

const ProductMediaFilesContext = createContext<FileModel[]>([]);
const ProductMediaFilesDispatchContext = createContext<
    React.Dispatch<ProductMediaFilesDispatchContextProps>
>(undefined as any);

type ProductMediaFilesProviderProps = {
    productMediaFilesInit: FileModel[];
    children: React.ReactNode;
};

export function ProductMediaFilesProvider({
    productMediaFilesInit,
    children,
}: ProductMediaFilesProviderProps) {
    const [productMediaFiles, dispatch] = useReducer<
        React.Reducer<FileModel[], ProductMediaFilesDispatchContextProps>
    >(productMediaFilesReducer, productMediaFilesInit);

    return (
        <ProductMediaFilesContext.Provider value={productMediaFiles}>
            <ProductMediaFilesDispatchContext.Provider value={dispatch}>
                {children}
            </ProductMediaFilesDispatchContext.Provider>
        </ProductMediaFilesContext.Provider>
    );
}

function productMediaFilesReducer(
    productMediaFiles: FileModel[],
    action: ProductMediaFilesDispatchContextProps,
): FileModel[] {
    switch (action.type) {
        case `add`: {
            return [...productMediaFiles, action.productMediaFile as FileModel];
        }
        case `delete`: {
            const removed = productMediaFiles.filter(
                (prevFile) => prevFile.id !== (action.deletedId as number),
            );
            if (removed.length > 0 && removed[0].mime.match(`video/`)) {
                return productMediaFiles;
            }

            return removed;
        }
        case `drag`: {
            return [...(action.newState || [])];
        }
        default: {
            throw Error(`Unknown action: ` + action.type);
        }
    }
}

export function useProductMediaFiles() {
    return useContext(ProductMediaFilesContext);
}

export function useProductMediaFilesDispatch() {
    return useContext(ProductMediaFilesDispatchContext);
}
