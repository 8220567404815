import React, { useState } from "react";
import MultiLanguages from "@admin/components/MultiLanguage";
import { v4 as uuid } from "uuid";
import { makePath } from "@admin/utils/makePath";
import { loadFile } from "@admin/domain/products/api";
import { LeadersModel } from "@admin/domain/customPages/models/CustomPageModel";
import { ContentBlockModel } from "@admin/domain/content/models/ContentBlockModel";

type Banner = Partial<LeadersModel>;

type Props = {
    block: ContentBlockModel;
    index: number;
    key: number;
    onSubmit: (blockId: number) => void;
};
export default function LeadersBlock(props: Props) {
    const initBanners = (props?.block?.value?.leader_banners || []).map(
        (value) => {
            return { ...value, id: uuid() };
        },
    );

    const [banners, setBanners] = useState<Banner[]>(initBanners);

    async function addFile(
        video: File | null | undefined,
        preview: File | null | undefined,
        index: number,
    ) {
        if (!video) return;

        await loadFile(video, preview).then((video) =>
            setBanners((prev) =>
                prev.map((prevVal, prevIndex) => {
                    if (prevIndex === index) {
                        prevVal.file = video;
                        if (video.preview_file !== null) {
                            prevVal.preview_file = video.preview_file;
                        }
                    }
                    return prevVal;
                }),
            ),
        );
    }

    return (
        <div className="mb-10">
            <h4 className="mb-5">Лидеры месяца</h4>
            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <div className="mb-5">
                                <label className="form-label">Заголовок</label>
                                <input
                                    className="form-control"
                                    name={`blocks[${props.index}][value][title][${language}]`}
                                    defaultValue={
                                        props?.block?.value?.title?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="row row-cols-auto">
                {banners.map((banner, index) => (
                    <div key={banner.id} className="col mb-5">
                        <div>
                            <MultiLanguages
                                languageElement={(language) => (
                                    <div>
                                        <div className="mb-5">
                                            <label className="form-label">
                                                Информация
                                            </label>
                                            <input
                                                className="form-control"
                                                name={`blocks[${props.index}][value][leader_banners][${index}][info][${language}]`}
                                                defaultValue={
                                                    banner.info?.[language] ||
                                                    ``
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                            />
                            <div className="mb-5">
                                <label className="form-label">Имя</label>
                                <input
                                    className="form-control"
                                    name={`blocks[${props.index}][value][leader_banners][${index}][full_name]`}
                                    defaultValue={banner.full_name}
                                />
                            </div>
                            <div className="row">
                                <h4 className="mb-5">Видео файл</h4>
                                <br />
                                <div>
                                    {!banner.file && (
                                        <div className="mb-5">
                                            <div className="mb-5">
                                                <div>
                                                    <label className="form-label">
                                                        Видео файл
                                                    </label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        name="file"
                                                        accept="video/*"
                                                        onChange={(event) =>
                                                            setBanners((prev) =>
                                                                prev.map(
                                                                    (
                                                                        val,
                                                                        prevIndex,
                                                                    ) => {
                                                                        if (
                                                                            prevIndex ===
                                                                            index
                                                                        ) {
                                                                            val.video_file =
                                                                                event.target.files?.item(
                                                                                    0,
                                                                                ) as File;
                                                                        }
                                                                        return val;
                                                                    },
                                                                ),
                                                            )
                                                        }
                                                    />
                                                    <input
                                                        type="hidden"
                                                        name={`blocks[${props.index}][value][leader_banners][${index}][file_id]`}
                                                        defaultValue={undefined}
                                                    />
                                                </div>
                                                <br />
                                                <div className="mt-5">
                                                    <label className="form-label">
                                                        Превью для видео файла
                                                    </label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        name="file"
                                                        accept="image/*"
                                                        onChange={(event) =>
                                                            setBanners((prev) =>
                                                                prev.map(
                                                                    (
                                                                        val,
                                                                        prevIndex,
                                                                    ) => {
                                                                        if (
                                                                            prevIndex ===
                                                                            index
                                                                        ) {
                                                                            val.add_preview_file =
                                                                                event.target.files?.item(
                                                                                    0,
                                                                                ) as File;
                                                                        }
                                                                        return val;
                                                                    },
                                                                ),
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() =>
                                                    addFile(
                                                        banner?.video_file,
                                                        banner?.add_preview_file,
                                                        index,
                                                    )
                                                }
                                            >
                                                Загрузить файл
                                            </button>
                                        </div>
                                    )}
                                    {banner.file && (
                                        <div>
                                            <div className="card border mb-4 position-relative">
                                                <div className="card-body">
                                                    <div className="d-flex flex-column justify-content-center">
                                                        <div>
                                                            <span className="fw-bolder">
                                                                Название файла:
                                                            </span>
                                                            {banner.file?.name}
                                                        </div>
                                                        <div>
                                                            <span className="fw-bolder">
                                                                Формат файла:
                                                            </span>
                                                            {banner.file?.mime}
                                                        </div>
                                                        <div>
                                                            <span className="fw-bolder">
                                                                Дата загрузки:
                                                            </span>
                                                            {
                                                                banner.file
                                                                    ?.created_at
                                                            }
                                                        </div>
                                                        <a
                                                            href={
                                                                banner.file
                                                                    ? makePath(
                                                                          banner
                                                                              .file
                                                                              .path,
                                                                      )
                                                                    : ``
                                                            }
                                                            target="_blank"
                                                            className="fw-bolder"
                                                        >
                                                            Скачать видео
                                                        </a>
                                                        <input
                                                            type="hidden"
                                                            name={`blocks[${props.index}][value][leader_banners][${index}][file_id]`}
                                                            defaultValue={
                                                                banner.file?.id
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <h4 className="mb-5">
                                                    Превью файл
                                                </h4>
                                                <div className="card-body">
                                                    <div className="d-flex flex-column justify-content-center">
                                                        <div>
                                                            <span className="fw-bolder">
                                                                Название файла:
                                                            </span>
                                                            {
                                                                banner.file
                                                                    .preview_file
                                                                    ?.name
                                                            }
                                                        </div>
                                                        <div>
                                                            <span className="fw-bolder">
                                                                Формат файла:
                                                            </span>
                                                            {
                                                                banner.file
                                                                    .preview_file
                                                                    ?.mime
                                                            }
                                                        </div>
                                                        <div>
                                                            <span className="fw-bolder">
                                                                Дата загрузки:
                                                            </span>
                                                            {
                                                                banner.file
                                                                    .preview_file
                                                                    ?.created_at
                                                            }
                                                        </div>
                                                        <a
                                                            href={
                                                                banner.file
                                                                    .preview_file
                                                                    ? makePath(
                                                                          banner
                                                                              .file
                                                                              .preview_file
                                                                              .path,
                                                                      )
                                                                    : ``
                                                            }
                                                            target="_blank"
                                                            className="fw-bolder"
                                                        >
                                                            Скачать превью
                                                        </a>
                                                        <input
                                                            type="hidden"
                                                            name={`blocks[${props.index}][value][leader_banners][${index}][preview_file_id]`}
                                                            defaultValue={
                                                                banner.file
                                                                    .preview_file
                                                                    ?.id
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <i
                                                    className="bi bi-x-circle position-absolute text-danger end-0 mt-5 me-5 cursor-pointer"
                                                    onClick={() => {
                                                        setBanners((prev) =>
                                                            prev.map(
                                                                (
                                                                    val,
                                                                    prevIndex,
                                                                ) => {
                                                                    if (
                                                                        prevIndex ===
                                                                        index
                                                                    ) {
                                                                        val.file =
                                                                            undefined;
                                                                        val.preview_file =
                                                                            undefined;
                                                                    }
                                                                    return val;
                                                                },
                                                            ),
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div>
                                        <a
                                            className="card-link text-danger"
                                            onClick={() => {
                                                setBanners((prev) =>
                                                    prev.filter(
                                                        (value) =>
                                                            value.id !==
                                                            banner.id,
                                                    ),
                                                );
                                            }}
                                        >
                                            Удалить
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="mb-5 d-flex">
                <div className="me-5">
                    <button
                        type="button"
                        className="btn btn-success"
                        onClick={() =>
                            setBanners((prevState) => [
                                ...prevState,
                                {
                                    id: uuid(),
                                    video_file: undefined,
                                    preview_file: undefined,
                                    full_name: undefined,
                                    info: undefined,
                                },
                            ])
                        }
                    >
                        Добавить лидера
                    </button>
                </div>
            </div>
            <div>
                <a
                    className="card-link text-danger"
                    onClick={() => {
                        props.onSubmit(props.block.id);
                    }}
                >
                    Удалить блок
                </a>
            </div>
        </div>
    );
}
