import React, { useState } from "react";
import MultiLanguages from "@admin/components/MultiLanguage";
import { directoryModel } from "@admin/domain/customPages/models/CustomPageModel";
import { v4 as uuid } from "uuid";
import { ContentBlockModel } from "@admin/domain/content/models/ContentBlockModel";

type Directories = Partial<directoryModel>;

type Props = {
    block: ContentBlockModel;
    index: number;
    key: number;
    onSubmit: (blockId: number) => void;
};

export default function DocumentDirectoriesBlock(props: Props) {
    const initDirectories = (props?.block?.value?.directory_list || []).map(
        (value) => {
            return { ...value, id: uuid() };
        },
    );
    const [directories, setDirectories] =
        useState<Directories[]>(initDirectories);

    return (
        <div className="mb-10">
            <h4 className="mb-3">Блок с папками документов</h4>
            <div className="row row-cols-2">
                {directories.map((directory, index) => (
                    <div key={directory.id} className="col mb-3">
                        <div className="card-body">
                            <MultiLanguages
                                languageElement={(language) => (
                                    <div>
                                        <div className="mb-3">
                                            <label className="form-label">
                                                Название директории
                                            </label>
                                            <input
                                                className="form-control"
                                                name={`blocks[${props.index}][value][directory_list][${index}][title][${language}]`}
                                                defaultValue={
                                                    directory?.title?.[
                                                        language
                                                    ] || ``
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                            />
                            <div className="mb-3">
                                <label className="form-label">
                                    Путь к директории
                                </label>
                                <input
                                    className="form-control"
                                    name={`blocks[${props.index}][value][directory_list][${index}][url]`}
                                    defaultValue={directory?.url || ``}
                                />
                            </div>
                            <div>
                                <a
                                    className="card-link text-danger"
                                    onClick={() =>
                                        setDirectories((prev) =>
                                            prev.filter(
                                                (value) =>
                                                    value.id !== directory.id,
                                            ),
                                        )
                                    }
                                >
                                    Удалить
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="mb-5 d-flex">
                <div className="me-5">
                    <button
                        type="button"
                        className="btn btn-success"
                        onClick={() =>
                            setDirectories((directoryState) => [
                                ...directoryState,
                                { id: uuid() },
                            ])
                        }
                    >
                        Добавить папку
                    </button>
                </div>
            </div>
            <div>
                <a
                    className="card-link text-danger"
                    onClick={() => props.onSubmit(props.block.id)}
                >
                    Удалить блок
                </a>
            </div>
        </div>
    );
}
