import { post, request } from "../api";
import { DeliveryMethodGroupModel } from "@admin/domain/deliveryMethods/models/DeliveryMethodGroupModel";

export const storeOrUpdateDeliveryMethodNotifications = async (
    body: FormData,
) => {
    return await post({
        url: `/admin/delivery_methods/settings/notifications/store_or_update`,
        body,
    });
};

export const deleteAllDeliveryMethodNotifications = async () => {
    return request({
        method: `DELETE`,
        url: `/admin/delivery_methods/settings/notifications/delete_all`,
    });
};

export const storeOrUpdateDeliveryMethodGeneralConditions = async (
    body: FormData,
) => {
    return await post({
        url: `/admin/delivery_methods/settings/general_conditions/store_or_update`,
        body,
    });
};

export const updateDeliveryMethod = async (id: number, body: FormData) => {
    return await post({
        url: `/admin/delivery_methods/${id}/update`,
        body,
    });
};

const createDeliveryMethodGroup = async (body: FormData) => {
    return await post({
        url: `/admin/delivery_methods/settings/group/store`,
        body,
    });
};

const updateDeliveryMethodGroup = async (
    id: DeliveryMethodGroupModel[`id`],
    body: FormData,
) => {
    return await post({
        url: `/admin/delivery_methods/settings/group/${id}/update`,
        body,
    });
};

export const saveDeliveryMethodGroup = async (
    group: DeliveryMethodGroupModel | null,
    body: FormData,
) => {
    return group && group.id
        ? updateDeliveryMethodGroup(group.id, body)
        : createDeliveryMethodGroup(body);
};

export const saveDeliveryMethodRegion = async (
    id: number,
    configId: number,
    body: FormData,
) => {
    return await post({
        url: `/admin/delivery_methods/${id}/config/${configId}/update`,
        body,
    });
};
