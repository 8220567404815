import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import {
    Chapter,
    Story,
    StoryType,
} from "@admin/domain/chapters/models/Chapter";
import Sortable from "@admin/components/Sortable";
import OptionsModal from "@admin/components/OptionsModal";
import StoryCard from "./StoryCard";

export type StoryPartial = Partial<Story> & { uuid: string };

const OPTIONS = {
    [StoryType.Banner]: `История с баннером`,
    [StoryType.Product]: `История с продуктом`,
} as const;

type Props = {
    chapter: Chapter;
};

export default function Stories({ chapter }: Props) {
    const initStories = (chapter?.stories || []).map((value) => ({
        ...value,
        uuid: uuid(),
    }));
    const [stories, setStories] = useState<StoryPartial[]>(initStories);

    const [showModal, setShowModal] = useState(false);

    const addStory = (type: string) => {
        setStories([
            ...stories,
            {
                uuid: uuid(),
                type: type as StoryType,
            },
        ]);

        setShowModal(false);
    };

    const deleteStory = (storyId: string) => {
        setStories(stories.filter((item) => item.uuid !== storyId));
    };

    const sortStories = (order: { id: StoryPartial[`uuid`] }[]) => {
        const newStories = [...stories];

        newStories.sort(
            (a, b) =>
                order.findIndex(({ id }) => id === a.uuid) -
                order.findIndex(({ id }) => id === b.uuid),
        );

        setStories(newStories);
    };

    return (
        <>
            <div className="separator" />

            <h2 className="my-8">Истории</h2>

            <Sortable
                list={stories.map(({ uuid }) => ({ id: uuid }))}
                setList={sortStories}
                className="row"
            >
                {stories.map((story: StoryPartial, index: number) => (
                    <StoryCard
                        key={story.uuid}
                        index={index}
                        story={story}
                        remove={() => deleteStory(story.uuid)}
                    />
                ))}
            </Sortable>

            {stories.length <= 0 && <p>У сюжета нет заполненных историй</p>}

            <button
                type="button"
                className="btn btn-success"
                onClick={() => setShowModal(true)}
            >
                Добавить историю
            </button>

            <OptionsModal
                title={`Выберите тип истории`}
                options={OPTIONS}
                defaultValue={StoryType.Banner}
                show={showModal}
                onSubmit={addStory}
                close={() => setShowModal(false)}
            />
        </>
    );
}
