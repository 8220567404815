import React, { useState } from "react";
import slug from "slug";

import TextArea from "@admin/components/TextArea";
import { Language } from "@admin/domain/languages/Languages";
import { ProductCategoryTextModel } from "@admin/domain/productCategory/models/ProductCategoryModel";
import Input from "@admin/components/Input";

type Props = {
    language: Language;
    texts?: ProductCategoryTextModel[];
};

export default function Text({ language, ...props }: Props) {
    const texts = new Map(props.texts?.map((text) => [text.language, text]));

    const slugInit = new Map(
        props.texts?.map((text) => [text.language, text.slug]),
    );
    const [slugValues, setSlugValues] = useState(slugInit);

    function onTitleChange(lang: Language, value: string) {
        setSlugValues((prevState) => new Map(prevState.set(lang, slug(value))));
    }

    function onSlugChange(lang: Language, value: string) {
        setSlugValues((prevState) => new Map(prevState.set(lang, value)));
    }

    return (
        <>
            <Input
                label="Название категории"
                name={`texts[${language}][title]`}
                defaultValue={texts.get(language)?.title || ``}
                onChange={(event) =>
                    onTitleChange(language, event.target.value)
                }
            />

            <Input
                label="Символьный код для URL"
                name={`texts[${language}][slug]`}
                value={slugValues.get(language) || ``}
                onChange={(event) => onSlugChange(language, event.target.value)}
            />

            <TextArea
                label="Описание категории"
                name={`texts[${language}][description]`}
                defaultValue={texts.get(language)?.description || ``}
            />
        </>
    );
}
