import AboutPageMain from "./AboutPageMain";
import AboutPageStatistic from "./AboutPageStatistic";
import AboutPageProductsPrideOfOurCompany from "./AboutPageProductsPrideOfOurCompany";
import AboutPageComponents from "./AboutPageComponents";
import { AboutPageModel } from "@admin/domain/uniquePages/models/AboutPageModel";
import AboutPageResearch from "./AboutPageResearch";
import AboutPageFactoryRuland from "./AboutPageFactoryRuland";
import AboutPageCompanyHistory from "./AboutPageCompanyHistory";
import AboutPageGroupCompanies from "./AboutPageGroupCompanies";
import AboutPageAwards from "./AboutPageAwards";
import AboutPageGeneralDirector from "./AboutPageGeneralDirector";
import { type UniquePageProps } from "../index";

export default function AboutPage({ page }: UniquePageProps<AboutPageModel>) {
    return (
        <>
            <h2 className="mb-5">Страница "О компании"</h2>
            <AboutPageMain page={page} />
            <AboutPageStatistic page={page} />
            <AboutPageProductsPrideOfOurCompany page={page} />
            <AboutPageComponents page={page} />
            <AboutPageResearch page={page} />
            <AboutPageFactoryRuland page={page} />
            <AboutPageCompanyHistory page={page} />
            <AboutPageGroupCompanies page={page} />
            <AboutPageAwards page={page} />
            <AboutPageGeneralDirector page={page} />
        </>
    );
}
