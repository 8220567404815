import React, { useState } from "react";
import MultiLanguages from "../../MultiLanguage";
import { FaqBlocksModel } from "@admin/domain/customPages/models/CustomPageModel";
import { v4 as uuid } from "uuid";
import { ContentBlockModel } from "@admin/domain/content/models/ContentBlockModel";

type Banner = Partial<FaqBlocksModel>;

type Props = {
    block: ContentBlockModel;
    index: number;
    key: number;
    onSubmit: (blockId: number) => void;
};
export default function FaqBlock(props: Props) {
    const initBanners = (props?.block?.value?.faq_banners || []).map(
        (value) => {
            return { ...value, id: uuid() };
        },
    );

    const [banners, setBanners] = useState<Banner[]>(initBanners);

    return (
        <div className="mb-10">
            <h4 className="mb-5">Часто задаваемые вопросы</h4>
            <div>
                <input
                    type="hidden"
                    name={`blocks[${props.index}][value][id]`}
                    value={props?.block?.id}
                />
                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <div className="mb-5">
                                <label className="form-label">Заголовок</label>
                                <input
                                    className="form-control"
                                    name={`blocks[${props.index}][value][title][${language}]`}
                                    defaultValue={
                                        props?.block?.value?.title?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="row row-cols-auto">
                {banners.map((banner, index) => (
                    <div key={banner.id} className="col mb-7">
                        <MultiLanguages
                            languageElement={(language) => (
                                <div>
                                    <div className="mb-7">
                                        <label className="form-label">
                                            Вопрос
                                        </label>
                                        <textarea
                                            className="form-control"
                                            rows={5}
                                            cols={50}
                                            name={`blocks[${props.index}][value][faq_banners][${index}][question][${language}]`}
                                            defaultValue={
                                                banner?.question?.[language] ||
                                                ``
                                            }
                                        />
                                    </div>
                                    <div className="mb-7">
                                        <label className="form-label">
                                            Ответ
                                        </label>
                                        <textarea
                                            className="form-control"
                                            rows={5}
                                            cols={50}
                                            name={`blocks[${props.index}][value][faq_banners][${index}][answer][${language}]`}
                                            defaultValue={
                                                banner?.answer?.[language] || ``
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        />
                        <div>
                            <a
                                className="card-link text-danger"
                                onClick={() => {
                                    setBanners((prev) =>
                                        prev.filter(
                                            (value) => value.id !== banner.id,
                                        ),
                                    );
                                }}
                            >
                                Удалить
                            </a>
                        </div>
                    </div>
                ))}

                <div className="mb-5 d-flex">
                    <div className="me-5">
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={() =>
                                setBanners((prevState) => [
                                    ...prevState,
                                    {
                                        id: uuid(),
                                        question: undefined,
                                        answer: undefined,
                                    },
                                ])
                            }
                        >
                            Добавить
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <a
                    className="card-link text-danger"
                    onClick={() => props.onSubmit(props.block.id)}
                >
                    Удалить блок
                </a>
            </div>
        </div>
    );
}
