import Modal from "react-bootstrap/Modal";
import { Alert } from "react-bootstrap";
import LoadingImageInput from "@admin/components/LoadingImageInput";
import { makePath } from "@admin/utils/makePath";
import React, { useState } from "react";
import { AboutPageAwardBannerModel } from "@admin/domain/uniquePages/models/AboutPageModel";
import {
    initLanguageKeyObject,
    Language,
} from "@admin/domain/languages/Languages";
import { v4 as uuid } from "uuid";
import { FileModel } from "@admin/domain/files/model/FileModel";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import Input from "@admin/components/Input";
import MultiLanguages from "@admin/components/MultiLanguage";

type Props = {
    showModal: boolean;
    closeModal: () => void;
    onSubmit: (data: AboutPageAwardBannerModel) => void;
    editableAward?: AboutPageAwardBannerModel;
};

type Banner = Partial<Pick<AboutPageAwardBannerModel, `file`>> &
    Omit<AboutPageAwardBannerModel, `file`>;

export default function AboutPageAwardsModal(props: Props) {
    const languageContext = useLanguageContext();
    const [banner, setBanner] = useState<Banner>(
        props.editableAward || {
            title: initLanguageKeyObject(),
            sub_title: initLanguageKeyObject(),
            id: uuid(),
        },
    );

    const [errors, setErrors] = useState<string[]>([]);
    const submit = () => {
        const newErrors: string[] = [];
        if (!banner.file) newErrors.push(`Изображение обязательно`);
        for (const language of languageContext.availableLanguages) {
            if (!banner.title[language])
                newErrors.push(
                    `Поле "${language} Заголовок" обязательно для заполнения`,
                );
        }
        if (newErrors.length > 0) {
            setErrors(newErrors);
            return;
        }

        props.onSubmit({ ...banner, file: banner.file as FileModel });
        props.closeModal();
    };

    return (
        <Modal centered show={props.showModal} onHide={props.closeModal}>
            <Modal.Header>
                <Modal.Title>
                    {props.editableAward ? `Обновление` : `Добавление`} награды
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {errors.length > 0 && (
                    <Alert variant="danger">
                        {errors.map((err, index) => (
                            <div key={index}>{err}</div>
                        ))}
                    </Alert>
                )}

                <div className="d-flex align-items-center justify-content-center">
                    <LoadingImageInput
                        width="660px"
                        height="475px"
                        onChange={(file) =>
                            setBanner((prev) => ({ ...prev, file: file }))
                        }
                        src={banner.file ? makePath(banner.file.path) : ``}
                    />
                </div>

                <MultiLanguages
                    languageElement={(language: Language) => (
                        <>
                            <Input
                                label="Заголовок"
                                onChange={(event) => {
                                    setBanner((prev) => {
                                        const title = prev.title;
                                        title[language] = event.target.value;
                                        return { ...prev, title: title };
                                    });
                                }}
                                value={banner.title[language]}
                            />
                            <Input
                                label="Текст под заголовком"
                                onChange={(event) => {
                                    setBanner((prev) => {
                                        const subTitle = prev.sub_title;
                                        subTitle[language] = event.target.value;
                                        return { ...prev, sub_title: subTitle };
                                    });
                                }}
                                value={banner.sub_title[language]}
                            />
                        </>
                    )}
                />
            </Modal.Body>

            <Modal.Footer>
                <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={props.closeModal}
                >
                    Закрыть
                </button>
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={submit}
                >
                    {props.editableAward ? `Обновить` : `Добавить`}
                </button>
            </Modal.Footer>
        </Modal>
    );
}
