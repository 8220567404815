export type EmbedVideoJson = {
    title: string;
    author_name: string;
    author_url: string;
    type: string;
    height: number;
    width: number;
    version: string;
    provider_name: string;
    provider_url: string;
    thumbnail_height: number;
    thumbnail_width: number;
    thumbnail_url: string;
    html: string;
};

export async function getEmbedVideo(url: string) {
    const response = await fetch(
        `https://www.youtube.com/oembed?url=${url}format=json`,
        { method: `GET` },
    );
    const json: Promise<EmbedVideoJson> = await response.json();
    return json;
}
