import React, { createRef, useReducer } from "react";

import { getProductByCharacteristicId } from "@admin/domain/products/api";
import MultiLanguages from "@admin/components/MultiLanguage";
import { ProductCategoryModel } from "@admin/domain/productCategory/models/ProductCategoryModel";
import { ProductModel } from "@admin/domain/products/models/ProductModel";
import AdditionalProduct from "./AdditionalProduct";
import AdditionalProductText from "./AdditionalProductText";

export type ProductsReducerProps = {
    action: `delete` | `add`;
    productId?: string;
    product?: ProductModel;
};

type Props = {
    productCategory: ProductCategoryModel | null;
};

function reducer(
    products: ProductModel[],
    reducerProps: ProductsReducerProps,
): ProductModel[] {
    switch (reducerProps.action) {
        case `add`:
            return [...products, reducerProps?.product as ProductModel];

        case `delete`:
            return products.filter(
                (value) => value.id !== reducerProps.productId,
            );

        default:
            throw Error(`Unknown action: ` + reducerProps.action);
    }
}

export default function AdditionalProducts({ productCategory }: Props) {
    const [products, dispatch] = useReducer(
        reducer,
        productCategory?.additional_products || [],
    );
    const inputRef = createRef<HTMLInputElement>();

    function addAdditionalProduct() {
        const characteristicId = inputRef.current?.value;
        if (!characteristicId) {
            return;
        }

        if (
            products.find(
                (value) => value.characteristic_id === characteristicId,
            )
        ) {
            return;
        }

        getProductByCharacteristicId(characteristicId).then((product) => {
            if (!product) {
                return;
            }

            dispatch({ action: `add`, product: product });
        });
    }

    const remove = (id: ProductModel[`id`]) => {
        dispatch({
            action: `delete`,
            productId: id,
        });
    };

    return (
        <div className="mb-5">
            <h4 className="mb-5">Дополнительные товары</h4>

            <MultiLanguages
                languageElement={(language) => (
                    <AdditionalProductText
                        language={language}
                        texts={productCategory?.texts}
                    />
                )}
            />

            <label className="form-label" htmlFor="input_additional_product">
                Дополнительные товары
            </label>

            <div className="input-group mb-5 w-50">
                <input
                    id="input_additional_product"
                    type="text"
                    className="form-control"
                    placeholder="Характеристика товара"
                    ref={inputRef}
                />
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => addAdditionalProduct()}
                >
                    Добавить
                </button>
            </div>

            {products.map((value) => (
                <AdditionalProduct
                    key={value.id}
                    product={value}
                    remove={remove}
                />
            ))}
        </div>
    );
}
