import React from "react";
import { ProductVideoModel } from "@admin/domain/products/models/edit/ProductVideoModel";
import { ProductVideoProvider } from "./ProductVideosContext";
import ProductVideoList from "./ProductVideoList";
import AddProductVideo from "./AddProductVideo";
import ModalVideoProvider from "@admin/components/ModalVideo";

export default function ProductVideos({
    productVideos,
}: {
    productVideos: ProductVideoModel[];
}) {
    return (
        <ProductVideoProvider productVideosInit={productVideos}>
            <div className="rounded border p-10 mb-5">
                <h4 className="mb-5">Видео о товаре</h4>
                <AddProductVideo />
                <ModalVideoProvider>
                    <ProductVideoList />
                </ModalVideoProvider>
            </div>
        </ProductVideoProvider>
    );
}
