import React from "react";
import Input from "@admin/components/Input";
import TextArea from "@admin/components/TextArea";
import DateTimeInput from "@admin/components/DateTimeInput";
import CheckBox from "@admin/components/CheckBox";
import MultiLanguages from "@admin/components/MultiLanguage";
import { ContentPromotionModel } from "@admin/domain/complexPromotions/content/models/ContentPromotionModel";
import { useIsInvalid } from "@admin/components/FormErrorsContext";

type Props = {
    promotion: ContentPromotionModel | null;
};

const ContentPromotionFields: React.FC<Props> = ({ promotion }) => (
    <>
        <MultiLanguages
            hasErrors={(language) =>
                useIsInvalid(`promotion[texts][${language}]`)
            }
            languageElement={(language) => (
                <>
                    <Input
                        label="Название"
                        defaultValue={promotion?.texts[language]?.title}
                        name={`promotion[texts][${language}][title]`}
                    />
                    <TextArea
                        label="Описание"
                        defaultValue={promotion?.texts[language]?.description}
                        name={`promotion[texts][${language}][description]`}
                    />
                </>
            )}
        />

        <DateTimeInput
            label="Начало акции"
            name="promotion[active_from]"
            defaultValue={promotion?.active_from || ``}
        />
        <DateTimeInput
            label="Конец акции"
            name="promotion[active_to]"
            defaultValue={promotion?.active_to || ``}
        />
        <CheckBox
            name="promotion[product_auto_addition]"
            label="Автоматически добавлять товары в корзину"
            defaultChecked={promotion ? promotion.product_auto_addition : true}
        />
        <CheckBox
            name="promotion[only_for_site]"
            label="Применять только для сайта"
            defaultChecked={promotion ? promotion.only_for_site : true}
        />
    </>
);

export default ContentPromotionFields;
