import React, { useState } from "react";

import MultiLanguages from "@admin/components/MultiLanguage";
import { Language } from "@admin/domain/languages/Languages";
import CheckBox from "@admin/components/CheckBox";
import Input from "@admin/components/Input";
import TextArea from "@admin/components/TextArea";
import PublishedAtInput from "@admin/components/PublishedAtInput";
import LoadingImageInput from "@admin/components/LoadingImageInput";
import { FileModel } from "@admin/domain/files/model/FileModel";
import DateTimeInput from "@admin/components/DateTimeInput";
import Select from "@admin/components/Select";
import { ArticleTextModel } from "@admin/domain/articles/models/ArticleTextModel";
import ArticleVideo from "./ArticleVideo";

const ArticleGeneralData: React.FC = () => {
    const { article, admins } = window.CMS;

    const texts = article?.texts || [];
    const textMap = new Map(
        texts.map((text: ArticleTextModel): [Language, ArticleTextModel] => [
            text.language,
            text,
        ]),
    );

    const [previewImage, setPreviewImage] = useState<FileModel | null>(
        article?.preview_image || null,
    );

    const [authorAvatar, setAuthorAvatar] = useState<FileModel | null>(
        article?.author_avatar || null,
    );

    return (
        <>
            {article && (
                <>
                    <Input label="ID" defaultValue={article?.id} disabled />

                    <DateTimeInput
                        label="Дата создания"
                        defaultValue={article?.created_at}
                        disabled
                    />

                    <DateTimeInput
                        label="Дата изменения"
                        defaultValue={article?.updated_at}
                        disabled
                    />
                </>
            )}

            <PublishedAtInput defaultValue={article?.published_at} />

            <Input
                label="Символьный код для URL"
                defaultValue={article?.path}
                name="path"
                required
            />

            <MultiLanguages
                languageElement={(language: Language): React.JSX.Element => (
                    <>
                        <Input
                            label="Заголовок страницы"
                            defaultValue={textMap.get(language)?.title}
                            name={`texts[${language}][title]`}
                        />

                        <Input
                            label="ФИО автора"
                            defaultValue={
                                textMap.get(language)?.author_full_name
                            }
                            name={`texts[${language}][author_full_name]`}
                            required
                        />

                        <Input
                            label="Должность автора"
                            defaultValue={
                                textMap.get(language)?.author_achievement
                            }
                            name={`texts[${language}][author_achievement]`}
                            required
                        />

                        <TextArea
                            label="Аннотация"
                            defaultValue={textMap.get(language)?.annotation}
                            name={`texts[${language}][annotation]`}
                        />

                        <input
                            type="hidden"
                            name={`texts[${language}][language]`}
                            defaultValue={language}
                        />
                    </>
                )}
            />

            <Input
                type={`number`}
                label="Примерное время на изучение (в минутах)"
                defaultValue={article?.study_time}
                name={`study_time`}
            />

            <LoadingImageInput
                height={300}
                src={article?.preview_image?.path}
                onChange={setPreviewImage}
                label="Изображение-превью"
            />
            <input
                type="hidden"
                name="preview_image_id"
                value={previewImage?.id || ``}
            />

            <ArticleVideo article={article} />

            <LoadingImageInput
                height={300}
                src={article?.author_avatar?.path}
                onChange={setAuthorAvatar}
                label="Аватар автора"
            />
            <input
                type="hidden"
                name="author_avatar_id"
                value={authorAvatar?.id || ``}
            />

            <Select
                label="Автор"
                options={admins}
                defaultValue={article?.author?.id}
                name="author_id"
                required
            />

            <CheckBox
                label="Скрыть / Показать Комментарии"
                name="is_visible_comments"
                defaultChecked={!article || article.is_visible_comments}
            />

            <CheckBox
                label="Скрыть / Показать блок Читайте так же"
                name="is_visible_other_articles"
                defaultChecked={!article || article.is_visible_other_articles}
            />

            <CheckBox
                label="Скрыть / Показать"
                name="is_public"
                defaultChecked={!article || article.is_public}
            />
        </>
    );
};

export default ArticleGeneralData;
