import Input from "@admin/components/Input";
import TextArea from "@admin/components/TextArea";
import { Language } from "@admin/domain/languages/Languages";
import { ProductExpertOpinionTextModel } from "@admin/domain/products/models/edit/ProductExpertOpinionModel";

type TextsProps = {
    language: Language;
    texts?: Map<Language, ProductExpertOpinionTextModel>;
};

export default function Text(props: TextsProps) {
    return (
        <>
            <input
                type="hidden"
                name={`expert[texts][${props.language}][language]`}
                defaultValue={props.language}
            />

            <Input
                label="ФИО эксперта"
                name={`expert[texts][${props.language}][full_name]`}
                defaultValue={props.texts?.get(props.language)?.full_name || ``}
            />

            <TextArea
                label="Должность/Заслуги эксперта"
                name={`expert[texts][${props.language}][achievement]`}
                value={props.texts?.get(props.language)?.achievement || ``}
            />

            <TextArea
                label="Текст мнения эксперта"
                name={`expert[texts][${props.language}][text]`}
                value={props.texts?.get(props.language)?.text || ``}
            />
        </>
    );
}
