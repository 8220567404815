import {
    ItemInterface,
    ReactSortable,
    ReactSortableProps,
} from "react-sortablejs";

export default class<T extends ItemInterface> extends ReactSortable<T> {
    static defaultProps: Partial<ReactSortableProps<any>> = {
        animation: 500,
        forceAutoScrollFallback: true,
    };
}
