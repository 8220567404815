import Modal from "react-bootstrap/Modal";
import { Alert } from "react-bootstrap";
import LoadingImageInput from "@admin/components/LoadingImageInput";
import { makePath } from "@admin/utils/makePath";
import React, { useState } from "react";
import { BuyerChoiceBannerModel } from "@admin/domain/uniquePages/models/MainPageModel";
import { FileModel } from "@admin/domain/files/model/FileModel";
import Input from "@admin/components/Input";

type Props = {
    showModal: boolean;
    closeModal: () => void;
    onSubmit: (data: BuyerChoiceBannerModel) => void;
    editableBanner?: BuyerChoiceBannerModel;
};

type Banner = Partial<Pick<BuyerChoiceBannerModel, `file`>> &
    Omit<BuyerChoiceBannerModel, `file`>;

export default function MainPageBuyerChoiceModal(props: Props) {
    const [banner, setBanner] = useState<Banner>(
        // @ts-ignore
        props.editableBanner || {},
    );

    const [errors, setErrors] = useState<string[]>([]);
    const submit = () => {
        const newErrors: string[] = [];
        if (!banner.file) newErrors.push(`Изображение обязательно`);
        if (newErrors.length > 0) {
            setErrors(newErrors);
            return;
        }

        props.onSubmit({ ...banner, file: banner.file as FileModel });
        props.closeModal();
    };

    return (
        <Modal centered show={props.showModal} onHide={props.closeModal}>
            <Modal.Header>
                <Modal.Title>
                    {props.editableBanner ? `Обновление` : `Добавление`} банера
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errors.length > 0 && (
                    <Alert variant="danger">
                        {errors.map((err, index) => (
                            <div key={index}>{err}</div>
                        ))}
                    </Alert>
                )}
                <div className="align-items-center justify-content-center">
                    <LoadingImageInput
                        width="400px"
                        height="400px"
                        onChange={(file) =>
                            setBanner((prev) => ({ ...prev, file: file }))
                        }
                        src={banner.file ? makePath(banner.file.path) : ``}
                    />
                    <Input
                        label="Текст отзыва"
                        onChange={(event) =>
                            setBanner((prev) => ({
                                ...prev,
                                text: event.target.value,
                            }))
                        }
                        value={banner.text}
                    />
                    <Input
                        label="Имя создателя отзыва"
                        onChange={(event) =>
                            setBanner((prev) => ({
                                ...prev,
                                reviewer_name: event.target.value,
                            }))
                        }
                        value={banner.reviewer_name}
                    />
                    <span>Аватар</span>
                    <br />
                    <LoadingImageInput
                        width="200px"
                        height="200px"
                        onChange={(file) =>
                            setBanner((prev) => ({
                                ...prev,
                                reviewer_file: file,
                            }))
                        }
                        src={
                            banner.reviewer_file
                                ? makePath(banner.reviewer_file.path)
                                : ``
                        }
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-secondary"
                    onClick={props.closeModal}
                >
                    Закрыть
                </button>
                <button className="btn btn-primary" onClick={submit}>
                    {props.editableBanner ? `Обновить` : `Добавить`}
                </button>
            </Modal.Footer>
        </Modal>
    );
}
