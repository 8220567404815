import { DeliveryPointModel } from "@admin/domain/deliveryPoints/models/DeliveryPointModel";
import { useState } from "react";

export type UsePhonesHook = {
    phones: ReadonlyArray<string>;
    addPhone: () => void;
    deletePhone: (phoneIndex: number) => void;
};

export const usePhones = (): UsePhonesHook => {
    // noinspection TypeScriptUnresolvedReference
    const CMS = window.CMS || {};
    const { deliveryPoint }: DeliveryPointModel | null = CMS;
    const [phones, setPhones] = useState<ReadonlyArray<string>>(
        deliveryPoint?.phones || [``],
    );

    const addPhone = (): void => {
        setPhones([...phones, ``]);
    };

    const deletePhone = (phoneIndex: number): void => {
        if (!confirm(`Вы уверены удалить телефон?`)) {
            return;
        }

        setPhones(
            phones.filter(
                (phone: string, index: number): boolean => phoneIndex !== index,
            ),
        );
    };

    return {
        phones,
        addPhone,
        deletePhone,
    };
};
