import { post, request } from "../api";
import { FileModel } from "../files/model/FileModel";
import { ActiveComponentsModel } from "./models/edit/ProductSpecificationsModel";
import { ProductModel } from "./models/ProductModel";
import { ProductAdditionalAttributesModel } from "@admin/domain/products/models/edit/ProductAdditionalAttributesModel";

export type FoundProductsPayload = {
    products: ReadonlyArray<FoundProduct>;
};

export type FoundProduct = {
    id: ProductModel[`id`];
    title: ProductModel[`texts`][0][`title`];
};

export const searchProducts = async (
    query: string,
): Promise<FoundProductsPayload> => {
    return await request({
        url: `/admin/products/search`,
        query: { query },
    });
};

export const updateProduct = async (
    id: ProductAdditionalAttributesModel[`id`],
    body: FormData,
) => {
    return await post({
        url: `/admin/products/${id}`,
        body,
    });
};

export const loadFile = async (
    file: File,
    previewFile?: File | null,
): Promise<FileModel> => {
    const formData = new FormData();

    formData.append(`file`, file as Blob);

    if (previewFile) {
        formData.append(`preview_file`, previewFile as Blob);
    }

    return await post({ url: `/admin/files`, body: formData });
};

export const getProductByCharacteristicId = async (
    characteristicId: string,
): Promise<ProductModel | undefined> => {
    return await request({
        url: `/admin/products/find_product_by_characteristic_id/${characteristicId}`,
    });
};

export const getActiveComponentByAccountingId = async (
    accountingId: string,
): Promise<ActiveComponentsModel> => {
    return await request({
        url: `/admin/products/find_active_component/${accountingId}`,
    });
};

export const getCrossSaleProduct = async (
    characteristicId: string,
): Promise<ProductModel | null> => {
    return await request({
        url: `/admin/products/find_cross_sale/${characteristicId}`,
    });
};
