import React, { ChangeEvent, useEffect, useState } from "react";

import CheckBox from "@admin/components/CheckBox";
import { getThemes } from "@admin/domain/articles/api";
import { ArticleThemeModel } from "@admin/domain/articles/models/ArticleThemeModel";
import Select from "@admin/components/Select";

type ArticleThemesProps = {
    article?: any;
};

export default function ArticleThemes({ article }: ArticleThemesProps) {
    const [options, setOptions] = useState<Record<string, string>>({});

    const [value, setValue] = useState<string>(article?.theme?.theme_id || ``);

    const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setValue(event.target.value);

        if (article && article.theme) {
            article.theme.sub_theme_ids = [];
        }
    };

    const [themes, setThemes] = useState<ArticleThemeModel[]>([]);

    useEffect(() => {
        getThemes().then((themes) => {
            const options = {
                "": ``,
            };
            (themes || []).forEach(({ id, title }) => {
                options[id] = title;
            });
            setOptions(options);

            setThemes(themes || []);
        });
    }, []);

    const subThemes =
        (value
            ? themes.find((item) => `${item.id}` === `${value}`)?.sub_themes
            : []) || [];

    return (
        <>
            <Select
                label="Тема"
                options={options}
                value={value}
                name="theme_id"
                onChange={onChange}
                required
            />

            {subThemes.length > 0 && (
                <>
                    <label className="form-label">Подтемы</label>
                    {subThemes.map(
                        (subTheme: ArticleThemeModel, index: number) => (
                            <CheckBox
                                key={subTheme.id || `new-block-${index}`}
                                className="form-check-input"
                                type="checkbox"
                                value={subTheme?.id}
                                name={`sub_theme_ids[${index}]`}
                                defaultChecked={article?.theme?.sub_theme_ids?.includes(
                                    subTheme?.id,
                                )}
                                label={subTheme?.title as string}
                            />
                        ),
                    )}
                </>
            )}
        </>
    );
}
