import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import {
    type MainBlockBannerModel,
    type MainPageModel,
} from "@admin/domain/uniquePages/models/MainPageModel";
import { type UniquePageProps } from "../../index";
import Sortable from "@admin/components/Sortable";
import AddBannerModal from "../Common/AddBannerModal";
import BannerImages from "./BannerImages";
import BannerFields from "./BannerFields";
import BannerButtons from "./BannerButtons";

export default function MainPageMainBanners({
    page,
}: UniquePageProps<MainPageModel>) {
    const bannersInit = (page.contentObject.main_block__banners || []).map(
        (banner: MainBlockBannerModel): MainBlockBannerModel => ({
            ...banner,
            id: uuid(),
            with_mobile_app_file: !!banner.mobile_app_file,
        }),
    );

    const [showModal, setShowModal] = useState(false);
    const [banners, setBanners] = useState(bannersInit);
    const [editableBanner, setEditableBanner] = useState<
        MainBlockBannerModel | undefined
    >();

    const addBanner = (data: MainBlockBannerModel): void => {
        setBanners([...banners, data]);
    };

    const editBanner = (data: MainBlockBannerModel): void => {
        setBanners(
            banners.map((value) => {
                if (value.id === data.id) value = data;
                return value;
            }),
        );
    };

    return (
        <div className="mb-10">
            <h4 className="mb-5">Основной блок со слайдером баннеров</h4>
            <Sortable
                list={banners}
                setList={setBanners}
                className="row row-cols-auto"
            >
                {banners.map((banner: MainBlockBannerModel, index: number) => (
                    <div
                        key={banner.id}
                        className="mb-5"
                        style={{ width: `fit-content`, height: `fit-content` }}
                    >
                        <div className="card border">
                            <BannerImages banner={banner} index={index} />
                            <div className="card-body">
                                <BannerFields banner={banner} index={index} />
                                <BannerButtons
                                    banner={banner}
                                    index={index}
                                    setShowModal={setShowModal}
                                    setEditableBanner={setEditableBanner}
                                    setBanners={setBanners}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </Sortable>
            {banners.length < 10 && (
                <i
                    className="bi bi-plus-square text-primary fs-2hx cursor-pointer ms-3 align-self-center"
                    onClick={() => {
                        setEditableBanner(undefined);
                        setShowModal(true);
                    }}
                />
            )}
            {showModal && (
                <AddBannerModal
                    showModal={showModal}
                    closeModal={() => setShowModal(false)}
                    onSubmit={editableBanner ? editBanner : addBanner}
                    editableBanner={editableBanner}
                    withMobileAppBanner
                />
            )}
        </div>
    );
}
