import { FileModel } from "@admin/domain/files/model/FileModel";
import Input from "@admin/components/Input";
import TextArea from "@admin/components/TextArea";
import LoadingImageInput from "@admin/components/LoadingImageInput";
import MultiLanguages from "@admin/components/MultiLanguage";
import { LanguageKeyObject } from "@admin/domain/languages/Languages";
import { makePath } from "@admin/utils/makePath";
import React, { useState } from "react";

type Props = {
    title: string;
    name: string;
    inputs: {
        title: LanguageKeyObject;
        description: LanguageKeyObject;
        icon: FileModel;
        url: string;
    };
};

export default function DeliveryAndPaymentBlockItem(props: Props) {
    const [icon, setIcon] = useState(props.inputs.icon);

    return (
        <div className="col card border m-2 p-8">
            <h4 className="mb-5">{props.title}</h4>
            <LoadingImageInput
                width="80px"
                height="80px"
                src={makePath(icon.path)}
                onChange={(file) => setIcon(file)}
            />
            <input
                type="hidden"
                name={`content[${props.name}__icon_id]`}
                value={icon.id}
            />
            <Input
                label="Ссылка"
                defaultValue={props.inputs.url}
                name={`content[${props.name}__url]`}
            />
            <MultiLanguages
                languageElement={(language) => (
                    <>
                        <Input
                            label="Заголовок"
                            defaultValue={props.inputs.title[language]}
                            name={`content[${props.name}__title][${language}]`}
                        />
                        <TextArea
                            label="Описаине под заголовком"
                            defaultValue={props.inputs.description[language]}
                            name={`content[${props.name}__description][${language}]`}
                        />
                    </>
                )}
            />
        </div>
    );
}
