import React from "react";
import ProductsSearch from "@admin/components/ProductsSearch";
import { usePromotionProductsContext } from "./ContentPromotionProvider";
import { ContentPromotionRewardModel } from "@admin/domain/complexPromotions/content/models/ContentPromotionRewardModel";
import Input from "@admin/components/Input";

const ContentPromotionRewardProducts: React.FC = () => {
    const { rewardProducts, addRewardProduct, deleteRewardProduct } =
        usePromotionProductsContext();

    return (
        <>
            <h3>Подарки / Скидки на товар</h3>

            <div className="rounded border p-10 my-5 me-5">
                {rewardProducts.map(
                    (
                        reward: ContentPromotionRewardModel,
                        rewardIndex: number,
                    ): React.JSX.Element => {
                        const isDiscount =
                            (!reward.id && !reward.is_gift) ||
                            reward.discount_product_id;

                        return (
                            <div
                                key={reward.id || `new-reward-${rewardIndex}`}
                                className="rounded border p-10 my-5 me-5"
                            >
                                {reward.id && (
                                    <input
                                        type="hidden"
                                        value={reward.id}
                                        name={`reward_products[${rewardIndex}][id]`}
                                    />
                                )}
                                <div className="d-flex">
                                    <div className="rounded border p-10 my-5 me-5 w-100">
                                        <ProductsSearch
                                            label={
                                                isDiscount
                                                    ? `Заменяемый товар`
                                                    : `Подарок`
                                            }
                                            inputName={`reward_products[${rewardIndex}][product_id]`}
                                            productId={reward.product_id}
                                            productTitle={reward.product_title}
                                        />
                                    </div>
                                    {isDiscount && (
                                        <div className="rounded border p-10 my-5 me-5 w-100">
                                            <ProductsSearch
                                                label="Товар по скидке"
                                                inputName={`reward_products[${rewardIndex}][discount_product_id]`}
                                                productId={
                                                    reward.discount_product_id
                                                }
                                                productTitle={
                                                    reward.discount_product_title
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                                <Input
                                    label="Количество"
                                    defaultValue={reward.quantity}
                                    name={`reward_products[${rewardIndex}][quantity]`}
                                />

                                {rewardProducts.length > 1 && (
                                    <button
                                        type="button"
                                        className="btn btn-danger me-2"
                                        onClick={(): void =>
                                            deleteRewardProduct(rewardIndex)
                                        }
                                    >
                                        Удалить{` `}
                                        {isDiscount ? `скидку` : `подарок`}
                                    </button>
                                )}
                            </div>
                        );
                    },
                )}

                <button
                    type="button"
                    className="btn btn-primary me-2"
                    onClick={(): void => addRewardProduct(true)}
                >
                    Добавить подарок
                </button>

                <button
                    type="button"
                    className="btn btn-primary me-2"
                    onClick={(): void => addRewardProduct(false)}
                >
                    Добавить скидку на товар
                </button>
            </div>
        </>
    );
};

export default ContentPromotionRewardProducts;
