import { ProductMediaFilesProvider } from "./ProductMediaFilesContext";
import ProductMediaFileList from "./ProductMediaFileList";
import { FileModel } from "@admin/domain/files/model/FileModel";
import ProductMediaFilesButtons from "./ProductMediaFilesButtons";
import ModalVideoProvider from "@admin/components/ModalVideo";

export default function ProductMediaFiles({
    productMediaFile,
}: {
    productMediaFile: FileModel[];
}) {
    return (
        <ProductMediaFilesProvider productMediaFilesInit={productMediaFile}>
            <div className="rounded border p-10 mb-5">
                <ProductMediaFilesButtons />
                <ModalVideoProvider>
                    <ProductMediaFileList />
                </ModalVideoProvider>
            </div>
        </ProductMediaFilesProvider>
    );
}
