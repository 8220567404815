import { useState } from "react";
import { FileModel } from "@admin/domain/files/model/FileModel";
import { loadFile as loadFileRequest } from "@admin/domain/products/api";

type FileItem = {
    file: FileModel | undefined;
    client_file: File | undefined;
};

const useFiles = (initFiles: FileModel[]) => {
    const [files, setFiles] = useState<FileItem[]>(
        initFiles.map(
            (file: FileModel): FileItem => ({
                file,
                client_file: undefined,
            }),
        ),
    );

    async function loadFile(
        file: File | null | undefined,
        index: number,
    ): Promise<void> {
        if (!file) {
            alert(`Необходимо выбрать файл!`);
            return;
        }

        const loadedFile = await loadFileRequest(file);

        setFiles(
            files.map((prevFile, prevIndex) => {
                if (prevIndex === index) {
                    return {
                        file: loadedFile,
                        client_file: undefined,
                    };
                }

                return prevFile;
            }),
        );
    }

    const addFile = (): void =>
        setFiles([
            ...files,
            {
                file: undefined,
                client_file: undefined,
            },
        ]);

    const loadClientFile = (index: number, file: File): void =>
        setFiles(
            files.map((prevFile, prevIndex) => {
                if (prevIndex === index) {
                    return {
                        file: undefined,
                        client_file: file,
                    };
                }

                return prevFile;
            }),
        );

    const deleteFile = (index: number): void =>
        setFiles(files.filter((_prevFile, prevIndex) => prevIndex !== index));

    const resetFile = (index: number): void =>
        setFiles(
            files.map((prevFile, prevIndex) => {
                if (prevIndex === index) {
                    return {
                        file: undefined,
                        client_file: undefined,
                    };
                }

                return prevFile;
            }),
        );

    return {
        files,
        loadFile,
        deleteFile,
        resetFile,
        loadClientFile,
        addFile,
    };
};

export default useFiles;
