export type HasLanguage = {
    language: Language;
};

export enum Language {
    Russian = `RU`,
    English = `EN`,
    Turkish = `TR`,
}

export default function convertToMapLanguageObject<T extends HasLanguage>(
    array: T[],
): Map<Language, T> {
    return new Map<Language, T>(array.map((value) => [value.language, value]));
}

export type LanguageKeyObject<T = string> = { [K in Language]?: T };

export const initLanguageKeyObject = (): LanguageKeyObject => ({
    [Language.Russian]: ``,
    [Language.English]: ``,
    [Language.Turkish]: ``,
});
