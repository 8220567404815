import React from "react";
import { createRoot } from "react-dom/client";

import ready from "@admin/utils/ready";
import App from "@admin/App";

import "@admin/sass/style.scss";

ready((): void => {
    const container = document.getElementById(`app`);

    if (container) {
        createRoot(container).render(<App />);
    }
});
