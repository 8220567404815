import { makePath } from "@admin/utils/makePath";
import React from "react";
import { useProductMediaFilesDispatch } from "./ProductMediaFilesContext";
import { FileModel } from "@admin/domain/files/model/FileModel";
import { useSetModalVideo } from "@admin/components/ModalVideo";
import { VideoJs } from "@admin/components/VideoJs";

type productMediaFileProps = {
    file: FileModel;
};

export default function ProductMediaFile(props: productMediaFileProps) {
    const dispatch = useProductMediaFilesDispatch();
    const setModalVideo = useSetModalVideo();

    function showButton() {
        if (props.file.preview_file) {
            return (
                <div
                    className="link link-primary cursor-pointer"
                    onClick={() =>
                        setModalVideo({
                            show: true,
                            body: (
                                <VideoJs source={makePath(props.file.path)} />
                            ),
                        })
                    }
                >
                    Посмотреть
                </div>
            );
        } else {
            return (
                <a
                    className="link link-success"
                    href={makePath(props.file.path)}
                    target="_blank"
                >
                    Посмотреть
                </a>
            );
        }
    }

    return (
        <div className="card border mb-4 position-relative">
            <div className="card-body">
                <div className="d-flex">
                    {!props.file.preview_file && (
                        <img
                            className="me-3 img-thumbnail product_list_img"
                            src={makePath(props.file.path)}
                            alt="image"
                        />
                    )}
                    <div className="d-flex flex-column justify-content-center">
                        <div>
                            <span className="fw-bolder">Название файла:</span>
                            {` `}
                            {props.file.name}
                        </div>
                        <div>
                            <span className="fw-bolder">Формат файла:</span>
                            {` `}
                            {props.file.mime}
                        </div>
                        <div>
                            <span className="fw-bolder">Размер файла:</span>
                            {` `}
                            {props.file.metadata.size}
                        </div>
                        {showButton()}
                        <input
                            type="hidden"
                            name="product_attributes[files][]"
                            defaultValue={props.file.id}
                        />
                    </div>
                </div>

                {props.file.preview_file && (
                    <div className="mt-5 ms-5">
                        <div className="fw-bolder mb-2">Превью файл:</div>
                        <div className="d-flex">
                            <img
                                className="me-3 img-thumbnail product_list_img"
                                src={makePath(props.file.preview_file.path)}
                                alt="image"
                            />
                            <div className="d-flex flex-column justify-content-center">
                                <div>
                                    <span className="fw-bolder">
                                        Название файла:
                                    </span>
                                    {props.file.preview_file.name}
                                </div>
                                <div>
                                    <span className="fw-bolder">
                                        Формат файла:
                                    </span>
                                    {props.file.preview_file.mime}
                                </div>
                                <div>
                                    <span className="fw-bolder">
                                        Размер файла:
                                    </span>
                                    {props.file.preview_file.metadata.size}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <i
                className="bi bi-x-circle position-absolute text-danger end-0 mt-5 me-5 cursor-pointer"
                onClick={() =>
                    dispatch({ type: `delete`, deletedId: props.file.id })
                }
            />
        </div>
    );
}
