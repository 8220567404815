import React, { createContext, useContext } from "react";

import {
    type UsePromotionHook,
    usePromotionProducts,
} from "./hooks/usePromotionProducts";

export const Context = createContext<UsePromotionHook>({
    rewardProducts: [],
    basketProducts: [],
    addBasketProduct: () => null,
    deleteBasketProduct: () => null,
    addRewardProduct: () => null,
    deleteRewardProduct: () => null,
});

export const ContentPromotionProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    return (
        <Context.Provider value={usePromotionProducts()}>
            {children}
        </Context.Provider>
    );
};

export const usePromotionProductsContext = () => useContext(Context);
