import React, { useEffect, useState } from "react";
import MultiLanguages from "@admin/components/MultiLanguage";
import { type UniquePageProps } from "../index";
import { FileModel } from "@admin/domain/files/model/FileModel";
import { loadFile } from "@admin/domain/products/api";
import { makePath } from "@admin/utils/makePath";
import { BusinessExpansionPageModel } from "@admin/domain/uniquePages/models/BusinessExpansionPageModel";
import LoadingImageInput from "@admin/components/LoadingImageInput";

export default function BusinessExpansionPageStoreServiceCenter({
    page,
}: UniquePageProps<BusinessExpansionPageModel>) {
    const [image, setImage] = useState(
        page.contentObject?.service_center_block__banner_file,
    );
    const [files, setFiles] = useState<{
        video: File | null;
        preview: File | null;
        file: File | null;
    }>({ video: null, preview: null, file: null });
    const [video, setVideo] = useState<FileModel | null>(null);
    const [file, setFile] = useState<FileModel | null>(null);

    useEffect(() => {
        if (page.contentObject?.service_center_block__video_file) {
            setVideo(page.contentObject.service_center_block__video_file);
        }
    }, [page.contentObject?.service_center_block__video_file]);

    useEffect(() => {
        if (page.contentObject?.service_center_block__file) {
            setFile(page.contentObject.service_center_block__file);
        }
    }, [page.contentObject?.service_center_block__file]);

    async function addFile() {
        if (files.file) {
            await loadFile(files.file).then((file) => setFile(file));
        } else if (files.video && files.preview) {
            await loadFile(files.video, files.preview).then((video) =>
                setVideo(video),
            );
        } else return;
    }

    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок "Открытие сервисного центра"</h4>
            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <div className="mb-5">
                                <label className="form-label">Заголовок</label>
                                <input
                                    className="form-control"
                                    name={`content[service_center_block__title][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .service_center_block__title?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">Описание</label>
                                <textarea
                                    className="form-control"
                                    name={`content[service_center_block__description][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .service_center_block__description?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                        </div>
                    )}
                />
                <div className="mb-5">
                    <label className="form-label">
                        Ссылка на страницу "Подать заявку"
                    </label>
                    <input
                        className="form-control"
                        name={`content[service_center_block__url]`}
                        defaultValue={
                            page.contentObject.service_center_block__url
                        }
                    />
                </div>
                <div className="row">
                    <div className="mb-5">
                        <label className="form-label">Изображение</label>
                        <LoadingImageInput
                            width="400px"
                            height="400px"
                            src={image?.path}
                            onChange={setImage}
                        />
                        <input
                            type="hidden"
                            name={`content[service_center_block__banner_file][file_id]`}
                            value={image?.id}
                        />
                    </div>
                </div>
                <div className="mb-5">
                    <label className="form-label">Ссылка на видео</label>
                    <input
                        className="form-control"
                        name={`content[service_center_block__url]`}
                        defaultValue={
                            page.contentObject.service_center_block__video_url
                        }
                    />
                </div>
                <div className="row">
                    <h4 className="mb-5">Видео файл</h4>
                    <br />
                    <div>
                        {!video && (
                            <div className="mb-5">
                                <div className="mb-5">
                                    <div>
                                        <label className="form-label">
                                            Видео файл
                                        </label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            name="file"
                                            accept="video/*"
                                            onChange={(event) =>
                                                setFiles((prevState) => ({
                                                    ...prevState,
                                                    video: event.target.files?.item(
                                                        0,
                                                    ) as File,
                                                }))
                                            }
                                        />
                                    </div>
                                    <div className="mt-5">
                                        <label className="form-label">
                                            Превью для видео файла
                                        </label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            name="file"
                                            accept="image/*"
                                            onChange={(event) =>
                                                setFiles((prevState) => ({
                                                    ...prevState,
                                                    preview:
                                                        event.target.files?.item(
                                                            0,
                                                        ) as File,
                                                }))
                                            }
                                        />
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={addFile}
                                >
                                    Загрузить файл
                                </button>
                            </div>
                        )}
                        {video && (
                            <div>
                                <div className="card border mb-4 position-relative">
                                    <div className="card-body">
                                        <div className="d-flex flex-column justify-content-center">
                                            <div>
                                                <span className="fw-bolder">
                                                    Название файла:
                                                </span>
                                                {video?.name}
                                            </div>
                                            <div>
                                                <span className="fw-bolder">
                                                    Формат файла:
                                                </span>
                                                {video?.mime}
                                            </div>
                                            <div>
                                                <span className="fw-bolder">
                                                    Дата загрузки:
                                                </span>
                                                {video?.created_at}
                                            </div>
                                            <a
                                                href={
                                                    page.contentObject
                                                        .service_center_block__video_file
                                                        ? makePath(video.path)
                                                        : ``
                                                }
                                                target="_blank"
                                                className="fw-bolder"
                                            >
                                                Скачать видео
                                            </a>
                                            <input
                                                type="hidden"
                                                name={`content[service_center_block__video_file][file_id]`}
                                                defaultValue={video.id}
                                            />
                                        </div>
                                    </div>
                                    <h4 className="mb-5">Превью файл</h4>
                                    <div className="card-body">
                                        <div className="d-flex flex-column justify-content-center">
                                            <div>
                                                <span className="fw-bolder">
                                                    Название файла:
                                                </span>
                                                {video.preview_file?.name}
                                            </div>
                                            <div>
                                                <span className="fw-bolder">
                                                    Формат файла:
                                                </span>
                                                {video.preview_file?.mime}
                                            </div>
                                            <div>
                                                <span className="fw-bolder">
                                                    Дата загрузки:
                                                </span>
                                                {video.preview_file?.created_at}
                                            </div>
                                            <a
                                                href={
                                                    video.preview_file
                                                        ? makePath(
                                                              video.preview_file
                                                                  .path,
                                                          )
                                                        : ``
                                                }
                                                target="_blank"
                                                className="fw-bolder"
                                            >
                                                Скачать превью
                                            </a>
                                            <input
                                                type="hidden"
                                                name={`content[service_center_block__video_file][metadata][file_id]`}
                                                defaultValue={
                                                    video.preview_file?.id
                                                }
                                            />
                                        </div>
                                    </div>
                                    <i
                                        className="bi bi-x-circle position-absolute text-danger end-0 mt-5 me-5 cursor-pointer"
                                        onClick={() => {
                                            setVideo(null);
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        {!file && (
                            <div className="mb-5">
                                <div className="mb-5">
                                    <div>
                                        <label className="form-label">
                                            Файл
                                        </label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            name="file"
                                            accept=".pdf, .docx, .xlsx"
                                            onChange={(event) =>
                                                setFiles((prevState) => ({
                                                    ...prevState,
                                                    file: event.target.files?.item(
                                                        0,
                                                    ) as File,
                                                }))
                                            }
                                        />
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={addFile}
                                >
                                    Загрузить файл
                                </button>
                            </div>
                        )}
                        <h4 className="mb-5">Файл в блоке</h4>
                        {file && (
                            <div>
                                <div className="card border mb-4 position-relative">
                                    <div className="card-body">
                                        <div className="d-flex flex-column justify-content-center">
                                            <div>
                                                <span className="fw-bolder">
                                                    Название файла:
                                                </span>
                                                {file?.name}
                                            </div>
                                            <div>
                                                <span className="fw-bolder">
                                                    Формат файла:
                                                </span>
                                                {file?.mime}
                                            </div>
                                            <div>
                                                <span className="fw-bolder">
                                                    Дата загрузки:
                                                </span>
                                                {file?.created_at}
                                            </div>
                                            <a
                                                href={
                                                    page.contentObject
                                                        .service_center_block__file
                                                        ? makePath(file?.path)
                                                        : ``
                                                }
                                                target="_blank"
                                                className="fw-bolder"
                                            >
                                                Скачать файл
                                            </a>
                                            <input
                                                type="hidden"
                                                name={`content[service_center_block__file][file_id]`}
                                                defaultValue={file?.id}
                                            />
                                        </div>
                                    </div>
                                    <i
                                        className="bi bi-x-circle position-absolute text-danger end-0 mt-5 me-5 cursor-pointer"
                                        onClick={() => {
                                            setFile(null);
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
