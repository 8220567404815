import Modal from "react-bootstrap/Modal";
import { Alert } from "react-bootstrap";
import LoadingImageInput from "@admin/components/LoadingImageInput";
import { makePath } from "@admin/utils/makePath";
import React, { useState } from "react";
import { AdvantageModel } from "@admin/domain/uniquePages/models/MainPageModel";
import {
    initLanguageKeyObject,
    Language,
} from "@admin/domain/languages/Languages";
import { v4 as uuid } from "uuid";
import { FileModel } from "@admin/domain/files/model/FileModel";
import Input from "@admin/components/Input";
import MultiLanguages from "@admin/components/MultiLanguage";

type Props = {
    showModal: boolean;
    closeModal: () => void;
    onSubmit: (data: AdvantageModel) => void;
    editableBanner?: AdvantageModel;
};

type Banner = Partial<Pick<AdvantageModel, `file`>> &
    Omit<AdvantageModel, `file`>;

export default function MainPageAdvantageModal(props: Props) {
    const [banner, setBanner] = useState<Banner>(
        props.editableBanner || {
            id: uuid(),
            title: initLanguageKeyObject(),
            description: initLanguageKeyObject(),
        },
    );

    const [errors, setErrors] = useState<string[]>([]);
    const submit = () => {
        const newErrors: string[] = [];
        if (!banner.file) newErrors.push(`Изображение обязательно`);
        if (!banner.title.RU || !banner.title.EN || !banner.title.TR)
            newErrors.push(`Поле "Заголовок" обязательно для заполнения`);
        if (newErrors.length > 0) {
            setErrors(newErrors);
            return;
        }

        props.onSubmit({ ...banner, file: banner.file as FileModel });
        props.closeModal();
    };

    return (
        <Modal centered show={props.showModal} onHide={props.closeModal}>
            <Modal.Header>
                <Modal.Title>
                    {props.editableBanner ? `Обновление` : `Добавление`} банера
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {errors.length > 0 && (
                    <Alert variant="danger">
                        {errors.map((err, index) => (
                            <div key={index}>{err}</div>
                        ))}
                    </Alert>
                )}

                <div className="d-flex align-items-center justify-content-center">
                    <LoadingImageInput
                        width="660px"
                        height="475px"
                        onChange={(file) =>
                            setBanner((prev) => ({ ...prev, file: file }))
                        }
                        src={banner.file ? makePath(banner.file.path) : ``}
                    />
                </div>

                <MultiLanguages
                    languageElement={(language: Language) => (
                        <>
                            <Input
                                label="Заголовок"
                                onChange={(event) =>
                                    setBanner((prev) => {
                                        const title = prev.title;
                                        title[language] = event.target.value;
                                        return { ...prev, title: title };
                                    })
                                }
                                value={banner.title?.[language] || ``}
                            />

                            <Input
                                label="Текст под заголовком"
                                onChange={(event) =>
                                    setBanner((prev) => {
                                        const description = prev.description;
                                        description[language] =
                                            event.target.value;
                                        return {
                                            ...prev,
                                            description: description,
                                        };
                                    })
                                }
                                value={banner.description?.[language]}
                            />
                        </>
                    )}
                />
            </Modal.Body>

            <Modal.Footer>
                <button
                    className="btn btn-secondary"
                    onClick={props.closeModal}
                >
                    Закрыть
                </button>
                <button className="btn btn-primary" onClick={submit}>
                    {props.editableBanner ? `Обновить` : `Добавить`}
                </button>
            </Modal.Footer>
        </Modal>
    );
}
