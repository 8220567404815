import React from "react";
import Editor from "@admin/components/Editor";
import { updateFooterBlocks } from "@admin/domain/footer/api";
import FooterBlocksEditor from "@admin/pages/FooterEditor/FooterBlocksEditor";

const LIST_URL = `/admin/settings` as const;

const FooterEditor: React.FC = () => {
    const footerBlocks = window.CMS.footerBlocks || null;
    const execute = async (body: FormData) => {
        await updateFooterBlocks(body);
    };

    return (
        <Editor backUrl={LIST_URL} onSubmit={execute}>
            <FooterBlocksEditor blocks={footerBlocks} />
        </Editor>
    );
};

export default FooterEditor;
