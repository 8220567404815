import React from "react";
import MultiLanguages from "@admin/components/MultiLanguage";
import { ContentBlockModel } from "@admin/domain/content/models/ContentBlockModel";

type Props = {
    block: ContentBlockModel;
    index: number;
    key: number;
    onSubmit: (blockId: number) => void;
};
export default function TextBlock(props: Props) {
    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок с Текстом</h4>
            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <div className="mb-5">
                                <label className="form-label">Текст</label>
                                <textarea
                                    className="form-control"
                                    name={`blocks[${props.index}][value][text][${language}]`}
                                    defaultValue={
                                        props.block?.value?.text?.[language] ||
                                        ``
                                    }
                                />
                            </div>
                        </div>
                    )}
                />
            </div>
            <div>
                <a
                    className="card-link text-danger"
                    onClick={() => props.onSubmit(props.block.id)}
                >
                    Удалить блок
                </a>
            </div>
        </div>
    );
}
