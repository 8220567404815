import React, { useState } from "react";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import MultiLanguages from "@admin/components/MultiLanguage";
import LoadingImageInput from "@admin/components/LoadingImageInput";
import Select from "@admin/components/Select";

import { makePath } from "@admin/utils/makePath";
import {
    AboutPageModel,
    ProductsPrideOfOurCompanyBannersModel,
} from "@admin/domain/uniquePages/models/AboutPageModel";
import { type UniquePageProps } from "../index";

type Banner = Partial<ProductsPrideOfOurCompanyBannersModel>;

export default function AboutPageProductsPrideOfOurCompany({
    page,
}: UniquePageProps<AboutPageModel>) {
    const currentLanguage = useLanguageContext().currentLanguage;

    const categories = {};
    page.payload.categories?.forEach((category) => {
        const textsMap = new Map(
            category.texts.map((text) => [text.language, text]),
        );
        categories[category.id] = textsMap.get(currentLanguage)?.title;
    });

    const initBanners = page.contentObject
        .products_pride_of_our_company__banners || [
        { file: undefined, category: undefined },
        { file: undefined, category: undefined },
        { file: undefined, category: undefined },
        { file: undefined, category: undefined },
    ];
    const [banners, setBanners] = useState<Banner[]>(initBanners);

    return (
        <div className="mb-10">
            <h4 className="mb-5">Продукты - гордость нашей компании</h4>
            <MultiLanguages
                languageElement={(language) => (
                    <div>
                        <div className="mb-5">
                            <label className="form-label">Заголовок</label>
                            <input
                                className="form-control"
                                name={`content[products_pride_of_our_company__title][${language}]`}
                                defaultValue={
                                    page.contentObject
                                        .products_pride_of_our_company__title[
                                        language
                                    ] || ``
                                }
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label">
                                Описание (Текст под заголовком)
                            </label>
                            <textarea
                                className="form-control"
                                name={`content[products_pride_of_our_company__description][${language}]`}
                                defaultValue={
                                    page.contentObject
                                        .products_pride_of_our_company__description[
                                        language
                                    ] || ``
                                }
                            />
                        </div>
                    </div>
                )}
            />
            <div className="row row-cols-auto">
                {banners.map((banner, index) => (
                    <div key={index} className="col mb-5">
                        <LoadingImageInput
                            width="320px"
                            height="440px"
                            src={
                                banner.file
                                    ? makePath(banner.file.path as string)
                                    : ``
                            }
                            onChange={(file) =>
                                setBanners((prev) =>
                                    prev.map((prevVal, prevIndex) => {
                                        if (prevIndex === index) {
                                            prevVal.file = file;
                                        }
                                        return prevVal;
                                    }),
                                )
                            }
                        />
                        <input
                            type="hidden"
                            name={`content[products_pride_of_our_company__banners][${index}][file_id]`}
                            value={banner.file?.id}
                        />
                        <Select
                            label="Категория"
                            options={categories}
                            name={`content[products_pride_of_our_company__banners][${index}][category_id]`}
                            defaultValue={banner.category?.id}
                            required
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}
