import {
    useProductMediaFiles,
    useProductMediaFilesDispatch,
} from "./ProductMediaFilesContext";
import React from "react";
import ProductMediaFile from "./ProductMediaFile";
import Sortable from "@admin/components/Sortable";

export default function ProductMediaFileList() {
    const productMediaFiles = useProductMediaFiles();
    const dispatch = useProductMediaFilesDispatch();

    return (
        <Sortable
            list={productMediaFiles}
            setList={(newState) =>
                dispatch({ type: `drag`, newState: newState })
            }
        >
            {productMediaFiles.map((file) => (
                <ProductMediaFile key={file.id} file={file} />
            ))}
        </Sortable>
    );
}
