import { Language } from "../../languages/Languages";

export type TextPage = {
    id: number;
    parent_id: number | null;
    section: PageType;
    path: string;
    author_id: number;
    is_public: boolean;
    published_at: string | null;
    created_at: string;
    updated_at: string;
    parent: TextPage | null;
    author: textPageAuthor;
    authors: Array<textPageAuthor>;
    texts: Array<TextPageText>;
    textsMap: Map<Language, TextPageText>;
};

export enum PageType {
    Public = `public`,
    Private = `private`,
}

type textPageAuthor = {
    id: number;
    email: string;
    name: string;
};

type TextPageText = {
    id: number;
    language: Language;
    title: string;
    additional_text: string;
    text: string;
};
