import React from "react";

import Editor from "@admin/components/Editor";
import { ContentPromotionProvider } from "./ContentPromotionProvider";
import ContentPromotionFields from "./ContentPromotionFields";
import ContentPromotionBasketProducts from "./ContentPromotionBasketProducts";
import ContentPromotionRewardProducts from "./ContentPromotionRewardProducts";
import { saveContentPromotion } from "@admin/domain/complexPromotions/content/api";

const LIST_URL = `/admin/complex_promotions` as const;

const ContentPromotionEditor: React.FC = () => {
    const promotion = window.CMS.contentPromotion?.promotion || null;

    const execute = async (body: FormData) => {
        await saveContentPromotion(promotion, body);
    };

    const reset = () => {
        // Чтобы сбросить добавленные уровни, проще всего перезагрузить страницу.
        window.location.reload();
    };

    return (
        <Editor backUrl={LIST_URL} onSubmit={execute} onReset={reset}>
            <ContentPromotionProvider>
                <ContentPromotionFields promotion={promotion} />
                <ContentPromotionBasketProducts />
                <ContentPromotionRewardProducts />
            </ContentPromotionProvider>
        </Editor>
    );
};

export default ContentPromotionEditor;
