import React from "react";
import { ProductVideoModel } from "@admin/domain/products/models/edit/ProductVideoModel";
import { useProductVideosDispatch } from "./ProductVideosContext";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import { useSetModalVideo } from "@admin/components/ModalVideo";
import { getEmbedVideo } from "@admin/domain/youtubeVideo/api";

export default function ProductVideoYouTubeLink({
    video,
    index,
}: {
    video: ProductVideoModel;
    index?: number;
}) {
    const dispatch = useProductVideosDispatch();
    const currenLanguage = useLanguageContext().currentLanguage;
    const setModalVideo = useSetModalVideo();

    async function click() {
        const body = await getEmbedVideo(video.youtube_link as string);
        setModalVideo({
            show: true,
            body: <div dangerouslySetInnerHTML={{ __html: body.html }} />,
        });
    }

    return (
        <div className="card border mb-4 position-relative">
            <div className="card-body">
                <div>
                    <span className="fw-bolder">Название:</span>
                    {` `}
                    {video.textsMap?.get(currenLanguage)?.title}
                </div>
                <div>
                    <span
                        className="cursor-pointer link link-primary"
                        onClick={click}
                    >
                        Посмотреть
                    </span>
                </div>
            </div>
            <i
                className="bi bi-x-circle position-absolute text-danger end-0 mt-5 me-5 cursor-pointer"
                onClick={() => dispatch({ type: `delete`, index: index })}
            />
        </div>
    );
}
