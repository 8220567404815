import React, { useState } from "react";
import { makePath } from "@admin/utils/makePath";
import {
    MainBlockWithFewBannerBannersModel,
    MainPageModel,
} from "@admin/domain/uniquePages/models/MainPageModel";
import { v4 as uuid } from "uuid";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import { type UniquePageProps } from "../../index";
import AddBannerModal from "../Common/AddBannerModal";

export default function MainPageMainBlockWithFewBanners({
    page,
}: UniquePageProps<MainPageModel>) {
    const bannersInit = (
        page.contentObject.main_block_with_few_banner__banners || []
    ).map((value) => {
        return { ...value, id: uuid() };
    });

    const currentLanguage = useLanguageContext().currentLanguage;

    const [showModal, setShowModal] = useState(false);
    const [banners, setBanners] = useState(bannersInit);
    const [editableBanner, setEditableBanner] = useState<
        MainBlockWithFewBannerBannersModel | undefined
    >();

    const addHandle = (data: MainBlockWithFewBannerBannersModel) => {
        setBanners((prev) => [...prev, data]);
    };

    const editHandle = (data: MainBlockWithFewBannerBannersModel) => {
        setBanners((prev) =>
            prev.map((value) => {
                if (value.id === data.id) value = data;
                return value;
            }),
        );
    };

    return (
        <div className="mb-10">
            <h4 className="mb-5">
                Основной блок со слайдером с двойными баннерами
            </h4>

            <div>
                <div className="form-check form-switch mb-5">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name={`content[main_block_with_few_banner__is_public]`}
                        defaultValue="1"
                        defaultChecked={
                            page.contentObject
                                .main_block_with_few_banner__is_public
                        }
                    />
                    <label className="form-check-label">
                        Скрыть / Показать
                    </label>
                </div>
            </div>

            {banners.map(
                (banner: MainBlockWithFewBannerBannersModel, index: number) => (
                    <div
                        key={banner.id}
                        className="mb-5"
                        style={{ width: `fit-content`, height: `fit-content` }}
                    >
                        <div className="card border">
                            <img
                                style={{ width: `660px`, height: `375px` }}
                                className="card-img-top img_placeholder img-thumbnail"
                                src={makePath(banner.file.path)}
                                alt=""
                            />

                            <input
                                type="hidden"
                                name={`content[main_block_with_few_banner__banners][${index}][file_id]`}
                                value={banner.file.id}
                            />

                            <div className="card-body">
                                <div className="card-text mb-3">
                                    <div>
                                        <span className="fw-bolder">
                                            Заголовок:{` `}
                                        </span>
                                        {` `}
                                        {banner.title[currentLanguage]}
                                        {Object.entries(banner.title).map(
                                            ([lang, value]) => (
                                                <input
                                                    type="hidden"
                                                    key={lang}
                                                    name={`content[main_block_with_few_banner__banners][${index}][title][${lang}]`}
                                                    value={value}
                                                />
                                            ),
                                        )}
                                    </div>

                                    <div>
                                        <span className="fw-bolder">
                                            Описание:{` `}
                                        </span>
                                        {` `}
                                        {banner.description[currentLanguage]}
                                        {Object.entries(banner.description).map(
                                            ([lang, value]) => (
                                                <input
                                                    type="hidden"
                                                    key={lang}
                                                    name={`content[main_block_with_few_banner__banners][${index}][description][${lang}]`}
                                                    value={value}
                                                />
                                            ),
                                        )}
                                    </div>

                                    <div>
                                        <span className="fw-bolder">
                                            Текст кнопки:{` `}
                                        </span>
                                        {` `}
                                        {banner.button_text[currentLanguage]}
                                        {Object.entries(banner.button_text).map(
                                            ([lang, value]) => (
                                                <input
                                                    type="hidden"
                                                    key={lang}
                                                    name={`content[main_block_with_few_banner__banners][${index}][button_text][${lang}]`}
                                                    value={value}
                                                />
                                            ),
                                        )}
                                    </div>

                                    <div>
                                        <span className="fw-bolder">
                                            Ссылка:{` `}
                                        </span>
                                        {` `}
                                        {banner.url}
                                        <input
                                            type="hidden"
                                            name={`content[main_block_with_few_banner__banners][${index}][url]`}
                                            value={banner.url}
                                        />
                                    </div>

                                    <div>
                                        <span className="fw-bolder">Тэг: </span>
                                        {` `}
                                        {banner.tag[currentLanguage]}
                                        {Object.entries(banner.tag).map(
                                            ([lang, value]) => (
                                                <input
                                                    key={lang}
                                                    type="hidden"
                                                    name={`content[main_block_with_few_banner__banners][${index}][tag][${lang}]`}
                                                    value={value}
                                                />
                                            ),
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <a
                                        className="card-link text-success"
                                        onClick={() => {
                                            setEditableBanner(banner);
                                            setShowModal(true);
                                        }}
                                    >
                                        Редактировать
                                    </a>

                                    <a
                                        className="card-link text-danger"
                                        onClick={() => {
                                            setBanners((prev) =>
                                                prev.filter(
                                                    (value) =>
                                                        value.id !== banner.id,
                                                ),
                                            );
                                        }}
                                    >
                                        Удалить
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                ),
            )}

            <i
                className="bi bi-plus-square text-primary fs-2hx cursor-pointer ms-3 align-self-center"
                onClick={() => {
                    setEditableBanner(undefined);
                    setShowModal(true);
                }}
            />

            {showModal && (
                <AddBannerModal<MainBlockWithFewBannerBannersModel>
                    showModal={showModal}
                    closeModal={() => setShowModal(false)}
                    onSubmit={editableBanner ? editHandle : addHandle}
                    editableBanner={editableBanner}
                />
            )}
        </div>
    );
}
