import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

import { type Notification } from "./index";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import Modal from "react-bootstrap/Modal";
import MultiLanguages from "@admin/components/MultiLanguage";
import Input from "@admin/components/Input";
import TextArea from "@admin/components/TextArea";

type Props = {
    showModal: boolean;
    closeModal: () => void;
    onSubmit: (notification: Notification) => void;
    editableNotification: Notification | null;
};

export default function DeliveryMethodNotificationModal({
    closeModal,
    editableNotification,
    onSubmit,
    showModal,
}: Props) {
    const availableLanguages = useLanguageContext().availableLanguages;

    const [notification, setNotification] = useState<Notification>(
        editableNotification || {
            id: uuid(),
            texts: Object.fromEntries(
                availableLanguages.map((language) => [
                    language,
                    { title: ``, text: `` },
                ]),
            ),
        },
    );

    useEffect(() => {
        if (!showModal) {
            return;
        }

        if (editableNotification) {
            setNotification(editableNotification);
        } else {
            setNotification({
                id: uuid(),
                texts: Object.fromEntries(
                    availableLanguages.map((language) => [
                        language,
                        { title: ``, text: `` },
                    ]),
                ),
            });
        }
    }, [editableNotification, showModal]);

    const submit = () => {
        onSubmit(notification);
        closeModal();
    };

    return (
        <Modal centered show={showModal} onHide={closeModal} size="lg">
            <Modal.Header>
                <Modal.Title>
                    {editableNotification ? `Обновление` : `Добавление`}
                    {` `}
                    уведомления
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <MultiLanguages
                    languageElement={(language) => (
                        <>
                            <Input
                                label="Заголовок"
                                value={notification.texts[language]?.title}
                                onChange={(event) =>
                                    setNotification((prev) => ({
                                        ...prev,
                                        texts: {
                                            ...prev.texts,
                                            [language]: {
                                                ...prev.texts[language],
                                                title: event.target.value,
                                            },
                                        },
                                    }))
                                }
                            />

                            <TextArea
                                label="Текст"
                                value={notification.texts[language]?.text}
                                onChange={(event) =>
                                    setNotification((prev) => ({
                                        ...prev,
                                        texts: {
                                            ...prev.texts,
                                            [language]: {
                                                ...prev.texts[language],
                                                text: event.target.value,
                                            },
                                        },
                                    }))
                                }
                                required
                            />
                        </>
                    )}
                />
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={closeModal}>
                    Закрыть
                </button>
                <button className="btn btn-primary" onClick={() => submit()}>
                    {editableNotification ? `Обновить` : `Сохранить`}
                </button>
            </Modal.Footer>
        </Modal>
    );
}
