import React from "react";
import { ContentBlockModel } from "@admin/domain/content/models/ContentBlockModel";
import { SharingButtonsModel } from "@admin/domain/content/models/ContentBlockValueModel";

type Props = {
    block: ContentBlockModel;
    index: number;
    key: number;
    onSubmit: (blockId: number) => void;
};

export default function SharingBlock(props: Props) {
    const social_networks = (
        props.block.value as SharingButtonsModel | undefined
    )?.social_networks;
    const checkBoxes = {
        vkontakte: social_networks ? social_networks.vkontakte : true,
        odnoklassniki: social_networks ? social_networks.odnoklassniki : true,
        telegram: social_networks ? social_networks.telegram : true,
        whats_app: social_networks ? social_networks.whats_app : true,
        viber: social_networks ? social_networks.viber : true,
    };

    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок шеринга в соц. сетях</h4>
            {Object.entries(checkBoxes).map(
                ([socialNetwork, isChecked]: [string, boolean]) => (
                    <div className="mb-5" key={`checkbox-${socialNetwork}`}>
                        <div className="form-check form-check-custom form-check-solid">
                            <input
                                type="hidden"
                                name={`blocks[${props.index}][value][social_networks][${socialNetwork}]`}
                                defaultValue="0"
                            />

                            <input
                                id={`checkbox-${socialNetwork}`}
                                className="form-check-input"
                                type="checkbox"
                                name={`blocks[${props.index}][value][social_networks][${socialNetwork}]`}
                                defaultChecked={isChecked}
                                defaultValue="1"
                            />
                            <label
                                className="form-check-label"
                                htmlFor={`checkbox-${socialNetwork}`}
                            >
                                {socialNetwork}
                            </label>
                        </div>
                    </div>
                ),
            )}
            <div>
                <a
                    className="card-link text-danger"
                    onClick={() => props.onSubmit(props.block.id)}
                >
                    Удалить блок
                </a>
            </div>
        </div>
    );
}
