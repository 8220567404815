import { MainPageModel } from "@admin/domain/uniquePages/models/MainPageModel";
import MainPageMainBanners from "./MainPageMainBanners";
import MainPageMainBlockWithFewBanners from "./MainPageMainBlockWithFewBanners";
import MainPagePromotions from "./MainPagePromotions";
import MainPageAdvantage from "./MainPageAdvantage";
import MainPageAbout from "./MainPageAbout";
import MainPageBuyerChoiceModal from "./MainPageBuyerChoice";
import MainPageHowToEarn from "./MainPageHowToEarn";
import { type UniquePageProps } from "../index";
import MainPageECatalog from "./MainPageECatalog";
import MainPageNews from "./MainPageNews";

export default function MainPage({ page }: UniquePageProps<MainPageModel>) {
    return (
        <div>
            <MainPageMainBanners page={page} />
            <MainPageMainBlockWithFewBanners page={page} />
            <MainPagePromotions page={page} />
            <MainPageAdvantage page={page} />
            <MainPageAbout page={page} />
            <MainPageBuyerChoiceModal page={page} />
            <MainPageHowToEarn page={page} />
            <MainPageECatalog page={page} />
            <MainPageNews page={page} />
        </div>
    );
}
