import Modal from "react-bootstrap/Modal";
import MultiLanguages from "@admin/components/MultiLanguage";
import TextArea from "@admin/components/TextArea";
import React, { useEffect, useState } from "react";
import { GeneralCondition } from "./index";
import { useLanguageContext } from "@admin/components/LanguagesContext";

type Props = {
    showModal: boolean;
    closeModal: () => void;
    onSubmit: (data: GeneralCondition) => void;
    editableGeneralCondition: GeneralCondition | null;
};

export default function DeliveryMethodGeneralConditionModal({
    closeModal,
    editableGeneralCondition,
    onSubmit,
    showModal,
}: Props) {
    const availableLanguage = useLanguageContext().availableLanguages;

    const [generalCondition, setGeneralCondition] = useState<GeneralCondition>(
        editableGeneralCondition || {
            id: ``,
            texts: Object.fromEntries(
                availableLanguage.map((value) => [value, { text: `` }]),
            ),
        },
    );

    useEffect(() => {
        if (!showModal) {
            return;
        }

        if (editableGeneralCondition) {
            setGeneralCondition(editableGeneralCondition);
        } else {
            setGeneralCondition({
                id: ``,
                texts: Object.fromEntries(
                    availableLanguage.map((value) => [value, { text: `` }]),
                ),
            });
        }
    }, [editableGeneralCondition, showModal]);

    const submit = () => {
        onSubmit(generalCondition);
        closeModal();
    };

    return (
        <Modal centered show={showModal} onHide={closeModal} size="lg">
            <Modal.Header>
                <Modal.Title>
                    {editableGeneralCondition ? `Обновление` : `Добавление`}
                    {` `}
                    условия
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <MultiLanguages
                    languageElement={(language) => (
                        <TextArea
                            label="Текст"
                            value={generalCondition.texts[language]?.text}
                            onChange={(event) =>
                                setGeneralCondition((prev) => ({
                                    ...prev,
                                    texts: {
                                        ...prev.texts,
                                        [language]: {
                                            text: event.target.value,
                                        },
                                    },
                                }))
                            }
                            required
                        />
                    )}
                />
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={closeModal}>
                    Закрыть
                </button>
                <button className="btn btn-primary" onClick={submit}>
                    {editableGeneralCondition ? `Обновить` : `Сохранить`}
                </button>
            </Modal.Footer>
        </Modal>
    );
}
