import {
    initLanguageKeyObject,
    Language,
} from "@admin/domain/languages/Languages";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Alert } from "react-bootstrap";
import LoadingImageInput from "@admin/components/LoadingImageInput";
import { makePath } from "@admin/utils/makePath";
import Input from "@admin/components/Input";
import { Timeline } from "./index";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import MultiLanguages from "@admin/components/MultiLanguage";

type Props = {
    showModal: boolean;
    closeModal: () => void;
    onSubmit: (data: Timeline) => void;
    editingTimeline?: Timeline;
};

export default function AboutPageCompanyHistoryModal(props: Props) {
    const languageContext = useLanguageContext();
    const [timeline, setTimeline] = useState<Timeline>(
        props.editingTimeline || {
            text: initLanguageKeyObject(),
            date: initLanguageKeyObject(),
            year: ``,
        },
    );

    const [errors, setErrors] = useState<string[]>([]);
    const submit = () => {
        const newErrors = [];
        if (!timeline.file) newErrors.push(`Изображение обязательно`);
        if (!timeline.year)
            newErrors.push(`Поле "Год" обязательно для заполнения`);
        for (const language of languageContext.availableLanguages) {
            if (!timeline.date[language])
                newErrors.push(
                    `Поле "${language} Дата" обязательно для заполнения`,
                );
        }
        if (newErrors.length > 0) {
            setErrors(newErrors);
            return;
        }

        props.onSubmit(timeline);
        props.closeModal();
    };

    return (
        <Modal centered show={props.showModal} onHide={props.closeModal}>
            <Modal.Header>
                <Modal.Title>
                    {props.editingTimeline ? `Обновление` : `Добавление`}
                    {` `}
                    временной отметки
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {errors.length > 0 && (
                    <Alert variant="danger">
                        {errors.map((err, index) => (
                            <div key={index}>{err}</div>
                        ))}
                    </Alert>
                )}

                <div className="d-flex align-items-center justify-content-center">
                    <LoadingImageInput
                        width="205px"
                        height="205px"
                        onChange={(file) =>
                            setTimeline((prev) => ({ ...prev, file: file }))
                        }
                        src={timeline.file ? makePath(timeline.file.path) : ``}
                    />
                </div>

                <Input
                    label="Год"
                    onChange={(event) =>
                        setTimeline((prev) => ({
                            ...prev,
                            year: event.target.value,
                        }))
                    }
                    value={timeline.year}
                />

                <MultiLanguages
                    languageElement={(language: Language) => (
                        <>
                            <Input
                                label="Дата"
                                onChange={(event) =>
                                    setTimeline((prev) => {
                                        const date = prev.date;
                                        date[language] = event.target.value;
                                        return { ...prev, date };
                                    })
                                }
                                value={timeline.date[language]}
                            />

                            <Input
                                label="Сопроводительный текст для изображения"
                                onChange={(event) =>
                                    setTimeline((prev) => {
                                        const text = prev.text;
                                        text[language] = event.target.value;
                                        return { ...prev, text };
                                    })
                                }
                                value={timeline.text[language]}
                            />
                        </>
                    )}
                />
            </Modal.Body>

            <Modal.Footer>
                <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={props.closeModal}
                >
                    Закрыть
                </button>
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => submit()}
                >
                    {props.editingTimeline ? `Обновить` : `Добавить`}
                </button>
            </Modal.Footer>
        </Modal>
    );
}
