export const enum NotificationChannel {
    Push = `push`,
    Web = `web`,
    All = `all`,
}

export type NotificationModel = {
    id: number;
    title: string;
    text: string | null;
    channel: NotificationChannel;
    is_important: boolean;
    send_at: string;
    is_sent: boolean;
};
