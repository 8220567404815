const makeURL = (url: string, query: object | null | undefined): string => {
    if (!query) {
        return url;
    }

    const GETParameters = new URLSearchParams();

    Object.entries(query).forEach(([key, value]): void => {
        if (value) {
            GETParameters.append(key, value);
        }
    });

    if (!GETParameters.toString()) {
        return url;
    }

    return url + `?` + GETParameters.toString();
};

export default makeURL;
