import React, { useCallback } from "react";
import debounce from "lodash-es/debounce";
import AsyncSelect from "react-select/async";

import {
    type FoundProduct,
    type FoundProductsPayload,
    searchProducts,
} from "@admin/domain/products/api";
import { useIsInvalid } from "../FormErrorsContext";

type SelectOptions = {
    label: string;
    value: string;
};

type Props = {
    inputName: string;
    label?: string;
    productId?: string;
    productTitle?: string;
    onChange?: (value: string | undefined, label: string | undefined) => void;
};

export const ProductsSearch: React.FC<Props> = ({
    productId,
    productTitle,
    inputName,
    label,
    onChange = () => null,
}) => {
    const isInvalid = useIsInvalid(inputName);

    const debouncedSearch = useCallback(
        debounce(
            (
                query: string,
                callback: (options: ReadonlyArray<SelectOptions>) => void,
            ): void => {
                if (query.length < 2) {
                    return;
                }

                searchProducts(query).then(
                    ({ products }: FoundProductsPayload): void =>
                        callback(
                            products.map(
                                (product: FoundProduct): SelectOptions => ({
                                    value: product.id,
                                    label: product.title,
                                }),
                            ),
                        ),
                );
            },
            500,
        ),
        [],
    );

    return (
        <div className="mb-5">
            {label && <label className="form-label">{label}</label>}

            <AsyncSelect
                classNames={{
                    control: () =>
                        `form-control p-0` + (isInvalid ? ` is-invalid` : ``),
                }}
                placeholder="Поиск по названию или артикулу товара"
                loadOptions={debouncedSearch}
                defaultValue={
                    productId
                        ? {
                              label: productTitle,
                              value: productId,
                          }
                        : undefined
                }
                name={inputName}
                isClearable
                required
                onChange={(value): void => onChange(value?.value, value?.label)}
            />
        </div>
    );
};

export default ProductsSearch;
