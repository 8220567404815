import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import Modal from "react-bootstrap/Modal";

import { ContentBlockModel } from "@admin/domain/content/models/ContentBlockModel";
import { ContentBlockType } from "@admin/domain/content/enums/ContentBlockType";

type AddBlockModalProps = {
    showModal: boolean;
    closeModal: () => void;
    blocks: ContentBlockModel[] | undefined;
    onSubmit: (data: ContentBlockModel) => void;
};

const checkBoxes = {
    [ContentBlockType.Image]: `Блок с изображением`,
    [ContentBlockType.Text]: `Блок с текстом без заголовка`,
    [ContentBlockType.PhotoWithTextSlider]: `Блок со слайдером фото c текстом`,
    [ContentBlockType.Video]: `Блок с видео`,
    [ContentBlockType.TitleWithText]: `Блок с заголовком определенного уровня и текстом`,
    [ContentBlockType.TitleWithOrderedList]: `Блок с заголовком определенного уровня и нумерованным списком`,
    [ContentBlockType.TitleWithUnorderedList]: `Блок с заголовком определенного уровня и маркированным списком`,
    [ContentBlockType.BGColorText]: `Блок текстом на фоне серого цвета`,
    [ContentBlockType.ImageWithTextCheckerboard]: `Блок с изображениями и текстом в шахматном порядке`,
    [ContentBlockType.Leaders]: `Блок "Лидеры месяца"`,
    [ContentBlockType.ExpertOpinions]: `Блок с мнением эксперта`,
    [ContentBlockType.Directories]: `Блок с папками документов`,
    [ContentBlockType.Button]: `Блок с кнопкой для перехода`,
    [ContentBlockType.ImagesSlider]: `Блок со слайдером изображений`,
    [ContentBlockType.Files]: `Блок с файлами`,
    [ContentBlockType.Banner]: `Блок с баннером`,
    [ContentBlockType.Faq]: `Блок "Часто задаваемые вопросы"`,
    [ContentBlockType.ProductsSlider]: `Блок со слайдером товаров`,
    [ContentBlockType.OtherNews]: `Блок "Другие новости"`,
    [ContentBlockType.Sharing]: `Блок шеринга в соц. сетях`,
};

export default function AddBlockModal(props: AddBlockModalProps) {
    const [blockType, setBlockType] = useState<ContentBlockType>(
        ContentBlockType.Image,
    );
    const [, setPagesData] = useState<ContentBlockModel[]>(props.blocks || []);

    const submit = (): void => {
        const newBlockData = {
            id: uuid(),
            type: blockType,
        } as unknown as ContentBlockModel;
        setPagesData((prevPagesData: ContentBlockModel[]) => [
            ...prevPagesData,
            newBlockData,
        ]);
        props.onSubmit(newBlockData);
        props.closeModal();
    };

    return (
        <Modal centered show={props.showModal} onHide={props.closeModal}>
            <Modal.Header>
                <Modal.Title>Добавление блока</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    <div>
                        <h5 className="mb-5">Выберите тип блока</h5>
                        {Object.entries(checkBoxes).map(
                            ([checkboxBlockType, label]: [string, string]) => (
                                <div
                                    className="mb-10"
                                    key={`checkbox-${checkboxBlockType}`}
                                >
                                    <div className="form-check form-check-custom form-check-solid">
                                        <input
                                            id={`checkbox-${checkboxBlockType}`}
                                            className="form-check-input"
                                            type="radio"
                                            checked={
                                                blockType === checkboxBlockType
                                            }
                                            onChange={() =>
                                                setBlockType(
                                                    checkboxBlockType as ContentBlockType,
                                                )
                                            }
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor={`checkbox-${checkboxBlockType}`}
                                        >
                                            {label}
                                        </label>
                                    </div>
                                </div>
                            ),
                        )}
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-secondary"
                    onClick={props.closeModal}
                >
                    Закрыть
                </button>
                <button className="btn btn-primary" onClick={submit}>
                    Добавить
                </button>
            </Modal.Footer>
        </Modal>
    );
}
