import React, { useState } from "react";

import type { ArticleContentBlockModel } from "@admin/domain/articles/models/ArticleContentBlockModel";
import Block from "./Block";
import AddBlockModal from "./AddBlockModal";

export type ArticleBlocksProps = {
    blocks: ArticleContentBlockModel[];
};

export default function ArticleBlocks(props: ArticleBlocksProps) {
    const [blocks, setBlocks] = useState<ArticleContentBlockModel[]>(
        props.blocks,
    );

    const [showModal, setShowModal] = useState(false);

    const addBlock = (newBlock: ArticleContentBlockModel): void =>
        setBlocks([...blocks, newBlock]);

    const deleteBlock = (id: number) =>
        setBlocks(blocks.filter((item) => item.id !== id));

    return (
        <>
            {blocks.map((block: ArticleContentBlockModel, index: number) => (
                <Block
                    key={block.id || `new-block-${index}`}
                    block={block}
                    index={index}
                    onDelete={deleteBlock}
                />
            ))}

            <button
                className="btn btn-success"
                type="button"
                onClick={(): void => setShowModal(true)}
            >
                Добавить блок
            </button>

            {showModal && (
                <AddBlockModal
                    show
                    close={(): void => setShowModal(false)}
                    onSubmit={addBlock}
                />
            )}
        </>
    );
}
