import React from "react";

import { type Language } from "@admin/domain/languages/Languages";
import { type ProductCategoryTextModel } from "@admin/domain/productCategory/models/ProductCategoryModel";
import Input from "@admin/components/Input";
import TextArea from "@admin/components/TextArea";

type AdditionalProductTextProps = {
    language: Language;
    texts?: ProductCategoryTextModel[];
};

export default function AdditionalProductText(
    props: AdditionalProductTextProps,
) {
    const texts = new Map(props.texts?.map((text) => [text.language, text]));

    return (
        <>
            <Input
                label="Заголовок"
                name={`additional_products[texts][${props.language}][title]`}
                defaultValue={
                    texts.get(props.language)?.additional_product_title
                }
            />

            <TextArea
                label="Описание"
                name={`additional_products[texts][${props.language}][description]`}
                defaultValue={
                    texts.get(props.language)?.additional_product_description
                }
            />
        </>
    );
}
