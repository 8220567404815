import { post } from "../../api";
import { ContentPromotionRewardModel } from "./models/ContentPromotionRewardModel";
import { ContentPromotionBasketModel } from "./models/ContentPromotionBasketModel";
import { ContentPromotionModel } from "./models/ContentPromotionModel";

export type StoreContentPromotionData = {
    reward_products: ReadonlyArray<ContentPromotionRewardModel>;
    basket_products: ReadonlyArray<ContentPromotionBasketModel>;
    promotion: ContentPromotionModel;
};

const createContentPromotion = async (
    body: FormData,
): Promise<StoreContentPromotionData> => {
    return await post({
        url: `/admin/complex_promotions/content`,
        body,
    });
};

const updateContentPromotion = async (
    id: ContentPromotionModel[`id`],
    body: FormData,
): Promise<StoreContentPromotionData> => {
    return await post({
        url: `/admin/complex_promotions/content/${id}`,
        body,
    });
};

export const saveContentPromotion = async (
    promotion: ContentPromotionModel | null,
    body: FormData,
): Promise<StoreContentPromotionData> => {
    return promotion
        ? updateContentPromotion(promotion.id, body)
        : createContentPromotion(body);
};
