import React, { createRef, memo, useState } from "react";
import classNames, { type Argument } from "classnames";
import isNumber from "lodash-es/isNumber";

import { type FileModel } from "@admin/domain/files/model/FileModel";
import { loadFile } from "@admin/domain/products/api";
import { makePath } from "@admin/utils/makePath";
import { useIsInvalid } from "@admin/components/FormErrorsContext";

export type Props = {
    width?: string | number;
    height?: string | number;
    thumbnailHeight?: string | number;
    thumbnailWidth?: string | number;
    onChange?: (image: FileModel | null) => void;
    name?: string;
    label?: string;
    className?: {
        container?: Argument;
        image?: Argument;
    };
} & Omit<
    React.DetailedHTMLProps<
        React.ImgHTMLAttributes<HTMLImageElement>,
        HTMLImageElement
    >,
    `ref` | `width` | `height` | `onChange` | `defaultValue` | `className`
>;

const LoadingImageInput = ({
    width,
    height,
    thumbnailHeight = 300,
    thumbnailWidth = 300,
    className,
    onChange = () => null,
    label,
    name,
    ...props
}: Props) => {
    let imageClassName = className?.image;
    if (typeof imageClassName === `undefined`) {
        imageClassName = `mb-5 img-thumbnail `;
    }
    let containerClassName = className?.container;
    if (typeof containerClassName === `undefined`) {
        containerClassName = `p-10 my-5 rounded border`;
    }

    const refFileInput = createRef<HTMLInputElement>();
    const [src, setSrc] = useState<string>(makePath(props.src));

    async function loadImage(
        event: React.ChangeEvent<HTMLInputElement>,
    ): Promise<void> {
        event.preventDefault();

        const file = event.target.files?.item(0);

        if (!file) {
            return;
        }

        const fileModel = await loadFile(file, null);
        setSrc(makePath(fileModel.path));
        onChange(fileModel);
    }

    const remove = () => {
        setSrc(``);
        onChange(null);
    };

    const isInvalid = useIsInvalid(name);

    if (isNumber(width)) {
        width = `${width}px`;
    }

    if (isNumber(height)) {
        height = `${height}px`;
    }

    if (isNumber(thumbnailWidth)) {
        thumbnailWidth = `${thumbnailWidth}px`;
    }

    if (isNumber(thumbnailHeight)) {
        thumbnailHeight = `${thumbnailHeight}px`;
    }

    let Image = () => (
        <div
            {...props}
            style={{
                ...props.style,
                width: thumbnailWidth,
                height: thumbnailHeight,
                cursor: `pointer`,
            }}
            className={classNames(`img_placeholder`, imageClassName)}
            onClick={(): void => refFileInput.current?.click()}
        />
    );

    if (src) {
        Image = () => (
            <>
                <img
                    {...props}
                    src={makePath(src)}
                    style={{ ...props.style, width, height, cursor: `pointer` }}
                    className={classNames(imageClassName)}
                    onClick={() => refFileInput.current?.click()}
                    alt=""
                />

                <i
                    className="bi bi-x-circle cursor-pointer text-danger position-absolute end-0 me-4"
                    title="Удалить изображение"
                    onClick={remove}
                />
            </>
        );
    }

    return (
        <div
            className={classNames(
                `position-relative`,
                {
                    border: isInvalid,
                    "border-danger": isInvalid,
                },
                containerClassName,
            )}
        >
            {label && <h4 className="mb-5">{label}</h4>}

            <Image />

            <input
                className="d-none"
                type="file"
                name={name}
                accept="image/*"
                onChange={loadImage}
                ref={refFileInput}
            />
        </div>
    );
};

export default memo(
    LoadingImageInput,
    (oldProps: Props, newsProps: Props): boolean =>
        oldProps.src === newsProps.src,
);
