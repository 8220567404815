import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
    ProductVideoTextModel,
    VideoSource,
} from "@admin/domain/products/models/edit/ProductVideoModel";
import Spinner from "react-bootstrap/Spinner";
import { Language } from "@admin/domain/languages/Languages";
import { loadFile } from "@admin/domain/products/api";
import { useProductVideosDispatch } from "./ProductVideosContext";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import MultiLanguages from "@admin/components/MultiLanguage";
import Input from "@admin/components/Input";

export default function AddProductVideo() {
    const availableLanguages = useLanguageContext().availableLanguages;
    const dispatch = useProductVideosDispatch();

    const [showModal, setShowModal] = useState(false);
    const [loadStatus, setLoadStatus] = useState(false);
    const [videoSource, setVideoSource] = useState(VideoSource.File);

    const [videoTexts, setVideoTexts] = useState<
        Map<Language, ProductVideoTextModel>
    >(new Map());
    const [youTubeLink, setYouTubeLink] = useState(``);
    const [files, setFiles] = useState<{
        video: File | null;
        preview: File | null;
    }>({ video: null, preview: null });

    function onChangeVideoTexts(
        event: React.ChangeEvent<HTMLInputElement>,
        lang: Language,
    ) {
        setVideoTexts((prevState) =>
            prevState.set(lang, {
                title: event.target.value,
                language: lang,
            }),
        );
    }

    async function modalSubmit() {
        if (videoTexts.size !== availableLanguages.length) return;

        setLoadStatus(true);

        switch (videoSource) {
            case VideoSource.File: {
                if (!files.video || !files.preview) return;

                await loadFile(files.video, files.preview).then((videoFile) => {
                    dispatch({
                        type: `add`,
                        newProductVideo: {
                            source_type: VideoSource.File,
                            file: videoFile,
                            textsMap: videoTexts,
                            texts: [],
                        },
                    });
                });
                break;
            }

            case VideoSource.YouTube: {
                if (!youTubeLink) return;

                dispatch({
                    type: `add`,
                    newProductVideo: {
                        source_type: VideoSource.YouTube,
                        youtube_link: youTubeLink,
                        textsMap: videoTexts,
                        texts: [],
                    },
                });
                break;
            }
        }

        setFiles({ video: null, preview: null });
        setYouTubeLink(``);
        setVideoTexts(new Map());
        setLoadStatus(false);
        setShowModal(false);
    }

    return (
        <div className="mb-5">
            <button
                type="button"
                className="btn btn-primary"
                onClick={() => setShowModal(true)}
            >
                Добавить видео
            </button>
            <Modal show={showModal}>
                <Modal.Header>
                    <Modal.Title>Добавление видео</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-5">
                        <div className="form-check form-check-inline">
                            <label className="form-check-label">Файл</label>
                            <input
                                className="form-check-input"
                                type="radio"
                                name="videoSource"
                                defaultChecked={
                                    videoSource === VideoSource.File
                                }
                                onClick={() => setVideoSource(VideoSource.File)}
                            />
                        </div>
                        <div className="form-check form-check-inline">
                            <label className="form-check-label">
                                YouTube ссылка
                            </label>
                            <input
                                className="form-check-input"
                                type="radio"
                                name="videoSource"
                                defaultChecked={
                                    videoSource === VideoSource.YouTube
                                }
                                onClick={() =>
                                    setVideoSource(VideoSource.YouTube)
                                }
                            />
                        </div>
                    </div>

                    <div className="mb-5">
                        {videoSource === VideoSource.File ? (
                            <div className="mb-5">
                                <div>
                                    <label className="form-label">
                                        Видео файл
                                    </label>
                                    <input
                                        type="file"
                                        required
                                        className="form-control"
                                        name="file"
                                        accept="video/*"
                                        onChange={(event) =>
                                            setFiles((prevState) => ({
                                                ...prevState,
                                                video: event.target.files?.item(
                                                    0,
                                                ) as File,
                                            }))
                                        }
                                    />
                                </div>
                                <div className="mt-5">
                                    <label className="form-label">
                                        Превью для видео файла
                                    </label>
                                    <input
                                        type="file"
                                        required
                                        className="form-control"
                                        name="file"
                                        accept="image/*"
                                        onChange={(event) =>
                                            setFiles((prevState) => ({
                                                ...prevState,
                                                preview:
                                                    event.target.files?.item(
                                                        0,
                                                    ) as File,
                                            }))
                                        }
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="mb-5">
                                <label className="form-label">
                                    YouTube ссылка на видео
                                </label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    name="youtube"
                                    onChange={(event) =>
                                        setYouTubeLink(event.target.value)
                                    }
                                />
                            </div>
                        )}

                        <MultiLanguages
                            languageElement={(language) => (
                                <Input
                                    label="Название для сайта"
                                    onChange={(event) =>
                                        onChangeVideoTexts(event, language)
                                    }
                                />
                            )}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setShowModal(false)}
                    >
                        Закрыть
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => modalSubmit()}
                        disabled={loadStatus}
                    >
                        {!loadStatus ? (
                            <span>Загрузить</span>
                        ) : (
                            <span>
                                <Spinner
                                    className="me-3"
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                Загрузка...
                            </span>
                        )}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
