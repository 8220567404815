import React, { useState } from "react";
import MultiLanguages from "@admin/components/MultiLanguage";
import {
    BusinessPageModel,
    HowToStartBannersModel,
} from "@admin/domain/uniquePages/models/BusinessPageModel";
import { type UniquePageProps } from "../index";
import { v4 as uuid } from "uuid";
import LoadingImageInput from "@admin/components/LoadingImageInput";
import { makePath } from "@admin/utils/makePath";

type Banner = Partial<HowToStartBannersModel>;

export default function BusinessPageHowToStart({
    page,
}: UniquePageProps<BusinessPageModel>) {
    const initBanners = (page.contentObject.how_to_start__banners || []).map(
        (value) => {
            return { ...value, id: uuid() };
        },
    );

    const [banners, setBanners] = useState<Banner[]>(initBanners);

    return (
        <div className="mb-10">
            <h4 className="mb-5">Как начать</h4>
            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <div className="mb-5">
                                <label className="form-label">Заголовок</label>
                                <input
                                    className="form-control"
                                    name={`content[how_to_start__title][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .how_to_start__title?.[language] ||
                                        ``
                                    }
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">Описание</label>
                                <textarea
                                    className="form-control"
                                    name={`content[how_to_start__description][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .how_to_start__description?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="row row-cols-auto">
                {banners.map((banner, index) => (
                    <div key={banner.id} className="col mb-5">
                        <MultiLanguages
                            languageElement={(language) => (
                                <div>
                                    <div className="mb-5">
                                        <label className="form-label">
                                            Заголовок
                                        </label>
                                        <input
                                            className="form-control"
                                            name={`content[how_to_start__banners][${index}][title][${language}]`}
                                            defaultValue={
                                                banner.title?.[language] || ``
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        />
                        <div key={index} className="col mb-5">
                            <LoadingImageInput
                                width="300px"
                                src={
                                    banner.file
                                        ? makePath(banner.file.path as string)
                                        : ``
                                }
                                onChange={(file) =>
                                    setBanners((prev) =>
                                        prev.map((prevVal, prevIndex) => {
                                            if (prevIndex === index) {
                                                prevVal.file = file;
                                            }
                                            return prevVal;
                                        }),
                                    )
                                }
                            />
                            <input
                                type="hidden"
                                name={`content[how_to_start__banners][${index}][file_id]`}
                                value={banner.file?.id}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
