import { HowCreateOrderActionBlockModel } from "@admin/domain/uniquePages/models/HowCreateOrderModel";
import HowCreateOrderSubBlocks, { SubBlock } from "./HowCreateOrderSubBlocks";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import React, { useState } from "react";
import HowCreateOrderActionBlockModal from "./HowCreateOrderActionBlockModal";
import { v4 as uuid } from "uuid";
import { makePath } from "@admin/utils/makePath";
import { initLanguageKeyObject } from "@admin/domain/languages/Languages";
import Sortable from "@admin/components/Sortable";

export type ActionBlock = { id: string; sub_blocks: SubBlock[] } & Omit<
    HowCreateOrderActionBlockModel,
    `sub_blocks`
>;

export default function HowCreateOrderActionBlocks({
    initActionBLocks,
}: {
    initActionBLocks: HowCreateOrderActionBlockModel[];
}) {
    const languageContext = useLanguageContext();

    const [isEdit, setIsEdit] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [actionBlocks, setActionBlocks] = useState<ActionBlock[]>(
        initActionBLocks.map((actionBlock) => {
            const subBlocks: SubBlock[] = actionBlock.sub_blocks.map(
                (subBlock) => ({
                    ...subBlock,
                    id: uuid(),
                }),
            );

            return {
                ...actionBlock,
                id: uuid(),
                sub_blocks: subBlocks,
            };
        }),
    );

    const [actionBlockData, setActionBlockData] = useState<ActionBlock>({
        id: uuid(),
        description: initLanguageKeyObject(),
        image: null,
        sub_blocks: [],
    });

    const addHandle = (data: ActionBlock) =>
        setActionBlocks((prev) => [...prev, { ...data, id: uuid() }]);

    const updateHandle = (data: ActionBlock) =>
        setActionBlocks((prev) =>
            prev.map((value) => {
                if (value.id === data.id) {
                    value = { ...data };
                }
                return value;
            }),
        );

    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок с действиями по заказу</h4>
            <button
                className="btn btn-primary mb-5"
                type="button"
                onClick={() => {
                    setActionBlockData({
                        id: uuid(),
                        description: initLanguageKeyObject(),
                        image: null,
                        sub_blocks: [],
                    });
                    setIsEdit(false);
                    setShowModal(true);
                }}
            >
                Добавить действие
            </button>

            <Sortable
                className="row row-cols-auto"
                list={actionBlocks}
                setList={setActionBlocks}
            >
                {actionBlocks.map((actionBlock, index) => (
                    <div
                        key={actionBlock.id}
                        className="rounded border col-4 p-10 m-5"
                    >
                        <h4 className="mb-5">Действие {index + 1}</h4>
                        {actionBlock.sub_blocks.length === 0 && (
                            <div>
                                <img
                                    className="img-thumbnail mb-5"
                                    src={makePath(actionBlock.image?.path)}
                                    style={{ width: `650px`, height: `280px` }}
                                />
                                <input
                                    type="hidden"
                                    name={`content[action_blocks][${index}][file_id]`}
                                    value={actionBlock.image?.id}
                                />
                            </div>
                        )}
                        <div className="mb-5">
                            <span className="fw-bolder">Описание действия</span>
                            <p>
                                {
                                    actionBlock.description[
                                        languageContext.currentLanguage
                                    ]
                                }
                            </p>
                            {languageContext.availableLanguages.map((lang) => (
                                <input
                                    type="hidden"
                                    key={lang}
                                    name={`content[action_blocks][${index}][description][${lang}]`}
                                    value={actionBlock.description[lang]}
                                />
                            ))}
                        </div>

                        <div className="mb-5 d-flex justify-content-between">
                            <a
                                className="card-link cursor-pointer text-success"
                                onClick={(event) => {
                                    event.preventDefault();
                                    setActionBlockData(actionBlock);
                                    setIsEdit(true);
                                    setShowModal(true);
                                }}
                            >
                                Редактировать
                            </a>
                            <a
                                className="card-link cursor-pointer text-danger"
                                onClick={(event) => {
                                    event.preventDefault();
                                    setActionBlocks((prev) =>
                                        prev.filter(
                                            (value) =>
                                                value.id !== actionBlock.id,
                                        ),
                                    );
                                }}
                            >
                                Удалить
                            </a>
                        </div>

                        <HowCreateOrderSubBlocks
                            initSubBlocks={actionBlock.sub_blocks}
                            parent={actionBlock}
                            parentIndex={index}
                            onUpdate={(data) => {
                                setActionBlocks((prev) =>
                                    prev.map((value) => {
                                        if (value.id === data.id) {
                                            value = { ...data };
                                        }

                                        return value;
                                    }),
                                );
                            }}
                        />
                    </div>
                ))}
            </Sortable>

            <HowCreateOrderActionBlockModal
                showModal={showModal}
                closeModal={() => setShowModal(false)}
                onSubmit={isEdit ? updateHandle : addHandle}
                initActionBlockData={actionBlockData}
            />
        </div>
    );
}
