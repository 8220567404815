import React, { useState } from "react";
import MultiLanguages from "@admin/components/MultiLanguage";
import { v4 as uuid } from "uuid";
import { listModel } from "@admin/domain/customPages/models/CustomPageModel";
import { ContentBlockModel } from "@admin/domain/content/models/ContentBlockModel";

type List = Partial<listModel>;
type Props = {
    block: ContentBlockModel;
    index: number;
    key: number;
    onSubmit: (blockId: number) => void;
};
export default function TitleWithOrderedListBlock(props: Props) {
    const [value, setValue] = useState(props?.block?.value?.value || `h2`);

    const initList = (props?.block?.value?.list || []).map((value) => {
        return { ...value, id: uuid() };
    });

    const [list, setEl] = useState<List[]>(initList);

    return (
        <div className="mb-10">
            <h4 className="mb-5">
                Блок с заголовком определенного уровня и нумерованным списком
            </h4>
            <div>
                <input
                    type="hidden"
                    name={`blocks[${props.index}][value][id]`}
                    value={props?.block?.id}
                />
                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <div className="mb-5">
                                <label className="form-label">
                                    Текст заголовка
                                </label>
                                <textarea
                                    className="form-control"
                                    name={`blocks[${props.index}][value][title][${language}]`}
                                    defaultValue={
                                        props?.block?.value?.title?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                        </div>
                    )}
                />
                <div>
                    <h5 className="mb-5">Уровень заголовка</h5>
                    <div className="mb-5">
                        <label className="form-label">h2</label>
                        <input
                            type="radio"
                            name={`blocks[${props.index}][value][value]`}
                            value="h2"
                            checked={value === `h2`}
                            onChange={() => setValue(`h2`)}
                        />
                    </div>
                    <div className="mb-5">
                        <label className="form-label">h3</label>
                        <input
                            type="radio"
                            name={`blocks[${props.index}][value][value]`}
                            value="h3"
                            checked={value === `h3`}
                            onChange={() => setValue(`h3`)}
                        />
                    </div>
                    <div className="mb-5">
                        <label className="form-label">h4</label>
                        <input
                            type="radio"
                            name={`blocks[${props.index}][value][value]`}
                            value="h4"
                            checked={value === `h4`}
                            onChange={() => setValue(`h4`)}
                        />
                    </div>
                </div>
                <div className="row row-cols-auto">
                    {list.map((ol, index) => (
                        <div key={ol.id} className="col mb-5">
                            <div>
                                <MultiLanguages
                                    languageElement={(language) => (
                                        <div>
                                            <div className="mb-5">
                                                <label className="form-label">
                                                    Элемент списка
                                                </label>
                                                <input
                                                    className="form-control"
                                                    name={`blocks[${props.index}][value][list][${index}][title][${language}]`}
                                                    defaultValue={
                                                        ol.title?.[language] ||
                                                        ``
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                />
                            </div>
                            <div>
                                <a
                                    className="card-link text-danger"
                                    onClick={() => {
                                        setEl((prev) =>
                                            prev.filter(
                                                (value) => value.id !== ol.id,
                                            ),
                                        );
                                    }}
                                >
                                    Удалить
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="mb-5 d-flex">
                    <div className="me-5">
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={() =>
                                setEl((elState) => [
                                    ...elState,
                                    {
                                        id: uuid(),
                                        title: undefined,
                                    },
                                ])
                            }
                        >
                            Добавить элемент списка
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <a
                    className="card-link text-danger"
                    onClick={() => {
                        props.onSubmit(props.block.id);
                    }}
                >
                    Удалить блок
                </a>
            </div>
        </div>
    );
}
