import { ResponseErrorInterface } from "./ResponseErrorInterface";

class ResponseError extends Error implements ResponseErrorInterface {
    readonly errors: Array<string>;

    constructor(description: string) {
        super(description);
        this.errors = [description];

        Object.setPrototypeOf(this, ResponseError.prototype);
    }
}

export default ResponseError;
