import React, { useState } from "react";
import { AboutPageResearchBannerModel } from "@admin/domain/uniquePages/models/AboutPageModel";
import {
    initLanguageKeyObject,
    Language,
    LanguageKeyObject,
} from "@admin/domain/languages/Languages";
import Modal from "react-bootstrap/Modal";
import { Alert } from "react-bootstrap";
import LoadingImageInput from "@admin/components/LoadingImageInput";
import Input from "@admin/components/Input";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import MultiLanguages from "@admin/components/MultiLanguage";

type Props = {
    showModal: boolean;
    closeModal: () => void;
    onSubmit: (data: AboutPageResearchBannerModel) => void;
};

export default function AboutPageResearchModal(props: Props) {
    const languageContext = useLanguageContext();
    const [newResearches, setNewResearches] = useState<
        Partial<AboutPageResearchBannerModel>
    >({
        title: initLanguageKeyObject(),
    });

    const [errors, setErrors] = useState<string[]>([]);
    const submit = () => {
        const newErrors = [];
        if (!newResearches.file) newErrors.push(`Изображение обязательно`);
        for (const language of languageContext.availableLanguages) {
            // @ts-ignore
            if (!newResearches.title[language])
                newErrors.push(
                    `Поле "${language} Название исследования" обязательно для заполнения`,
                );
        }
        if (!newResearches.url)
            newErrors.push(`Поле "Ссылка" обязательно для заполнения`);
        if (newErrors.length > 0) {
            setErrors(newErrors);
            return;
        }

        props.onSubmit(newResearches as AboutPageResearchBannerModel);
        props.closeModal();
    };

    return (
        <Modal centered show={props.showModal} onHide={props.closeModal}>
            <Modal.Header>
                <Modal.Title>Добавление исследования</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {errors.length > 0 && (
                    <Alert variant="danger">
                        {errors.map((err, index) => (
                            <div key={index}>{err}</div>
                        ))}
                    </Alert>
                )}

                <div className="d-flex align-items-center justify-content-center">
                    <LoadingImageInput
                        width="320px"
                        height="400px"
                        onChange={(file) =>
                            setNewResearches((prev) => ({
                                ...prev,
                                file: file,
                            }))
                        }
                    />
                </div>

                <MultiLanguages
                    languageElement={(language: Language) => (
                        <Input
                            label="Название исследования"
                            onChange={(event) =>
                                setNewResearches((prev) => {
                                    const title =
                                        prev.title as LanguageKeyObject;
                                    title[language] = event.target.value;
                                    return { ...prev, title: title };
                                })
                            }
                        />
                    )}
                />

                <Input
                    label="Ссылка"
                    onChange={(event) =>
                        setNewResearches((prev) => ({
                            ...prev,
                            url: event.target.value,
                        }))
                    }
                />
            </Modal.Body>

            <Modal.Footer>
                <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={props.closeModal}
                >
                    Закрыть
                </button>
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => submit()}
                >
                    Добавить
                </button>
            </Modal.Footer>
        </Modal>
    );
}
