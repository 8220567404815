import { post } from "../../api";
import { AmountPromotionModel } from "./models/AmountPromotionModel";
import { AmountPromotionLevelModel } from "./models/AmountPromotionLevelModel";
import { AmountPromotionDiscountModel } from "./models/AmountPromotionDiscountModel";
import { AmountPromotionGiftModel } from "./models/AmountPromotionGiftModel";

export type AmountPromotionLevelData = {
    level: AmountPromotionLevelModel;
    discount_products: ReadonlyArray<AmountPromotionDiscountModel>;
    gift_products: ReadonlyArray<AmountPromotionGiftModel>;
};

export type AmountPromotionData = {
    promotion: AmountPromotionModel;
    levels: ReadonlyArray<AmountPromotionLevelData>;
};

const createAmountPromotion = async (
    body: FormData,
): Promise<AmountPromotionData> => {
    return await post({
        url: `/admin/complex_promotions/amount`,
        body,
    });
};

const updateAmountPromotion = async (
    id: AmountPromotionModel[`id`],
    body: FormData,
): Promise<AmountPromotionData> => {
    return await post({
        url: `/admin/complex_promotions/amount/${id}`,
        body,
    });
};

export const saveAmountPromotion = async (
    promotion: AmountPromotionModel | null,
    body: FormData,
): Promise<AmountPromotionData> => {
    return promotion
        ? updateAmountPromotion(promotion.id, body)
        : createAmountPromotion(body);
};
