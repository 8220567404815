import React from "react";

import Input from "@admin/components/Input";
import { type AmountPromotionGiftModel } from "@admin/domain/complexPromotions/amount/models/AmountPromotionGiftModel";
import ProductsSearch from "@admin/components/ProductsSearch";

type AmountPromotionGiftsProps = {
    giftProducts: ReadonlyArray<AmountPromotionGiftModel>;
    levelIndex: number;
    deleteGift: (levelIndex: number, giftIndex: number) => void;
};

const AmountPromotionGifts: React.FC<AmountPromotionGiftsProps> = ({
    giftProducts,
    levelIndex,
    deleteGift,
}) => {
    if (giftProducts.length === 0) {
        return null;
    }

    return (
        <>
            <h3>Подарки</h3>
            {giftProducts.map(
                (
                    gift: AmountPromotionGiftModel,
                    giftIndex: number,
                ): React.JSX.Element => (
                    <div
                        key={gift.id || `new-gift-${giftIndex}`}
                        className="rounded border p-10 my-5"
                    >
                        <ProductsSearch
                            productId={gift.product_id}
                            productTitle={gift.product_title}
                            inputName={`levels[${levelIndex}][gift_products][${giftIndex}][product_id]`}
                        />
                        <Input
                            label="Количество"
                            defaultValue={gift.quantity}
                            type="number"
                            name={`levels[${levelIndex}][gift_products][${giftIndex}][quantity]`}
                        />
                        {gift.id && (
                            <input
                                type="hidden"
                                value={gift.id}
                                name={`levels[${levelIndex}][gift_products][${giftIndex}][id]`}
                            />
                        )}

                        {giftProducts.length > 1 && (
                            <button
                                type="button"
                                className="btn btn-primary me-2 mt-5"
                                onClick={(): void =>
                                    deleteGift(levelIndex, giftIndex)
                                }
                            >
                                Удалить подарок
                            </button>
                        )}
                    </div>
                ),
            )}
        </>
    );
};

export default AmountPromotionGifts;
