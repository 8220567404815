import Input from "@admin/components/Input";
import QuillEditor from "@admin/components/QuillEditor";
import React from "react";
import { Language } from "@admin/domain/languages/Languages";
import { ProductTextModel } from "@admin/domain/products/models/ProductTextModel";

type TextsProps = {
    language: Language;
    texts: ProductTextModel[];
};

export default function Text(props: TextsProps) {
    const text = new Map(props.texts.map((value) => [value.language, value]));

    return (
        <>
            <Input
                label="Название"
                name={`product_attributes[text][${props.language}][title]`}
                defaultValue={text.get(props.language)?.title}
            />

            <QuillEditor
                label="Описание"
                name={`product_attributes[text][${props.language}][description]`}
                initHtml={text.get(props.language)?.description}
            />

            <QuillEditor
                label="Использование"
                name={`product_attributes[text][${props.language}][usage]`}
                initHtml={text.get(props.language)?.usage}
            />
        </>
    );
}
