import { type FC } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import LanguagesContext from "@admin/components/LanguagesContext";
import FormErrorsContext from "@admin/components/FormErrorsContext";
import routes from "@admin/routes";

const App: FC = () => {
    return (
        <LanguagesContext>
            <FormErrorsContext>
                <RouterProvider router={createBrowserRouter(routes)} />
            </FormErrorsContext>
        </LanguagesContext>
    );
};

export default App;
