import React, { useState } from "react";
import { makePath } from "@admin/utils/makePath";
import { FileModel } from "@admin/domain/files/model/FileModel";
import { loadFile } from "@admin/domain/products/api";

type Props = {
    video: FileModel | null;
    videoInputName: string;
    previewInputName: string;
};

export default function VideoLoader({
    video: initVideo,
    videoInputName,
    previewInputName,
}: Props) {
    const [video, setVideo] = useState<FileModel | null>(initVideo);
    const [, setVideoPreview] = useState<FileModel | null>(null);
    const [files, setFiles] = useState<{
        video: File | null;
        preview: File | null;
    }>({ video: null, preview: null });

    async function addFile(): Promise<void> {
        if (!files.video) {
            alert(`Загрузите видео`);

            return;
        }

        if (!files.preview) {
            alert(`Загрузите превью`);

            return;
        }

        const video = await loadFile(files.video, files.preview);

        setVideo(video);
    }

    return (
        <div className="row">
            <br />
            <div>
                {!video && (
                    <div className="card border mb-4">
                        <div className="card-body">
                            <div>
                                <label className="form-label">Видео файл</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    name="file"
                                    accept="video/*"
                                    onChange={(event) =>
                                        setFiles((prevState) => ({
                                            ...prevState,
                                            video: event.target.files?.item(
                                                0,
                                            ) as File,
                                        }))
                                    }
                                />
                            </div>
                            <div className="mt-5 mb-5">
                                <label className="form-label">
                                    Превью для видео файла
                                </label>
                                <input
                                    type="file"
                                    className="form-control"
                                    name="file"
                                    accept="image/*"
                                    onChange={(event) =>
                                        setFiles((prevState) => ({
                                            ...prevState,
                                            preview: event.target.files?.item(
                                                0,
                                            ) as File,
                                        }))
                                    }
                                />
                            </div>

                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={addFile}
                            >
                                Загрузить файл
                            </button>
                        </div>
                    </div>
                )}

                {video && (
                    <div className="card border mb-4">
                        <div className="card-body">
                            <div className="d-flex flex-column justify-content-center">
                                <h4 className="mb-5">Видео файл</h4>

                                <div>
                                    <span className="fw-bolder">
                                        Название файла:
                                    </span>
                                    {video?.name}
                                </div>
                                <div>
                                    <span className="fw-bolder">
                                        Формат файла:
                                    </span>
                                    {video?.mime}
                                </div>
                                <div>
                                    <span className="fw-bolder">
                                        Дата загрузки:
                                    </span>
                                    {video?.created_at}
                                </div>
                                <a
                                    href={makePath(initVideo?.path)}
                                    target="_blank"
                                    className="fw-bolder"
                                >
                                    Скачать видео
                                </a>
                                <input
                                    type="hidden"
                                    name={videoInputName}
                                    value={video.id}
                                />
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="d-flex flex-column justify-content-center">
                                <h4 className="mb-5">Превью файл</h4>

                                <div>
                                    <span className="fw-bolder">
                                        Название файла:
                                    </span>
                                    {video.preview_file?.name}
                                </div>
                                <div>
                                    <span className="fw-bolder">
                                        Формат файла:
                                    </span>
                                    {video.preview_file?.mime}
                                </div>
                                <div>
                                    <span className="fw-bolder">
                                        Дата загрузки:
                                    </span>
                                    {video.preview_file?.created_at}
                                </div>
                                <a
                                    href={
                                        video.preview_file
                                            ? makePath(video.preview_file.path)
                                            : ``
                                    }
                                    target="_blank"
                                    className="fw-bolder"
                                >
                                    Скачать превью
                                </a>
                                <input
                                    type="hidden"
                                    name={previewInputName}
                                    value={video.preview_file?.id}
                                />
                            </div>
                        </div>
                        <i
                            className="bi bi-x-circle position-absolute text-danger end-0 mt-5 me-5 cursor-pointer"
                            onClick={() => {
                                setVideo(null);
                                setVideoPreview(null);
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
