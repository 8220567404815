import React, { useState } from "react";
import MultiLanguages from "@admin/components/MultiLanguage";
import LoadingImageInput from "@admin/components/LoadingImageInput";
import { makePath } from "@admin/utils/makePath";
import {
    AboutBannerModel,
    MainPageModel,
} from "@admin/domain/uniquePages/models/MainPageModel";
import { type UniquePageProps } from "../index";

type Banner = Partial<AboutBannerModel>;

export default function MainPageAbout({
    page,
}: UniquePageProps<MainPageModel>) {
    const initBanners = page.contentObject.about__banner || [
        { file: undefined, title: undefined, description: undefined },
    ];
    const [banners, setBanners] = useState<Banner[]>(initBanners);

    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок о компании</h4>
            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <div className="mb-5">
                                <label className="form-label">Заголовок</label>
                                <input
                                    className="form-control"
                                    name={`content[about__title][${language}]`}
                                    defaultValue={
                                        page.contentObject.about__title?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">Описание</label>
                                <textarea
                                    className="form-control"
                                    name={`content[about__description][${language}]`}
                                    defaultValue={
                                        page.contentObject.about__description?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                        </div>
                    )}
                />
                {banners.map((banner, index) => (
                    <div key={index}>
                        <div>
                            {banners.map((banner, index) => (
                                <div key={index} className="col mb-5">
                                    <LoadingImageInput
                                        width="800px"
                                        src={
                                            banner.file
                                                ? makePath(
                                                      banner.file
                                                          .path as string,
                                                  )
                                                : ``
                                        }
                                        onChange={(file) =>
                                            setBanners((prev) =>
                                                prev.map(
                                                    (prevVal, prevIndex) => {
                                                        if (
                                                            prevIndex === index
                                                        ) {
                                                            prevVal.file = file;
                                                        }
                                                        return prevVal;
                                                    },
                                                ),
                                            )
                                        }
                                    />
                                    <input
                                        type="hidden"
                                        name={`content[about__banner][${index}][file_id]`}
                                        value={banner.file?.id}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
