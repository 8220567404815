import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import { ArticleContentBlockModel } from "@admin/domain/articles/models/ArticleContentBlockModel";
import { Icon as IconsModel } from "@admin/domain/articles/models/ArticleBlockValueModel";
import { ArticleBlockType } from "@admin/domain/articles/enums/ArticleBlockType";
import { makePath } from "@admin/utils/makePath";
import MultiLanguages from "@admin/components/MultiLanguage";
import LoadingImageInput from "@admin/components/LoadingImageInput";

type Slider = Partial<IconsModel>;

type Props = {
    block: ArticleContentBlockModel & {
        type: ArticleBlockType.Icons;
    };
    index: number;
};

export default function IconsBlock({ block: { value }, index }: Props) {
    const initSlider = (value?.icons || []).map((value) => ({
        ...value,
        id: uuid(),
    }));
    const [slider, setSlider] = useState<Slider[]>(initSlider);

    return (
        <div className="mb-10">
            <MultiLanguages
                languageElement={(language) => (
                    <div className="mb-5">
                        <label className="form-label">Заголовок</label>
                        <input
                            className="form-control"
                            name={`blocks[${index}][value][title][${language}]`}
                            defaultValue={value?.title?.[language] || ``}
                        />
                    </div>
                )}
            />

            <div className="row row-cols-auto">
                {slider.map((slide, index) => (
                    <div key={slide.id} className="col mb-5">
                        <MultiLanguages
                            languageElement={(language) => (
                                <div className="mb-5">
                                    <label className="form-label">
                                        Описание
                                    </label>
                                    <input
                                        className="form-control"
                                        name={`blocks[${index}][value][icons][${index}][title][${language}]`}
                                        defaultValue={
                                            slide?.title?.[language] || ``
                                        }
                                    />
                                </div>
                            )}
                        />

                        <div className="col mb-5">
                            <LoadingImageInput
                                width="300px"
                                height="300px"
                                src={
                                    slide.file
                                        ? makePath(slide.file.path as string)
                                        : ``
                                }
                                onChange={(file) =>
                                    setSlider((image) =>
                                        image.map((imageVal, imageIndex) => {
                                            if (imageIndex === index) {
                                                imageVal.file = file;
                                            }
                                            return imageVal;
                                        }),
                                    )
                                }
                            />

                            <input
                                type="hidden"
                                name={`blocks[${index}][value][icons][${index}][file_id]`}
                                value={slide.file?.id}
                            />
                        </div>

                        <div>
                            <a
                                className="card-link text-danger"
                                onClick={() => {
                                    setSlider((slideState) =>
                                        slideState.filter(
                                            (value) => value.id !== slide.id,
                                        ),
                                    );
                                }}
                            >
                                Удалить
                            </a>
                        </div>
                    </div>
                ))}
            </div>

            <div className="mb-5 d-flex">
                <div className="me-5">
                    <button
                        type="button"
                        className="btn btn-success"
                        onClick={() =>
                            setSlider((slideState) => [
                                ...slideState,
                                {
                                    id: uuid(),
                                    file: undefined,
                                    title: undefined,
                                },
                            ])
                        }
                    >
                        Добавить слайд
                    </button>
                </div>
            </div>
        </div>
    );
}
