import {
    useProductVideos,
    useProductVideosDispatch,
} from "./ProductVideosContext";
import ProductVideoFile from "./ProductVideoFile";
import ProductVideoYouTubeLink from "./ProductVideoYouTubeLink";
import { ProductVideoModel } from "@admin/domain/products/models/edit/ProductVideoModel";
import React from "react";
import Sortable from "@admin/components/Sortable";

export default function ProductVideoList() {
    const productVideos = useProductVideos();
    const dispatch = useProductVideosDispatch();

    function renderValues(productVideo: ProductVideoModel, index: number) {
        const texts: React.JSX.Element[] = [];
        productVideo.textsMap?.forEach((value) =>
            texts.push(
                <div key={value.language}>
                    <input
                        name={`product_videos[${index}][texts][${value.language}][title]`}
                        defaultValue={value.title}
                    />
                    <input
                        name={`product_videos[${index}][texts][${value.language}][language]`}
                        defaultValue={value.language}
                    />
                </div>,
            ),
        );

        return (
            <>
                <input
                    type="hidden"
                    name={`product_videos[${index}][source_type]`}
                    defaultValue={productVideo.source_type}
                />

                {typeof productVideo.id === `number` && (
                    <input
                        type="hidden"
                        name={`product_videos[${index}][id]`}
                        defaultValue={productVideo.id}
                    />
                )}

                {productVideo.file && (
                    <input
                        type="hidden"
                        name={`product_videos[${index}][file_id]`}
                        defaultValue={productVideo.file.id}
                    />
                )}

                {productVideo.youtube_link && (
                    <input
                        type="hidden"
                        name={`product_videos[${index}][youtube_link]`}
                        defaultValue={productVideo.youtube_link}
                    />
                )}

                {texts}
            </>
        );
    }

    return (
        <div className="mb-5">
            <Sortable
                list={productVideos}
                setList={(newState) =>
                    dispatch({ type: `drag`, newState: newState })
                }
            >
                {productVideos.map((value, index) => (
                    <div key={value.id}>
                        {value.file && (
                            <ProductVideoFile file={value.file} index={index} />
                        )}
                        {value.youtube_link && (
                            <ProductVideoYouTubeLink
                                video={value}
                                index={index}
                            />
                        )}
                        {renderValues(value, index)}
                    </div>
                ))}
            </Sortable>
        </div>
    );
}
