import { makePath } from "@admin/utils/makePath";
import React from "react";
import { FileModel } from "@admin/domain/files/model/FileModel";
import { useProductVideosDispatch } from "./ProductVideosContext";
import { useSetModalVideo } from "@admin/components/ModalVideo";
import { VideoJs } from "@admin/components/VideoJs";

export default function ProductVideoFile({
    file,
    index,
}: {
    file: FileModel;
    index?: number;
}) {
    const videosDispatch = useProductVideosDispatch();
    const setModalVideo = useSetModalVideo();

    return (
        <div className="card border mb-4 position-relative">
            <div className="card-body">
                <div className="d-flex">
                    <div className="d-flex flex-column justify-content-center">
                        <div>
                            <span className="fw-bolder">Название файла:</span>
                            {` `}
                            {file.name}
                        </div>
                        <div>
                            <span className="fw-bolder">Формат файла:</span>
                            {` `}
                            {file.mime}
                        </div>
                        <div>
                            <span className="fw-bolder">Размер файла:</span>
                            {` `}
                            {file.metadata.size}
                        </div>
                        <div
                            className="link link-primary cursor-pointer"
                            onClick={() =>
                                setModalVideo({
                                    show: true,
                                    body: (
                                        <VideoJs
                                            source={makePath(
                                                file.path as string,
                                            )}
                                        />
                                    ),
                                })
                            }
                        >
                            Посмотреть
                        </div>
                    </div>
                </div>

                {file.preview_file && (
                    <div className="mt-5 ms-5">
                        <div className="fw-bolder mb-2">Превью файл:</div>
                        <div className="d-flex">
                            <img
                                className="me-3 img-thumbnail product_list_img"
                                src={makePath(file.preview_file.path)}
                                alt="image"
                            />
                            <div className="d-flex flex-column justify-content-center">
                                <div>
                                    <span className="fw-bolder">
                                        Название файла:
                                    </span>
                                    {file.preview_file.name}
                                </div>
                                <div>
                                    <span className="fw-bolder">
                                        Формат файла:
                                    </span>
                                    {file.preview_file.mime}
                                </div>
                                <div>
                                    <span className="fw-bolder">
                                        Размер файла:
                                    </span>
                                    {file.preview_file.metadata.size}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <i
                className="bi bi-x-circle position-absolute text-danger end-0 mt-5 me-5 cursor-pointer"
                onClick={() => videosDispatch({ type: `delete`, index: index })}
            />
        </div>
    );
}
