import { FileModel } from "../../../files/model/FileModel";
import { HasLanguage, Language } from "../../../languages/Languages";

export type ProductVideoModel = {
    id: number | string;
    source_type: VideoSource;
    file?: FileModel;
    youtube_link?: string;
    texts: ProductVideoTextModel[];
    textsMap?: Map<Language, ProductVideoTextModel>;
};

export interface ProductVideoTextModel extends HasLanguage {
    language: Language;
    title: string;
}

export enum VideoSource {
    File = `file`,
    YouTube = `youtube`,
}
