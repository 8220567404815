import React, { useState } from "react";
import ImageBlock from "./Blocks/ImageBlock";
import VideoBlock from "./Blocks/VideoBlock";
import TextBlock from "./Blocks/TextBlock";
import PhotoSliderBlock from "./Blocks/PhotoSliderBlock";
import TitleBlock from "./Blocks/TitleBlock";
import TitleWithOrderedListBlock from "./Blocks/TitleWithOrderedListBlock";
import TitleWithUnOrderedListBlock from "./Blocks/TitleWithUnOrderedListBlock";
import BGColorTextBlock from "./Blocks/BGColorTextBlock";
import PhotoWithTextSliderBlock from "./Blocks/PhotoWithTextSliderBlock";
import AddBlockModal from "./AddBlockModal";
import LeadersBlock from "./Blocks/LeadersBlock";
import ExpertOpinionBlock from "./Blocks/ExpertOpinionBlock";
import DocumentDirectoriesBlock from "./Blocks/DocumentDirectoriesBlock";
import ButtonBlock from "./Blocks/ButtonBlock";
import FilesBlock from "./Blocks/FilesBlock";
import BannerBlock from "./Blocks/BannerBlock";
import FaqBlock from "./Blocks/FaqBlock";
import NewsSubscribesBlock from "./Blocks/NewsSubscribesBlock";
import ProductsSliderBlock from "./Blocks/ProductsSliderBlock";
import ImageWithTextCheckerboardBlock from "./Blocks/ImageWithTextCheckerboardBlock";
import { ContentBlockModel } from "@admin/domain/content/models/ContentBlockModel";
import { ContentBlockType } from "@admin/domain/content/enums/ContentBlockType";
import OtherNewsBlock from "./Blocks/OtherNewsBlock";
import SharingBlock from "./Blocks/SharingBlock";

export type ContentBlocksProps = {
    blocks: ContentBlockModel[] | undefined;
};

export default function ContentBlocks({ blocks }: ContentBlocksProps) {
    const [pagesData, setPagesData] = useState(blocks || []);
    const [showModal, setShowModal] = useState(false);
    const onChangeBlocks = (contentBlock: ContentBlockModel): void =>
        setPagesData((prevPagesData) => [...prevPagesData, contentBlock]);
    const deleteBlock = (blockId: number): void =>
        setPagesData((prevPagesData) =>
            [...prevPagesData].filter((item) => item.id !== blockId),
        );

    const renderBlocks = (
        block: ContentBlockModel,
        index: number,
    ): React.JSX.Element | null => {
        switch (block.type) {
            case ContentBlockType.Image: {
                return (
                    <ImageBlock
                        block={block}
                        index={index}
                        key={index}
                        onSubmit={deleteBlock}
                    />
                );
            }
            case ContentBlockType.Text: {
                return (
                    <TextBlock
                        block={block}
                        index={index}
                        key={index}
                        onSubmit={deleteBlock}
                    />
                );
            }
            case ContentBlockType.PhotoWithTextSlider: {
                return (
                    <PhotoWithTextSliderBlock
                        block={block}
                        index={index}
                        key={index}
                        onSubmit={deleteBlock}
                    />
                );
            }
            case ContentBlockType.Video: {
                return (
                    <VideoBlock
                        block={block}
                        index={index}
                        key={index}
                        onSubmit={deleteBlock}
                    />
                );
            }
            case ContentBlockType.TitleWithText: {
                return (
                    <TitleBlock
                        block={block}
                        index={index}
                        key={index}
                        onSubmit={deleteBlock}
                    />
                );
            }
            case ContentBlockType.TitleWithOrderedList: {
                return (
                    <TitleWithOrderedListBlock
                        block={block}
                        index={index}
                        key={index}
                        onSubmit={deleteBlock}
                    />
                );
            }
            case ContentBlockType.TitleWithUnorderedList: {
                return (
                    <TitleWithUnOrderedListBlock
                        block={block}
                        index={index}
                        key={index}
                        onSubmit={deleteBlock}
                    />
                );
            }
            case ContentBlockType.BGColorText: {
                return (
                    <BGColorTextBlock
                        block={block}
                        index={index}
                        key={index}
                        onSubmit={deleteBlock}
                    />
                );
            }
            case ContentBlockType.ImageWithTextCheckerboard: {
                return (
                    <ImageWithTextCheckerboardBlock
                        block={block}
                        index={index}
                        key={index}
                        onSubmit={deleteBlock}
                    />
                );
            }
            case ContentBlockType.Leaders: {
                return (
                    <LeadersBlock
                        block={block}
                        index={index}
                        key={index}
                        onSubmit={deleteBlock}
                    />
                );
            }
            case ContentBlockType.ExpertOpinions: {
                return (
                    <ExpertOpinionBlock
                        block={block}
                        index={index}
                        key={index}
                        onSubmit={deleteBlock}
                    />
                );
            }
            case ContentBlockType.Directories: {
                return (
                    <DocumentDirectoriesBlock
                        block={block}
                        index={index}
                        key={index}
                        onSubmit={deleteBlock}
                    />
                );
            }
            case ContentBlockType.Button: {
                return (
                    <ButtonBlock
                        block={block}
                        index={index}
                        key={index}
                        onSubmit={deleteBlock}
                    />
                );
            }
            case ContentBlockType.ImagesSlider: {
                return (
                    <PhotoSliderBlock
                        block={block}
                        index={index}
                        key={index}
                        onSubmit={deleteBlock}
                    />
                );
            }
            case ContentBlockType.Files: {
                return (
                    <FilesBlock
                        block={block}
                        index={index}
                        key={index}
                        onSubmit={deleteBlock}
                    />
                );
            }
            case ContentBlockType.Banner: {
                return (
                    <BannerBlock
                        block={block}
                        index={index}
                        key={index}
                        onSubmit={deleteBlock}
                    />
                );
            }
            case ContentBlockType.Faq: {
                return (
                    <FaqBlock
                        block={block}
                        index={index}
                        key={index}
                        onSubmit={deleteBlock}
                    />
                );
            }
            case ContentBlockType.NewsSubscribes: {
                return (
                    <NewsSubscribesBlock
                        block={block}
                        index={index}
                        key={index}
                        onSubmit={deleteBlock}
                    />
                );
            }
            case ContentBlockType.ProductsSlider: {
                return (
                    <ProductsSliderBlock
                        block={block}
                        index={index}
                        key={index}
                        onSubmit={deleteBlock}
                    />
                );
            }

            case ContentBlockType.OtherNews: {
                return (
                    <OtherNewsBlock
                        block={block}
                        index={index}
                        key={index}
                        onSubmit={deleteBlock}
                    />
                );
            }

            case ContentBlockType.Sharing: {
                return (
                    <SharingBlock
                        block={block}
                        index={index}
                        key={index}
                        onSubmit={deleteBlock}
                    />
                );
            }

            default:
                return null;
        }
    };

    return (
        <div>
            {pagesData.map((block: ContentBlockModel, index: number) => {
                return (
                    <div
                        className="rounded border p-10 my-5"
                        key={block.id || `new-block-${index}`}
                    >
                        {renderBlocks(block, index)}
                        <input
                            type="hidden"
                            name={`blocks[${index}][id]`}
                            value={block.id}
                        />
                        <input
                            type="hidden"
                            name={`blocks[${index}][type]`}
                            value={block.type}
                        />
                    </div>
                );
            })}
            <button
                className="btn btn-success"
                type="button"
                onClick={(): void => setShowModal(true)}
            >
                Добавить блок
            </button>
            {showModal && (
                <AddBlockModal
                    showModal={showModal}
                    closeModal={(): void => setShowModal(false)}
                    blocks={blocks}
                    onSubmit={onChangeBlocks}
                />
            )}
        </div>
    );
}
