import React from "react";

import { type ProductModel } from "@admin/domain/products/models/ProductModel";
import { useLanguageContext } from "@admin/components/LanguagesContext";

type Props = {
    product: ProductModel;
    remove: (id: ProductModel[`id`]) => void;
};

export default function AdditionalProduct({
    product: { id, texts },
    remove,
}: Props) {
    const cL = useLanguageContext().currentLanguage;

    return (
        <div className="card border mb-5 position-relative">
            <div className="card-body">
                <div>
                    <span className="fw-bolder">Название: </span>
                    {texts?.filter(({ language: l }) => l === cL)[0]?.title}
                </div>
                <div>
                    <span className="fw-bolder">UUID: </span>
                    {id}
                </div>
                <input
                    type="hidden"
                    name="additional_products[products_ids][]"
                    defaultValue={id}
                />
            </div>
            <i
                className="bi bi-x-circle position-absolute text-danger end-0 mt-5 me-5 cursor-pointer"
                onClick={() => remove(id)}
            />
        </div>
    );
}
