import React, { useState } from "react";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import Select from "@admin/components/Select";
import {
    MainPageModel,
    NewsBannersModel,
} from "@admin/domain/uniquePages/models/MainPageModel";
import { type UniquePageProps } from "../index";
import { v4 as uuid } from "uuid";

type Banner = Partial<NewsBannersModel>;

export default function MainPageNews({ page }: UniquePageProps<MainPageModel>) {
    const initBanners = (page.contentObject.news__banners || []).map(
        (value) => {
            return { ...value, id: value?.id ? value?.id : uuid() };
        },
    );
    const currentLanguage = useLanguageContext().currentLanguage;

    const newsCollection = {};
    page.payload.news?.forEach((news) => {
        const textsMap = new Map(
            news.texts.map((text) => [text.language, text]),
        );
        newsCollection[news.id] = textsMap.get(currentLanguage)?.title;
    });

    const [banners, setBanners] = useState<Banner[]>(initBanners);

    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок с новостями</h4>
            <div className="row row-cols-auto">
                {banners.map((banner, index) => (
                    <div key={banner?.id} className="col mb-5">
                        <Select
                            label="Новость"
                            options={newsCollection}
                            name={`content[news__banners][${index}][id]`}
                            defaultValue={banner?.id}
                            required
                        />
                        <div>
                            <a
                                className="card-link text-danger"
                                onClick={() => {
                                    setBanners((prev) =>
                                        prev.filter(
                                            (value) => value.id !== banner?.id,
                                        ),
                                    );
                                }}
                            >
                                Удалить
                            </a>
                        </div>
                    </div>
                ))}
            </div>
            <div className="mb-5 d-flex">
                <div className="me-5">
                    <button
                        type="button"
                        className="btn btn-success"
                        onClick={() =>
                            setBanners((prevState) => [
                                ...prevState,
                                {
                                    id: undefined,
                                },
                            ])
                        }
                    >
                        Добавить новость
                    </button>
                </div>
            </div>
        </div>
    );
}
