import React, { useState } from "react";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import MultiLanguages from "@admin/components/MultiLanguage";
import Select from "@admin/components/Select";
import {
    CatalogBannersModel,
    BusinessPageModel,
} from "@admin/domain/uniquePages/models/BusinessPageModel";
import { type UniquePageProps } from "../index";
import { v4 as uuid } from "uuid";
import LoadingImageInput from "@admin/components/LoadingImageInput";
import { makePath } from "@admin/utils/makePath";

type Banner = Partial<CatalogBannersModel>;

export default function BusinessPageCatalog({
    page,
}: UniquePageProps<BusinessPageModel>) {
    const initBanners = (page.contentObject.catalog__banners || []).map(
        (value) => {
            return { ...value, id: uuid() };
        },
    );
    const currentLanguage = useLanguageContext().currentLanguage;

    const categories = {};
    page.payload.categories?.forEach((category) => {
        const textsMap = new Map(
            category.texts.map((text) => [text.language, text]),
        );
        categories[category.id] = textsMap.get(currentLanguage)?.title;
    });

    const [banners, setBanners] = useState<Banner[]>(initBanners);

    return (
        <div className="mb-10">
            <h4 className="mb-5">
                Блок с категориями/подкатегориями продуктов
            </h4>
            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <div className="mb-5">
                                <label className="form-label">Заголовок</label>
                                <input
                                    className="form-control"
                                    name={`content[catalog__title][${language}]`}
                                    defaultValue={
                                        page.contentObject.catalog__title?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">Описание</label>
                                <textarea
                                    className="form-control"
                                    name={`content[catalog__description][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .catalog__description?.[language] ||
                                        ``
                                    }
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">
                                    Текст кнопки
                                </label>
                                <textarea
                                    className="form-control"
                                    name={`content[catalog__button_text][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .catalog__button_text?.[language] ||
                                        ``
                                    }
                                />
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="row row-cols-auto">
                {banners.map((banner, index) => (
                    <div key={banner.id} className="col mb-5">
                        <Select
                            label="Кактегория"
                            options={categories}
                            name={`content[catalog__banners][${index}][category_id]`}
                            defaultValue={banner.category?.id}
                            required
                        />
                        <MultiLanguages
                            languageElement={(language) => (
                                <div>
                                    <div className="mb-5">
                                        <label className="form-label">
                                            Заголовок
                                        </label>
                                        <input
                                            className="form-control"
                                            name={`content[catalog__banners][${index}][title][${language}]`}
                                            defaultValue={
                                                banner.title?.[language] || ``
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        />
                        <div key={index} className="col mb-5">
                            <LoadingImageInput
                                width="300px"
                                src={
                                    banner.file
                                        ? makePath(banner.file.path as string)
                                        : ``
                                }
                                onChange={(file) =>
                                    setBanners((prev) =>
                                        prev.map((prevVal, prevIndex) => {
                                            if (prevIndex === index) {
                                                prevVal.file = file;
                                            }
                                            return prevVal;
                                        }),
                                    )
                                }
                            />
                            <input
                                type="hidden"
                                name={`content[catalog__banners][${index}][file_id]`}
                                value={banner.file?.id}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
