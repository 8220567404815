import {
    type AmountPromotionLevelData,
    saveAmountPromotion,
} from "@admin/domain/complexPromotions/amount/api";
import { AmountType } from "@admin/domain/complexPromotions/amount/models/AmountPromotionModel";
import Editor from "@admin/components/Editor";
import MultiLanguages from "@admin/components/MultiLanguage";
import { useIsInvalid } from "@admin/components/FormErrorsContext";
import Input from "@admin/components/Input";
import TextArea from "@admin/components/TextArea";
import DateTimeInput from "@admin/components/DateTimeInput";
import Select from "@admin/components/Select";
import CheckBox from "@admin/components/CheckBox";
import useLevels from "./useLevels";
import AmountPromotionGifts from "./AmountPromotionGifts";
import AmountPromotionDiscounts from "./AmountPromotionDiscounts";

const LIST_URL = `/admin/complex_promotions` as const;

const AmountPromotionEditor = () => {
    const promotion = window.CMS.amountPromotion?.promotion || null;
    const {
        levels,
        addNewLevel,
        addNewGiftToLevel,
        addNewDiscountToLevel,
        deleteLevel,
        deleteGift,
        deleteDiscount,
    } = useLevels(window.CMS.amountPromotion?.levels || []);

    const execute = async (body: FormData) => {
        await saveAmountPromotion(promotion, body);
    };

    const reset = () => {
        // Чтобы сбросить добавленные уровни, проще всего перезагрузить страницу.
        window.location.reload();
    };

    return (
        <Editor backUrl={LIST_URL} onSubmit={execute} onReset={reset}>
            <MultiLanguages
                hasErrors={(language) =>
                    useIsInvalid(`promotion[texts][${language}]`)
                }
                languageElement={(language) => (
                    <>
                        <Input
                            label="Название"
                            name={`promotion[texts][${language}][title]`}
                            defaultValue={promotion?.texts[language]?.title}
                        />
                        <TextArea
                            label="Описание"
                            name={`promotion[texts][${language}][description]`}
                            defaultValue={
                                promotion?.texts[language]?.description
                            }
                        />
                    </>
                )}
            />

            <DateTimeInput
                label="Начало акции"
                name="promotion[active_from]"
                defaultValue={promotion?.active_from || ``}
            />
            <DateTimeInput
                label="Конец акции"
                name="promotion[active_to]"
                defaultValue={promotion?.active_to || ``}
            />
            <Select
                label="Тип суммы"
                name="promotion[amount_type]"
                defaultValue={promotion?.amount_type || AmountType.Points}
                options={{
                    [AmountType.Price]: `Цена товара (в р.)`,
                    [AmountType.Points]: `Баллы`,
                }}
                prependEmptyOption={false}
                required
            />
            <CheckBox
                label="Применять только для сайта"
                name="promotion[only_for_site]"
                defaultChecked={promotion ? promotion.only_for_site : true}
            />

            <h2>Уровни акции</h2>

            {levels.map(
                (
                    {
                        level,
                        discount_products,
                        gift_products,
                    }: AmountPromotionLevelData,
                    levelIndex: number,
                ) => (
                    <div
                        className="rounded border p-10 my-5"
                        key={level.id || `new-level-${levelIndex}`}
                    >
                        <input
                            type="hidden"
                            value={level.id}
                            name={`levels[${levelIndex}][level][id]`}
                        />

                        <Input
                            label="Сумма"
                            defaultValue={level.amount / 100}
                            name={`levels[${levelIndex}][level][amount]`}
                        />

                        <AmountPromotionGifts
                            giftProducts={gift_products}
                            levelIndex={levelIndex}
                            deleteGift={deleteGift}
                        />

                        <AmountPromotionDiscounts
                            discountProducts={discount_products}
                            levelIndex={levelIndex}
                            deleteDiscount={deleteDiscount}
                        />

                        {discount_products.length === 0 && (
                            <button
                                type="button"
                                className="btn btn-primary me-2"
                                onClick={(): void =>
                                    addNewGiftToLevel(levelIndex)
                                }
                            >
                                Добавить подарок
                            </button>
                        )}

                        {gift_products.length === 0 && (
                            <button
                                type="button"
                                className="btn btn-primary me-2"
                                onClick={(): void =>
                                    addNewDiscountToLevel(levelIndex)
                                }
                            >
                                Добавить скидку на товар
                            </button>
                        )}

                        {levels.length > 1 && (
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={(): void => {
                                    if (confirm(`Удалить уровень?`)) {
                                        deleteLevel(levelIndex);
                                    }
                                }}
                            >
                                Удалить уровень
                            </button>
                        )}
                    </div>
                ),
            )}

            <div
                className="rounded border p-10 my-5 cursor-pointer"
                onClick={addNewLevel}
            >
                <i className="fas fa-plus me-2" />
                Добавить уровень
            </div>
        </Editor>
    );
};

export default AmountPromotionEditor;
