import React, { useState } from "react";

import { type FileModel } from "@admin/domain/files/model/FileModel";
import LoadingImageInput, {
    type Props as BaseProps,
} from "@admin/components/LoadingImageInput";

type Props = {
    label: string;
    name: string;
    defaultValue: FileModel | null | undefined;
};

export default function ImageInput({
    defaultValue,
    label,
    name,
    ...props
}: Props & BaseProps) {
    const [image, setImage] = useState<FileModel | null>(defaultValue || null);

    return (
        <>
            <LoadingImageInput
                {...props}
                label={label}
                src={image?.path}
                onChange={setImage}
            />
            <input type="hidden" name={name} value={image?.id || ``} />
        </>
    );
}
