import { useLanguageContext } from "@admin/components/LanguagesContext";
import Input from "@admin/components/Input";
import { type UniquePageProps } from "../index";
import { AboutPageModel } from "@admin/domain/uniquePages/models/AboutPageModel";
import { makePath } from "@admin/utils/makePath";

export default function AboutPageMain({
    page,
}: UniquePageProps<AboutPageModel>) {
    const currentLanguage = useLanguageContext().currentLanguage;

    return (
        <div className="mb-10">
            <h4>Основной блок c баннером</h4>
            <Input
                label="Заголовок страницы"
                disabled
                defaultValue={
                    page.contentObject.main_block_with_banner__title[
                        currentLanguage
                    ] || ``
                }
            />
            <Input
                label="Описание страницы"
                disabled
                defaultValue={
                    page.contentObject.main_block_with_banner__description[
                        currentLanguage
                    ] || ``
                }
            />
            <div className="mb-5">
                <label className="form-label">Фоновое изображение</label>
                <img
                    className="img-fluid d-block img-thumbnail"
                    src={makePath(
                        page.contentObject.main_block_with_banner__banner.path,
                    )}
                    alt="Фоновое изображение (баннер) с заводом Тенториум "
                />
            </div>
        </div>
    );
}
