import { Language } from "@admin/domain/languages/Languages";
import { ProductModel } from "@admin/domain/products/models/ProductModel";
import { ContentBlockModel } from "@admin/domain/content/models/ContentBlockModel";
import { AdminModel } from "@admin/domain/admins/models/AdminModel";

export type CustomPageModel = {
    id: number;
    parent_id: number | null;
    section: PageType;
    path: string;
    author_id: number;
    is_public: boolean;
    published_at: string | null;
    created_at: string;
    updated_at: string;
    parent: CustomPageModel | null;
    author: AdminModel;
    texts: Array<CustomPageText>;
    textsMap: Map<Language, CustomPageText>;
    blocks: Array<ContentBlockModel>;
    payload: CustomPagePayload;
};

export enum PageType {
    Public = `public`,
    Private = `private`,
}

type CustomPageText = {
    id: number;
    language: Language;
    title: string;
    text: string;
};

type CustomPagePayload = {
    products?: Array<ProductModel>;
};
