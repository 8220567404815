import React, { useState } from "react";
import MultiLanguages from "@admin/components/MultiLanguage";
import { ProductsSliderModel } from "@admin/domain/articles/models/ArticleBlockValueModel";
import ProductsSearch from "@admin/components/ProductsSearch";
import { ArticleContentBlockModel } from "@admin/domain/articles/models/ArticleContentBlockModel";
import { ArticleBlockType } from "@admin/domain/articles/enums/ArticleBlockType";

type Props = {
    block: ArticleContentBlockModel & {
        type: ArticleBlockType.ProductsSlider;
    };
    index: number;
};

export default function ProductsSliderBlock({
    block: { value },
    index,
}: Props) {
    const [products, setProducts] = useState<ProductsSliderModel[`products`]>(
        value?.products || [],
    );

    return (
        <>
            <MultiLanguages
                languageElement={(language) => (
                    <div className="mb-5">
                        <label className="form-label">Заголовок</label>
                        <input
                            className="form-control"
                            name={`blocks[${index}][value][title][${language}]`}
                            defaultValue={value?.title?.[language] || ``}
                        />
                    </div>
                )}
            />

            <div className="rounded border p-10 my-5">
                <h3>Товары</h3>

                {products.map((product, index: number) => (
                    <div
                        key={product.id || `product-${index}`}
                        className="col mb-5"
                    >
                        <ProductsSearch
                            inputName={`blocks[${index}][value][product_ids][${index}]`}
                            productId={product.id}
                            productTitle={product.title}
                            onChange={(value, label): void =>
                                setProducts(
                                    products.map(
                                        (product, productIndex: number) => {
                                            if (productIndex === index) {
                                                return {
                                                    id: value || ``,
                                                    title: label || ``,
                                                };
                                            }

                                            return product;
                                        },
                                    ),
                                )
                            }
                        />

                        <div>
                            <a
                                className="card-link text-danger"
                                onClick={(): void =>
                                    setProducts(
                                        products.filter(
                                            (_, index: number): boolean =>
                                                index !== index,
                                        ),
                                    )
                                }
                            >
                                Удалить
                            </a>
                        </div>
                    </div>
                ))}

                <div className="mb-5 d-flex">
                    <div className="me-5">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(): void =>
                                setProducts([
                                    ...products,
                                    {
                                        id: ``,
                                        title: ``,
                                    },
                                ])
                            }
                        >
                            Добавить товар
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
