import uniq from "lodash-es/uniq";
import flatten from "lodash-es/flatten";

import { toArray } from "@admin/utils/converters";
import { type ResponseErrorInterface } from "./ResponseErrorInterface";

class ValidationError extends Error implements ResponseErrorInterface {
    readonly errors: Array<string>;
    readonly fields: Set<string>;

    constructor({
        description,
        payload,
    }: {
        description: string;
        payload: { messages: { [_key: string]: Array<string> } };
    }) {
        super(description);

        this.errors = uniq(flatten(toArray<string>(payload.messages)));

        this.fields = new Set<string>(
            flatten(
                Object.keys(payload.messages || {}).map((name) => {
                    const chunks = name.split(`.`);
                    const parts = [];

                    for (let i = 0; i < chunks.length; i += 1) {
                        const part = [];

                        for (let j = 0; j <= i; j += 1) {
                            part.push(chunks[j]);
                        }

                        if (part.length > 1) {
                            parts.push(part.join(`][`).replace(`]`, ``) + `]`);
                        } else {
                            parts.push(part[0]);
                        }
                    }

                    return parts;
                }),
            ),
        );

        Object.setPrototypeOf(this, ValidationError.prototype);
    }
}

export default ValidationError;
