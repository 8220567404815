import React, { useState } from "react";
import MultiLanguages from "@admin/components/MultiLanguage";
import {
    BusinessPageModel,
    LeadersBannersModel,
} from "@admin/domain/uniquePages/models/BusinessPageModel";
import { type UniquePageProps } from "../index";
import { v4 as uuid } from "uuid";
import LoadingImageInput from "@admin/components/LoadingImageInput";
import { makePath } from "@admin/utils/makePath";
import { loadFile } from "@admin/domain/products/api";

type Banner = Partial<LeadersBannersModel>;

export default function BusinessPageLeaders({
    page,
}: UniquePageProps<BusinessPageModel>) {
    const initBanners = (page.contentObject.leaders__banners || []).map(
        (value) => {
            return { ...value, id: uuid() };
        },
    );

    const [banners, setBanners] = useState<Banner[]>(initBanners);

    async function addFile(video: File | null | undefined, index: number) {
        if (!video) return;

        await loadFile(video).then((video) =>
            setBanners((prev) =>
                prev.map((prevVal, prevIndex) => {
                    if (prevIndex === index) {
                        prevVal.video_file = video;
                    }
                    return prevVal;
                }),
            ),
        );
    }

    return (
        <div className="mb-10">
            <h4 className="mb-5">Лидеры месяца</h4>
            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <div className="mb-5">
                                <label className="form-label">Заголовок</label>
                                <input
                                    className="form-control"
                                    name={`content[leaders__title][${language}]`}
                                    defaultValue={
                                        page.contentObject.leaders__title?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="row row-cols-auto">
                {banners.map((banner, index) => (
                    <div key={banner.id} className="col mb-5">
                        <div>
                            <MultiLanguages
                                languageElement={(language) => (
                                    <div>
                                        <div className="mb-5">
                                            <label className="form-label">
                                                Информация
                                            </label>
                                            <input
                                                className="form-control"
                                                name={`content[leaders__banners][${index}][info][${language}]`}
                                                defaultValue={
                                                    banner.info?.[language] ||
                                                    ``
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                            />
                            <div className="mb-5">
                                <label className="form-label">Имя</label>
                                <input
                                    className="form-control"
                                    name={`content[leaders__banners][${index}][full_name]`}
                                    defaultValue={banner.full_name}
                                />
                            </div>
                            <div key={index} className="col mb-5">
                                <LoadingImageInput
                                    width="300px"
                                    height="300px"
                                    src={
                                        banner.preview_file
                                            ? makePath(
                                                  banner.preview_file
                                                      .path as string,
                                              )
                                            : ``
                                    }
                                    onChange={(file) =>
                                        setBanners((prev) =>
                                            prev.map((prevVal, prevIndex) => {
                                                if (prevIndex === index) {
                                                    prevVal.preview_file = file;
                                                }
                                                return prevVal;
                                            }),
                                        )
                                    }
                                />
                                <input
                                    type="hidden"
                                    name={`content[leaders__banners][${index}][preview_file_id]`}
                                    value={banner.preview_file?.id}
                                />
                            </div>
                            <div className="row">
                                <h4 className="mb-5">Видео файл</h4>
                                <br />
                                <div>
                                    {!banner.video_file && (
                                        <div className="mb-5">
                                            <div className="mb-5">
                                                <div>
                                                    <label className="form-label">
                                                        Видео файл
                                                    </label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        name="file"
                                                        accept="video/*"
                                                        onChange={(event) =>
                                                            setBanners((prev) =>
                                                                prev.map(
                                                                    (
                                                                        val,
                                                                        prevIndex,
                                                                    ) => {
                                                                        if (
                                                                            prevIndex ===
                                                                            index
                                                                        ) {
                                                                            val.file =
                                                                                event.target.files?.item(
                                                                                    0,
                                                                                ) as File;
                                                                        }
                                                                        return val;
                                                                    },
                                                                ),
                                                            )
                                                        }
                                                    />
                                                    <input
                                                        type="hidden"
                                                        name={`content[leaders__banners][${index}][video_file_id]`}
                                                        defaultValue={undefined}
                                                    />
                                                </div>
                                            </div>
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() =>
                                                    addFile(banner?.file, index)
                                                }
                                            >
                                                Загрузить файл
                                            </button>
                                        </div>
                                    )}
                                    {banner.video_file && (
                                        <div>
                                            <div className="card border mb-4 position-relative">
                                                <div className="card-body">
                                                    <div className="d-flex flex-column justify-content-center">
                                                        <div>
                                                            <span className="fw-bolder">
                                                                Название файла:
                                                            </span>
                                                            {
                                                                banner
                                                                    .video_file
                                                                    ?.name
                                                            }
                                                        </div>
                                                        <div>
                                                            <span className="fw-bolder">
                                                                Формат файла:
                                                            </span>
                                                            {
                                                                banner
                                                                    .video_file
                                                                    ?.mime
                                                            }
                                                        </div>
                                                        <div>
                                                            <span className="fw-bolder">
                                                                Дата загрузки:
                                                            </span>
                                                            {
                                                                banner
                                                                    .video_file
                                                                    ?.created_at
                                                            }
                                                        </div>
                                                        <a
                                                            href={
                                                                banner.video_file
                                                                    ? makePath(
                                                                          banner
                                                                              .video_file
                                                                              .path,
                                                                      )
                                                                    : ``
                                                            }
                                                            target="_blank"
                                                            className="fw-bolder"
                                                        >
                                                            Скачать видео
                                                        </a>
                                                        <input
                                                            type="hidden"
                                                            name={`content[leaders__banners][${index}][video_file_id]`}
                                                            defaultValue={
                                                                banner
                                                                    .video_file
                                                                    ?.id
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                <i
                                                    className="bi bi-x-circle position-absolute text-danger end-0 mt-5 me-5 cursor-pointer"
                                                    onClick={() => {
                                                        setBanners((prev) =>
                                                            prev.map(
                                                                (
                                                                    val,
                                                                    prevIndex,
                                                                ) => {
                                                                    if (
                                                                        prevIndex ===
                                                                        index
                                                                    ) {
                                                                        val.video_file =
                                                                            undefined;
                                                                    }
                                                                    return val;
                                                                },
                                                            ),
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div>
                                        <a
                                            className="card-link text-danger"
                                            onClick={() => {
                                                setBanners((prev) =>
                                                    prev.filter(
                                                        (value) =>
                                                            value.id !==
                                                            banner.id,
                                                    ),
                                                );
                                            }}
                                        >
                                            Удалить
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="mb-5 d-flex">
                <div className="me-5">
                    <button
                        type="button"
                        className="btn btn-success"
                        onClick={() =>
                            setBanners((prevState) => [
                                ...prevState,
                                {
                                    video_file: undefined,
                                    preview_file: undefined,
                                    full_name: undefined,
                                    info: undefined,
                                },
                            ])
                        }
                    >
                        Добавить лидера
                    </button>
                </div>
            </div>
        </div>
    );
}
