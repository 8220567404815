import { post } from "../api";
import { type TextPage } from "@admin/domain/textPages/models/TextPage";

async function createTextPage(body: FormData): Promise<TextPage> {
    return await post({
        url: `/admin/text_pages`,
        body: body,
    });
}

async function updateTextPage(
    id: TextPage[`id`],
    body: FormData,
): Promise<TextPage> {
    return await post({
        url: `/admin/text_pages/${id}`,
        body: body,
    });
}

export async function saveTextPage(
    textPage: TextPage | null,
    body: FormData,
): Promise<TextPage> {
    return textPage ? updateTextPage(textPage.id, body) : createTextPage(body);
}
