import React from "react";

import { NotificationChannel } from "@admin/domain/notifications/models/NotificationModel";
import { saveNotification } from "@admin/domain/notifications/api";
import Editor from "@admin/components/Editor";
import Input from "@admin/components/Input";
import TextArea from "@admin/components/TextArea";
import CheckBox from "@admin/components/CheckBox";
import DateTimeInput from "@admin/components/DateTimeInput";

const LIST_URL = `/admin/notifications` as const;

const NotificationEditor: React.FC = () => {
    const notification = window.CMS.notification || null;

    const execute = async (body: FormData) => {
        await saveNotification(notification, body);
    };

    return (
        <Editor backUrl={LIST_URL} onSubmit={execute}>
            <Input
                label="Заголовок"
                name="title"
                defaultValue={notification?.title}
                disabled={notification?.is_sent}
            />

            <TextArea
                label="Текст"
                name="text"
                defaultValue={notification?.text || ``}
                disabled={notification?.is_sent}
                required={!notification?.is_sent}
            />

            <CheckBox
                label="В ЛК и на почту"
                name="channels[]"
                value={NotificationChannel.Web as string}
                defaultChecked={
                    notification?.channel === NotificationChannel.Web ||
                    notification?.channel === NotificationChannel.All
                }
                disabled={notification?.is_sent}
            />

            <CheckBox
                label="Push"
                name="channels[]"
                value={NotificationChannel.Push as string}
                defaultChecked={
                    notification?.channel === NotificationChannel.Push ||
                    notification?.channel === NotificationChannel.All
                }
                disabled={notification?.is_sent}
            />

            <DateTimeInput
                label="Дата и время отправки"
                name="send_at"
                defaultValue={notification?.send_at}
                disabled={notification?.is_sent}
            />
        </Editor>
    );
};

export default NotificationEditor;
