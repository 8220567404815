import React from "react";
import { makePath } from "@admin/utils/makePath";
import { MainBlockBannerModel } from "@admin/domain/uniquePages/models/MainPageModel";

type Props = {
    banner: MainBlockBannerModel;
    index: number;
};

export default function BannerImages({ banner, index }: Props) {
    return (
        <>
            <img
                style={{ width: `660px` }}
                className="card-img-top img_placeholder img-thumbnail"
                src={makePath(banner.file.path)}
                alt="Баннер для сайта"
            />
            <input
                name={`content[main_block__banners][${index}][file_id]`}
                value={banner.file.id}
                type="hidden"
            />

            {banner.mobile_app_file && banner.with_mobile_app_file && (
                <img
                    style={{ width: `343px` }}
                    className="card-img-top img_placeholder img-thumbnail"
                    src={makePath(banner.mobile_app_file.path)}
                    alt="Баннер для МП"
                />
            )}

            <input
                name={`content[main_block__banners][${index}][mobile_app_file_id]`}
                value={banner.mobile_app_file?.id}
                type="hidden"
            />

            <input
                name={`content[main_block__banners][${index}][with_mobile_app_file]`}
                value={banner.with_mobile_app_file ? `1` : `0`}
                type="hidden"
            />
        </>
    );
}
