import { post } from "../api";
import { type CustomPageModel } from "@admin/domain/customPages/models/CustomPageModel";

async function createCustomPage(body: FormData): Promise<CustomPageModel> {
    return await post({
        url: `/admin/custom_pages`,
        body: body,
    });
}

async function updateCustomPage(
    id: CustomPageModel[`id`],
    body: FormData,
): Promise<CustomPageModel> {
    return await post({
        url: `/admin/custom_pages/${id}`,
        body: body,
    });
}

export async function saveCustomPage(
    page: CustomPageModel | null,
    body: FormData,
): Promise<CustomPageModel> {
    return page ? updateCustomPage(page.id, body) : createCustomPage(body);
}
