import React from "react";

import { ProductLabelTypeModel } from "@admin/domain/products/models/edit/ProductAttributesModel";
import CheckBox from "@admin/components/CheckBox";

type LabelTypesProps = {
    labelTypes: ProductLabelTypeModel[];
};

export default function LabelTypes(props: LabelTypesProps) {
    return (
        <div className="rounded border p-10 mb-5">
            <h4 className="mb-5">Признаки товара</h4>

            {props.labelTypes.map((value, index) => (
                <CheckBox
                    key={value.id}
                    name={`product_attributes[label_type][${index}]`}
                    label={value.title}
                    value={value.id}
                    defaultChecked={value.selected}
                />
            ))}
        </div>
    );
}
