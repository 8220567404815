import { DosageFormModel } from "@admin/domain/products/models/edit/ProductSpecificationsModel";
import React from "react";
import convertToMapLanguageObject from "@admin/domain/languages/Languages";
import MultiLanguages from "@admin/components/MultiLanguage";
import Input from "@admin/components/Input";

export default function DosageForm({
    dosageForm,
}: {
    dosageForm: DosageFormModel;
}) {
    const mapText = convertToMapLanguageObject(dosageForm.texts);

    return (
        <MultiLanguages
            languageElement={(language) => (
                <Input
                    label="Форма выпуска"
                    name={`specifications[dosage_form][${language}]`}
                    defaultValue={mapText.get(language)?.title}
                />
            )}
        />
    );
}
