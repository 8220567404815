import React, { useState } from "react";
import { FileModel } from "@admin/domain/files/model/FileModel";
import { makePath } from "@admin/utils/makePath";

type Props = {
    label: string;
    name: string;
    icon: FileModel | null;
};

export default function DeliveryMethodIconInput(props: Props) {
    const [src, setSrc] = useState<string | ArrayBuffer | null>(
        props.icon?.path ? makePath(props.icon.path) : null,
    );

    const onChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => setSrc(reader.result);

            reader.readAsDataURL(file);
        }
    };

    return (
        <div className="mb-4">
            <div className="mb-2">
                <label className="form-label">{props.label}</label>
                <input
                    className="form-control"
                    type="file"
                    name={props.name}
                    accept="image/*"
                    onChange={onChangeImage}
                />
            </div>
            {src && <img src={src.toString()} className="img-thumbnail" />}
        </div>
    );
}
