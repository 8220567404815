export enum ArticleBlockType {
    TitleWithTextImage = `title_with_text_image`,
    VerticalImageWithTextCheckerboard = `vertical_image_with_text_checkerboard`,
    HorizontalImageWithTextCheckerboard = `horizontal_image_with_text_checkerboard`,
    OrderedList = `ordered_list`,
    UnorderedList = `unordered_list`,
    Faq = `faq`,
    Notifications = `notifications`,
    ColorTabs = `color_tabs`,
    Icons = `icons`,
    ScoreWithLinks = `score_with_links`,
    ProductsSlider = `products_slider`,
    DropDownElements = `drop_down_elements`,
    ProgramRules = `program_rules`,
    ArticleSubscribes = `article_subscribes`,
}
