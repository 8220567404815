import React from "react";
import { makePath } from "@admin/utils/makePath";
import { FileModel } from "@admin/domain/files/model/FileModel";
import useFiles from "./useFiles";

type Props = {
    files: FileModel[] | null;
    fileInputFunction: (fileIndex: number) => string;
    title: string;
};

export default function FilesList({
    files: initFiles,
    fileInputFunction,
    title,
}: Props) {
    const { files, addFile, loadFile, deleteFile, loadClientFile, resetFile } =
        useFiles(initFiles || []);

    return (
        <div className="mb-10">
            <h4 className="mb-5">{title}</h4>
            <div className="row row-cols-auto">
                {files.map(({ file, client_file }, index: number) => (
                    <div
                        key={file?.id || index}
                        className="col mb-5 rounded border p-10 m-5"
                    >
                        <h4 className="mb-5">Файл</h4>
                        <div>
                            {!file && (
                                <div className="mb-5">
                                    <div className="mb-5">
                                        <div>
                                            <label className="form-label">
                                                Файл
                                            </label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                name="file"
                                                onChange={(event) =>
                                                    loadClientFile(
                                                        index,
                                                        event.target.files?.item(
                                                            0,
                                                        ) as File,
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() =>
                                            loadFile(client_file, index)
                                        }
                                    >
                                        Загрузить файл
                                    </button>
                                </div>
                            )}
                            {file && (
                                <div>
                                    <div className="card border mb-4 position-relative">
                                        <div className="card-body">
                                            <div className="d-flex flex-column justify-content-center">
                                                <div>
                                                    <span className="fw-bolder">
                                                        Название файла:
                                                    </span>
                                                    {file.name}
                                                </div>
                                                <div>
                                                    <span className="fw-bolder">
                                                        Формат файла:
                                                    </span>
                                                    {file.mime}
                                                </div>
                                                <div>
                                                    <span className="fw-bolder">
                                                        Дата загрузки:
                                                    </span>
                                                    {file.created_at}
                                                </div>
                                                <a
                                                    href={makePath(file.path)}
                                                    target="_blank"
                                                    className="fw-bolder"
                                                >
                                                    Скачать файл
                                                </a>
                                                <input
                                                    type="hidden"
                                                    name={fileInputFunction(
                                                        index,
                                                    )}
                                                    defaultValue={file.id}
                                                />
                                            </div>
                                        </div>

                                        <i
                                            className="bi bi-x-circle position-absolute text-danger end-0 mt-5 me-5 cursor-pointer"
                                            onClick={() => resetFile(index)}
                                        />
                                    </div>
                                </div>
                            )}
                            <div>
                                <a
                                    className="card-link text-danger"
                                    onClick={() => deleteFile(index)}
                                >
                                    Удалить
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="mb-5 d-flex">
                <div className="me-5">
                    <button
                        type="button"
                        className="btn btn-success"
                        onClick={addFile}
                    >
                        Добавить файл
                    </button>
                </div>
            </div>
        </div>
    );
}
