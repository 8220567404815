import React, { useState } from "react";
import Input from "@admin/components/Input";
import Switch from "@admin/components/Switch";
import { saveDeliveryMethodRegion } from "@admin/domain/deliveryMethods/api";
import Editor from "@admin/components/Editor";
import { DeliveryMethodRegionConditionModel } from "@admin/domain/deliveryMethods/models/DeliveryMethodRegionModel";
import { v4 as uuid } from "uuid";

type Condition = Partial<DeliveryMethodRegionConditionModel>;

export default function DeliveryMethodRegionEditor() {
    const deliveryMethodRegion = window.CMS.deliveryMethodRegionConfig;

    const LIST_URL = (`/admin/delivery_methods/` +
        deliveryMethodRegion.delivery_method_id +
        `/countries/`) as const;

    const initConditions = (deliveryMethodRegion.conditions || []).map(
        (value) => {
            return { ...value, id: uuid() };
        },
    );

    const [deliveryMethodRegionConditions, setDeliveryMethodRegionConditions] =
        useState<Condition[]>(initConditions);

    const [isFreeShipping, setIsFreeShipping] = useState(
        deliveryMethodRegion?.is_free_shipping,
    );

    const onIsFreeShippingChange = () => {
        setIsFreeShipping(!isFreeShipping);
    };

    const execute = async (body: FormData) => {
        if (deliveryMethodRegion) {
            await saveDeliveryMethodRegion(
                deliveryMethodRegion.delivery_method_id,
                deliveryMethodRegion.id,
                body,
            );
        }
    };

    return (
        <>
            <Editor backUrl={LIST_URL} onSubmit={execute}>
                <Switch
                    label="Платная/Бесплатная доставка"
                    name="is_free_shipping"
                    onChange={onIsFreeShippingChange}
                    defaultChecked={deliveryMethodRegion?.is_free_shipping}
                />

                <Input
                    label="Стоимость"
                    name="cost"
                    type="number"
                    defaultValue={deliveryMethodRegion?.cost / 100}
                />

                <div className="d-flex">
                    <Input
                        label="Минимальный срок доставки"
                        name="delivery_time_min_days"
                        defaultValue={
                            deliveryMethodRegion?.delivery_time_min_days
                        }
                    />
                    <Input
                        className={{ block: `ms-4` }}
                        label="Максимальный срок доставки"
                        name="delivery_time_max_days"
                        defaultValue={
                            deliveryMethodRegion?.delivery_time_max_days
                        }
                    />
                </div>

                <Input
                    label="Сумма корзины для бесплатной доставки"
                    name="free_shipping_basket_amount"
                    type="number"
                    hidden={!isFreeShipping}
                    defaultValue={
                        deliveryMethodRegion?.free_shipping_basket_amount / 100
                    }
                />
                <div className="p-10">
                    <h3>Условия</h3>
                    {deliveryMethodRegionConditions?.map((condition, index) => (
                        <div key={index} className="col pb-5">
                            <input
                                type="hidden"
                                name={`conditions[${index}][id]`}
                                value={condition?.id}
                            />
                            <Input
                                className={{ block: `ms-4` }}
                                label="Минимальное значение стоимости корзины"
                                name={`conditions[${index}][min_basket_amount]`}
                                defaultValue={
                                    condition?.min_basket_amount
                                        ? condition?.min_basket_amount / 100
                                        : ``
                                }
                            />
                            <Input
                                className={{ block: `ms-4` }}
                                label="Максимальный значение стоимости корзины"
                                name={`conditions[${index}][max_basket_amount]`}
                                defaultValue={
                                    condition?.max_basket_amount
                                        ? condition?.max_basket_amount / 100
                                        : ``
                                }
                            />
                            <Input
                                className={{ block: `ms-4` }}
                                label="Значение стоимости доставки"
                                name={`conditions[${index}][delivery_cost]`}
                                defaultValue={
                                    condition?.delivery_cost
                                        ? condition?.delivery_cost / 100
                                        : ``
                                }
                            />
                            <div>
                                <a
                                    className="card-link text-danger"
                                    onClick={() => {
                                        setDeliveryMethodRegionConditions(
                                            (prev) =>
                                                prev.filter(
                                                    (value) =>
                                                        value.id !==
                                                        condition.id,
                                                ),
                                        );
                                    }}
                                >
                                    Удалить
                                </a>
                            </div>
                        </div>
                    ))}
                    <div className="d-flex justify-content-end mb-5">
                        <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => {
                                setDeliveryMethodRegionConditions(
                                    (prevState) => [
                                        ...prevState,
                                        {
                                            id: uuid(),
                                        },
                                    ],
                                );
                            }}
                        >
                            Добавить условие
                        </button>
                    </div>
                </div>
            </Editor>
        </>
    );
}
