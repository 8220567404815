import { post } from "../api";
import { FooterBlockModel } from "@admin/domain/footer/Models/FooterBlockModel";

export const updateFooterBlocks = async (
    body: FormData,
): Promise<FooterBlockModel> => {
    return await post({
        url: `/admin/footer/`,
        body,
    });
};
