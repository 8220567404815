import React, { useState } from "react";

import { Override } from "@admin/utils/overrideType";
import Sortable from "@admin/components/Sortable";
import { FooterBlockModel } from "@admin/domain/footer/Models/FooterBlockModel";
import FooterBlocksModal from "@admin/pages/FooterEditor/FooterBlocksModal";

export type GeneralCondition = Override<
    FooterBlockModel,
    { id: string | number }
>;

export type FooterBlocksProps = {
    blocks: FooterBlockModel[] | null;
};

export default function FooterBlocksEditor(props: FooterBlocksProps) {
    const [showModal, setShowModal] = useState(false);

    const [generalConditions, setGeneralConditions] = useState<
        GeneralCondition[]
    >(props.blocks || []);
    const [editableGeneralCondition, setEditableGeneralCondition] =
        useState<GeneralCondition | null>(null);

    const addGeneralCondition = (data: GeneralCondition) =>
        setGeneralConditions((prev) => [...prev, data]);

    const editGeneralCondition = (data: GeneralCondition) => {
        setGeneralConditions((prev) =>
            prev.map((value) => {
                if (value.id === data.id) value = data;
                return value;
            }),
        );
        setEditableGeneralCondition(null);
    };

    return (
        <>
            <Sortable list={generalConditions} setList={setGeneralConditions}>
                {generalConditions.map((generalCondition, index) => (
                    <div
                        key={generalCondition.id}
                        className="card rounded border mb-4"
                    >
                        <div className="card-body">
                            {typeof generalCondition.id === `number` && (
                                <input
                                    type="hidden"
                                    name={`footer_blocks[${index}][id]`}
                                    value={generalCondition.id}
                                />
                            )}

                            <input
                                type="hidden"
                                name={`footer_blocks[${index}][title]`}
                                value={generalCondition.title}
                            />

                            <h3 className="card-text">
                                {generalCondition.title}
                            </h3>
                            {generalCondition.value.map((page, pageIndex) => (
                                <div
                                    key={page.id}
                                    className="card rounded border mb-4"
                                >
                                    <p>{page.title}</p>

                                    <input
                                        type="hidden"
                                        name={`footer_blocks[${index}][pages][${pageIndex}][id]`}
                                        value={page.id}
                                    />

                                    <input
                                        type="hidden"
                                        name={`footer_blocks[${index}][pages][${pageIndex}][title]`}
                                        value={page.title}
                                    />

                                    <input
                                        type="hidden"
                                        name={`footer_blocks[${index}][pages][${pageIndex}][url]`}
                                        value={page.url}
                                    />
                                </div>
                            ))}

                            <a
                                className="card-link text-primary cursor-pointer"
                                onClick={() => {
                                    setEditableGeneralCondition(
                                        generalCondition,
                                    );
                                    setShowModal(true);
                                }}
                            >
                                Редактировать
                            </a>
                            <a
                                className="card-link text-danger cursor-pointer"
                                onClick={() =>
                                    setGeneralConditions((prev) =>
                                        prev.filter(
                                            (value) =>
                                                value.id !==
                                                generalCondition.id,
                                        ),
                                    )
                                }
                            >
                                Удалить
                            </a>
                        </div>
                    </div>
                ))}
            </Sortable>
            {generalConditions.length < 5 && (
                <div className="d-flex justify-content-end mb-5">
                    <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                            setEditableGeneralCondition(null);
                            setShowModal(true);
                        }}
                    >
                        Добавить блок
                    </button>
                </div>
            )}

            <FooterBlocksModal
                showModal={showModal}
                closeModal={() => setShowModal(false)}
                onSubmit={
                    editableGeneralCondition
                        ? editGeneralCondition
                        : addGeneralCondition
                }
                editableGeneralCondition={editableGeneralCondition}
            />
        </>
    );
}
