import React, { useState } from "react";
import { makePath } from "@admin/utils/makePath";
import MainPageAdvantageModal from "./MainPageAdvantageModal";
import {
    AdvantageModel,
    MainPageModel,
} from "@admin/domain/uniquePages/models/MainPageModel";
import { v4 as uuid } from "uuid";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import { type UniquePageProps } from "../../index";

export default function MainPageMainBlock({
    page,
}: UniquePageProps<MainPageModel>) {
    const bannersInit = (page.contentObject.advantage || []).map((value) => {
        value.id = uuid();
        return value;
    });

    const currentLanguage = useLanguageContext().currentLanguage;

    const [showModal, setShowModal] = useState(false);
    const [banners, setBanners] = useState(bannersInit);
    const [editableBanner, setEditableBanner] = useState<
        AdvantageModel | undefined
    >();

    const addHandle = (data: AdvantageModel) => {
        setBanners((prev) => [...prev, data]);
    };

    const editHandle = (data: AdvantageModel) => {
        setBanners((prev) => {
            const index = prev.findIndex((value) => value.id === data.id);
            prev[index] = data;
            return prev;
        });
    };

    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок преимуществ</h4>
            {banners.map((banner, index) => (
                <div
                    key={banner.id}
                    className="mb-5"
                    style={{ width: `fit-content`, height: `fit-content` }}
                >
                    <div className="card border">
                        <img
                            style={{ width: `70px` }}
                            className="card-img-top img_placeholder img-thumbnail"
                            src={makePath(banner.file.path)}
                        />
                        <input
                            type="hidden"
                            name={`content[advantage][${index}][file_id]`}
                            value={banner.file.id}
                        />
                        <div className="card-body">
                            <div className="card-text mb-3">
                                <div>
                                    <span className="fw-bolder">
                                        Заголовок:{` `}
                                    </span>
                                    {` `}
                                    {banner.title[currentLanguage]}
                                    {Object.entries(banner.title).map(
                                        ([lang, value]) => (
                                            <input
                                                type="hidden"
                                                key={lang}
                                                name={`content[advantage][${index}][title][${lang}]`}
                                                value={value}
                                            />
                                        ),
                                    )}
                                </div>
                                <div>
                                    <span className="fw-bolder">
                                        Описание:{` `}
                                    </span>
                                    {` `}
                                    {banner.description[currentLanguage]}
                                    {Object.entries(banner.description).map(
                                        ([lang, value]) => (
                                            <input
                                                type="hidden"
                                                key={lang}
                                                name={`content[advantage][${index}][description][${lang}]`}
                                                value={value}
                                            />
                                        ),
                                    )}
                                </div>
                            </div>
                            <div>
                                <a
                                    className="card-link text-success"
                                    onClick={() => {
                                        setEditableBanner(banner);
                                        setShowModal(true);
                                    }}
                                >
                                    Редактировать
                                </a>
                                <a
                                    className="card-link text-danger"
                                    onClick={() => {
                                        setBanners((prev) =>
                                            prev.filter(
                                                (value) =>
                                                    value.id !== banner.id,
                                            ),
                                        );
                                    }}
                                >
                                    Удалить
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            {banners.length < 10 && (
                <i
                    className="bi bi-plus-square text-primary fs-2hx cursor-pointer ms-3 align-self-center"
                    onClick={() => {
                        setEditableBanner(undefined);
                        setShowModal(true);
                    }}
                />
            )}
            {showModal && (
                <MainPageAdvantageModal
                    showModal={showModal}
                    closeModal={() => setShowModal(false)}
                    onSubmit={editableBanner ? editHandle : addHandle}
                    editableBanner={editableBanner}
                />
            )}
        </div>
    );
}
