import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import RadioButton from "@admin/components/RadioButton";

type Props = {
    title: string;
    options: Record<string, string>;
    defaultValue?: string;
    submitText?: string;
    show: boolean;
    close: () => void;
    onSubmit: (value: string) => void;
};

export default function OptionsModal({
    title,
    options,
    defaultValue = ``,
    submitText = `Добавить`,
    show,
    close,
    onSubmit,
}: Props) {
    const [selected, setSelected] = useState<string>(defaultValue);

    const submit = (): void => onSubmit(selected);

    return (
        <Modal centered show={show} onHide={close}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {Object.entries(options).map(([value, label]) => (
                    <RadioButton
                        key={`radio-${value}`}
                        label={label}
                        name={`radio`}
                        value={value}
                        checked={selected === value}
                        onChange={() => setSelected(value)}
                    />
                ))}
            </Modal.Body>

            <Modal.Footer>
                <button className="btn btn-secondary" onClick={close}>
                    Закрыть
                </button>
                <button className="btn btn-primary" onClick={submit}>
                    {submitText}
                </button>
            </Modal.Footer>
        </Modal>
    );
}
