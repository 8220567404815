import { post } from "../api";
import { type UniquePageModel } from "@admin/domain/uniquePages/models/UniquePageModel";

export async function updateUniquePage(
    id: UniquePageModel<unknown>[`id`],
    body: FormData,
): Promise<UniquePageModel<unknown>> {
    return await post({
        url: `/admin/unique_pages/${id}`,
        body: body,
    });
}
