import React from "react";
import { FilesListModel } from "@admin/domain/content/models/ContentBlockValueModel";
import { ContentBlockModel } from "@admin/domain/content/models/ContentBlockModel";
import { FileModel } from "@admin/domain/files/model/FileModel";
import FilesList from "../../FilesList";

type Props = {
    block: ContentBlockModel;
    index: number;
    key: number;
    onSubmit: (blockId: number) => void;
};

export default function FilesBlock(props: Props) {
    const value = props.block?.value as FilesListModel | undefined;

    return (
        <>
            <FilesList
                files={(value?.file_list || []).map(
                    ({ file }): FileModel => file,
                )}
                fileInputFunction={(index: number) =>
                    `blocks[${props.index}][value][file_list][${index}][file_id]`
                }
                title="Блок с файлами"
            />

            <div>
                <a
                    className="card-link text-danger"
                    onClick={() => props.onSubmit(props.block.id)}
                >
                    Удалить блок
                </a>
            </div>
        </>
    );
}
