import React, { useState } from "react";
import MultiLanguages from "@admin/components/MultiLanguage";
import { type UniquePageProps } from "../index";
import { BusinessExpansionPageModel } from "@admin/domain/uniquePages/models/BusinessExpansionPageModel";
import LoadingImageInput from "@admin/components/LoadingImageInput";

export default function BusinessExpansionPageNewDistributorRegister({
    page,
}: UniquePageProps<BusinessExpansionPageModel>) {
    const [image, setImage] = useState(
        page.contentObject?.new_distributor_block__banner_file,
    );
    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок “Регистрация нового дистрибьютора</h4>
            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <div className="mb-5">
                                <label className="form-label">Заголовок</label>
                                <input
                                    className="form-control"
                                    name={`content[new_distributor_block__title][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .new_distributor_block__title?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">Описание</label>
                                <textarea
                                    className="form-control"
                                    name={`content[new_distributor_block__description][${language}]`}
                                    defaultValue={
                                        page.contentObject
                                            .new_distributor_block__description?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                        </div>
                    )}
                />
                <div className="mb-5">
                    <label className="form-label">
                        Ссылка на страницу регистрации нового дистрибьютора
                    </label>
                    <input
                        className="form-control"
                        name={`content[new_distributor_block__url]`}
                        defaultValue={
                            page.contentObject.new_distributor_block__url
                        }
                    />
                </div>
                <div className="row">
                    <div className="mb-5">
                        <label className="form-label">Изображение</label>
                        <LoadingImageInput
                            width="400px"
                            height="400px"
                            src={image?.path}
                            onChange={setImage}
                        />
                        <input
                            type="hidden"
                            name={`content[new_distributor_block__banner_file][file_id]`}
                            value={image?.id}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
