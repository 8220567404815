import { post } from "../api";
import { type PageSeoParamsModel } from "@admin/domain/seo/models/PageSeoParamsModel";

async function createTemplate(body: FormData): Promise<PageSeoParamsModel> {
    return await post({
        url: `/admin/seo/templates`,
        body: body,
    });
}

async function updateTemplate(
    id: PageSeoParamsModel[`id`],
    body: FormData,
): Promise<PageSeoParamsModel> {
    return await post({
        url: `/admin/seo/templates/${id}`,
        body: body,
    });
}

export async function saveTemplate(
    template: PageSeoParamsModel | null,
    body: FormData,
): Promise<PageSeoParamsModel> {
    return template ? updateTemplate(template.id, body) : createTemplate(body);
}
