import React, { type ChangeEvent } from "react";

import {
    DeliveryPointModel,
    DeliveryPointProvider,
} from "@admin/domain/deliveryPoints/models/DeliveryPointModel";
import Input from "@admin/components/Input";
import QuillEditor from "@admin/components/QuillEditor";
import Select from "@admin/components/Select";
import CheckBox from "@admin/components/CheckBox";

type CommonParametersProps = {
    deliveryPoint: DeliveryPointModel | null;
    setProvider: (provider: DeliveryPointProvider) => void;
};

const CommonParameters: React.FC<CommonParametersProps> = ({
    deliveryPoint,
    setProvider,
}) => {
    return (
        <>
            <h2>Общие параметры</h2>

            {deliveryPoint && (
                <>
                    <Input
                        label="Дата и время создания"
                        name="created_at"
                        defaultValue={deliveryPoint.created_at}
                        disabled
                    />

                    <Input
                        label="Дата и время изменения"
                        name="updated_at"
                        defaultValue={deliveryPoint.updated_at}
                        disabled
                    />
                </>
            )}

            <Input
                label="Название"
                name="name"
                defaultValue={deliveryPoint?.name}
            />

            <QuillEditor
                label="Комментарий"
                name="comment"
                initHtml={deliveryPoint?.comment}
            />

            <Select
                value={deliveryPoint?.provider}
                label="Тип пункта доставки"
                options={[
                    { value: ``, label: `Не выбран` },
                    {
                        value: DeliveryPointProvider.Tentorium,
                        label: `Тенториум`,
                    },
                    {
                        value: DeliveryPointProvider.Boxberry,
                        label: `Boxberry`,
                    },
                    { value: DeliveryPointProvider.Cdek, label: `Cdek` },
                ]}
                name="provider"
                onChange={(e: ChangeEvent<HTMLSelectElement>): void =>
                    setProvider(e.target.value as DeliveryPointProvider)
                }
                required
            />

            <CheckBox
                label={deliveryPoint ? `Показать` : `Показать после создания`}
                name="is_hidden"
                defaultChecked={deliveryPoint ? !deliveryPoint.is_hidden : true}
            />
        </>
    );
};

export default CommonParameters;
