const createRange = ($input: HTMLDivElement, targetPosition: number) => {
    const range = document.createRange();
    range.selectNode($input);
    range.setStart($input, 0);

    let pos = 0;

    const stack: (HTMLElement | ChildNode)[] = [$input];

    while (stack.length > 0) {
        const current = stack.pop();

        if (!current) {
            break;
        }

        if (current.nodeType === Node.TEXT_NODE) {
            const len = current.textContent?.length || 0;
            if (pos + len >= targetPosition) {
                range.setStart(current, targetPosition - pos);
                range.setEnd(current, targetPosition - pos);
                return range;
            }
            pos += len;
        } else if (current.childNodes && current.childNodes.length > 0) {
            for (let i = current.childNodes.length - 1; i >= 0; i--) {
                stack.push(current.childNodes[i]);
            }
        }
    }

    // The target position is greater than the
    // length of the contenteditable element.
    range.setStart($input, $input.childNodes.length);
    range.setEnd($input, $input.childNodes.length);

    return range;
};

const getCaretPosition = ($input: HTMLDivElement | null) => {
    const selection = window.getSelection();

    if (!$input || !selection) {
        return 0;
    }

    let range;

    if (selection.rangeCount > 0) {
        range = selection.getRangeAt(0);
    } else {
        range = document.createRange();
    }

    const clonedRange = range.cloneRange();
    clonedRange.selectNodeContents($input);
    clonedRange.setEnd(range.endContainer, range.endOffset);

    return clonedRange.toString().length;
};

const setCaretPosition = (
    $input: HTMLDivElement | null,
    targetPosition: number,
) => {
    const selection = window.getSelection();

    if (!$input || !selection) {
        return;
    }

    const range = createRange($input, targetPosition);

    selection.removeAllRanges();
    selection.addRange(range);
};

export { getCaretPosition, setCaretPosition };
