import React from "react";

import { type UniquePageProps } from "../index";
import { type AboutPageModel } from "@admin/domain/uniquePages/models/AboutPageModel";
import { type ActiveComponentsModel } from "@admin/domain/products/models/edit/ProductSpecificationsModel";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import MultiLanguages from "@admin/components/MultiLanguage";
import { Language } from "@admin/domain/languages/Languages";
import Input from "@admin/components/Input";

export default function AboutPageComponents({
    page: page,
}: UniquePageProps<AboutPageModel>) {
    page.payload.activeComponents?.map(
        (activeComponent: ActiveComponentsModel) => {
            activeComponent.textsMap = new Map(
                activeComponent.texts.map((text) => [text.language, text]),
            );
            return activeComponent;
        },
    );

    const currentLanguage = useLanguageContext().currentLanguage;

    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок с активными компонентами</h4>

            {[...Array(10)].map((_, index) => (
                <div key={index} className="mb-5 row">
                    <div className="col">
                        <label className="form-label">Активный компонент</label>
                        <select
                            className="form-control"
                            name={`content[active_components][${index}][active_component_id]`}
                            defaultValue={
                                page.contentObject.active_components?.at(index)
                                    ?.id
                            }
                        >
                            {page.payload.activeComponents?.map(
                                (activeComponent: ActiveComponentsModel) => (
                                    <option
                                        key={activeComponent.id}
                                        value={activeComponent.id}
                                    >
                                        {
                                            activeComponent.textsMap?.get(
                                                currentLanguage,
                                            )?.title
                                        }
                                    </option>
                                ),
                            )}
                        </select>
                    </div>

                    <div className="col">
                        <MultiLanguages
                            languageElement={(language: Language) => (
                                <Input
                                    label="Описание"
                                    name={`content[active_components][${index}][description][${language}]`}
                                    defaultValue={
                                        page.contentObject.active_components?.at(
                                            index,
                                        )?.texts[language]?.description
                                    }
                                />
                            )}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
}
