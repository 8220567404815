import React, { useState } from "react";
import MultiLanguages from "@admin/components/MultiLanguage";
import { ContentBlockModel } from "@admin/domain/content/models/ContentBlockModel";

type Props = {
    block: ContentBlockModel;
    index: number;
    key: number;
    onSubmit: (blockId: number) => void;
};

export default function TitleBlock(props: Props) {
    const [value, setValue] = useState(props?.block?.value?.value || `h2`);

    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок с заголовком определенного уровня</h4>
            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <div className="mb-5">
                                <label className="form-label">Заголовок</label>
                                <input
                                    className="form-control"
                                    name={`blocks[${props.index}][value][title][${language}]`}
                                    defaultValue={
                                        props?.block?.value?.title?.[
                                            language
                                        ] || ``
                                    }
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">Текст</label>
                                <textarea
                                    className="form-control"
                                    name={`blocks[${props.index}][value][text][${language}]`}
                                    defaultValue={
                                        props?.block?.value?.text?.[language] ||
                                        ``
                                    }
                                />
                            </div>
                        </div>
                    )}
                />
                <div>
                    <h5 className="mb-5">Уровень заголовка</h5>
                    <div className="mb-5">
                        <label className="form-label">h2</label>
                        <input
                            type="radio"
                            name={`blocks[${props.index}][value][value]`}
                            value="h2"
                            checked={value === `h2`}
                            onChange={() => setValue(`h2`)}
                        />
                    </div>
                    <div className="mb-5">
                        <label className="form-label">h3</label>
                        <input
                            type="radio"
                            name={`blocks[${props.index}][value][value]`}
                            value="h3"
                            checked={value === `h3`}
                            onChange={() => setValue(`h3`)}
                        />
                    </div>
                    <div className="mb-5">
                        <label className="form-label">h4</label>
                        <input
                            type="radio"
                            name={`blocks[${props.index}][value][value]`}
                            value="h4"
                            checked={value === `h4`}
                            onChange={() => setValue(`h4`)}
                        />
                    </div>
                </div>
            </div>
            <div>
                <a
                    className="card-link text-danger"
                    onClick={() => {
                        props.onSubmit(props.block.id);
                    }}
                >
                    Удалить блок
                </a>
            </div>
        </div>
    );
}
