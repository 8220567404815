export enum ContentBlockType {
    Image = `image`,
    Text = `text`,
    PhotoWithTextSlider = `photo_with_text_slider`,
    Video = `video`,
    TitleWithText = `title_with_text`,
    TitleWithOrderedList = `title_with_ordered_list`,
    TitleWithUnorderedList = `title_with_unordered_list`,
    BGColorText = `bg_color_text`,
    ImageWithTextCheckerboard = `image_with_text_checkerboard`,
    Leaders = `leaders`,
    ExpertOpinions = `expert_opinions`,
    Directories = `directories`,
    Button = `button`,
    ImagesSlider = `images_slider`,
    Files = `files`,
    Banner = `banner`,
    Faq = `faq`,
    NewsSubscribes = `news_subscribes`,
    Sharing = `sharing`,
    ProductsSlider = `products_slider`,
    OtherNews = `other_news`,
}
