import React, { createRef, useState } from "react";
import { ProductModel } from "@admin/domain/products/models/ProductModel";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import { getCrossSaleProduct } from "@admin/domain/products/api";
import { useIsInvalid } from "@admin/components/FormErrorsContext";

export default function CrossSale(props: { crossSales: ProductModel[] }) {
    const currentLanguage = useLanguageContext().currentLanguage;

    const [crossSales, setCrossSales] = useState(
        props.crossSales.map((crossSale) => {
            crossSale.textsMap = new Map(
                crossSale.texts.map((text) => [text.language, text]),
            );
            return crossSale;
        }),
    );

    const inputRef = createRef<HTMLInputElement>();

    const isInvalid = useIsInvalid(`cross_sale_products`);

    const addCrossSale = async () => {
        if (!inputRef.current) return;
        const characteristicId = inputRef.current.value;

        await getCrossSaleProduct(characteristicId).then((crossSale) => {
            if (!crossSale) return;

            crossSale.textsMap = new Map(
                crossSale.texts.map((text) => [text.language, text]),
            );
            setCrossSales((prevState) => [...prevState, crossSale]);
        });

        inputRef.current.value = ``;
    };

    return (
        <div
            className={
                `rounded border p-10 mb-5` + (isInvalid ? ` border-danger` : ``)
            }
        >
            <h4 className="mb-5">Рекомендуемые товары (кроссейлы)</h4>

            <div className="input-group mb-5 w-50">
                <input
                    type="text"
                    className="form-control"
                    placeholder="ID характеристики товара"
                    ref={inputRef}
                />
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => addCrossSale()}
                >
                    Добавить
                </button>
            </div>

            {crossSales.map((crossSale) => (
                <div
                    key={crossSale.id}
                    className="card border mb-4 position-relative"
                >
                    <div className="card-body">
                        <input
                            type="hidden"
                            name="cross_sale_products[]"
                            defaultValue={crossSale.id}
                        />

                        <div>
                            <span className="fw-bolder">
                                ID характеристики:
                            </span>
                            {` `}
                            {crossSale.characteristic_id}
                        </div>
                        <div>
                            <span className="fw-bolder">Название:</span>
                            {` `}
                            {crossSale.textsMap?.get(currentLanguage)?.title}
                        </div>
                    </div>
                    <i
                        className="bi bi-x-circle position-absolute text-danger end-0 mt-5 me-5 cursor-pointer"
                        onClick={() =>
                            setCrossSales((prevState) =>
                                prevState.filter(
                                    (prevValue) =>
                                        prevValue.id !== crossSale.id,
                                ),
                            )
                        }
                    />
                </div>
            ))}
        </div>
    );
}
