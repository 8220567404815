import React, { ReactNode } from "react";

import MainPage from "./MainPage";
import AboutPage from "./AboutPage";
import BusinessPage from "./BusinessPage";
import BusinessExpansionPage from "./BusinessExpansionPage";
import HowCreateOrderPage from "./HowCreateOrderPage";
import LiveBroadcastsPage from "./LiveBroadcasts";
import DeliveryAndPaymentPage from "./DeliveryAndPaymentPage";
import DeliveryPage from "@admin/pages/UniquePageEditor/DeliveryPage";
import ReturnPage from "./ReturnPage";
import { updateUniquePage } from "@admin/domain/uniquePages/api";
import { PaymentPage } from "./PaymentPage";
import Editor from "@admin/components/Editor";
import { type UniquePageModel } from "@admin/domain/uniquePages/models/UniquePageModel";
import Input from "@admin/components/Input";
import MultiLanguages from "@admin/components/MultiLanguage";
import PublishedAtInput from "@admin/components/PublishedAtInput";
import Switch from "@admin/components/Switch";
import Select from "@admin/components/Select";
import PageSeoParamsEditor from "@admin/components/PageSeoParamsEditor";

const LIST_URL = `/admin/unique_pages` as const;

export type UniquePageProps<T> = {
    page: UniquePageModel<T>;
};

export default function UniquePageEditor() {
    const pages = new Map<
        string,
        ({ page }: UniquePageProps<any>) => ReactNode
    >([
        [`main`, MainPage],
        [`about`, AboutPage],
        [`business`, BusinessPage],
        [`delivery_and_payment`, DeliveryAndPaymentPage],
        [`delivery`, DeliveryPage],
        [`payment`, PaymentPage],
        [`return`, ReturnPage],
        [`how_to_create_an_order`, HowCreateOrderPage],
        [`live_broadcasts`, LiveBroadcastsPage],
        [`business_expansion`, BusinessExpansionPage],
    ]);

    const page = window.CMS.uniquePage;

    if (!page) {
        throw new Error(`unique page not found`);
    }

    page.textsMap = new Map(page.texts.map((value) => [value.language, value]));
    page.contentObject = Object.fromEntries(
        page.contents.map((content) => [content.code, content.value]),
    );

    const children = pages.get(page.code);

    async function execute(body: FormData) {
        await updateUniquePage((page as UniquePageModel<any>).id, body);
    }

    return (
        <Editor backUrl={LIST_URL} onSubmit={execute}>
            <Switch
                label="Скрыть / Показать"
                name="is_public"
                defaultChecked={page?.is_public}
            />

            <Input label="Код страницы" defaultValue={page.code} disabled />
            <Input label="URL страницы" defaultValue={page.url} disabled />

            <MultiLanguages
                languageElement={(language) => (
                    <>
                        <input
                            type="hidden"
                            name={`texts[${language}][language]`}
                            defaultValue={language}
                        />

                        <Input
                            label="Заголовок страницы"
                            name={`texts[${language}][title]`}
                            defaultValue={page?.textsMap.get(language)?.title}
                        />
                    </>
                )}
            />

            <Select
                label="Автор"
                name="author_id"
                defaultValue={page?.author?.id}
                disabled={!page}
                options={(page?.authors || []).map((author) => ({
                    value: author.id as number,
                    label: `${author.name} ${author.email}`,
                }))}
                required
            />

            <PublishedAtInput defaultValue={page.published_at} />

            <PageSeoParamsEditor />

            {children && children({ page })}
        </Editor>
    );
}
