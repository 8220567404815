export const makePath = (path: string | null | undefined): string => {
    if (!path) {
        return ``;
    }

    if (!path.startsWith(`/`)) {
        path = `/${path}`;
    }

    if (/^\/[a-f0-9]{3}\//.test(path)) {
        path = `/media/storage${path}`;
    }

    return path;
};
