import { LanguageKeyObject, Language } from "../../languages/Languages";
import { FileModel } from "../../files/model/FileModel";

export type Chapter = {
    id: number;
    is_public: boolean;
    created_at: string;
    updated_at: string;
    file?: FileModel;
    texts: LanguageKeyObject<ChapterText>;
    stories: Array<Story>;
};

export type Story = {
    id: number;
    texts: LanguageKeyObject<StoryText>;
    file?: FileModel;
    playback_duration: number;
    url: string;
    type: StoryType;
    light_background: boolean;
    is_public: boolean;
    uuid: string;
    product: {
        id: string;
        title: string;
    };
};

export enum StoryType {
    Product = `product`,
    Banner = `banner`,
}

type ChapterText = {
    id: number;
    language: Language;
    title: string;
};

type StoryText = {
    id: number;
    language: Language;
    title: string;
    sub_title: string;
};
