import React, { useState } from "react";
import MultiLanguages from "@admin/components/MultiLanguage";
import { ContentBlockModel } from "@admin/domain/content/models/ContentBlockModel";
import {
    ProductSliderModel,
    ProductsSliderModel,
} from "@admin/domain/content/models/ContentBlockValueModel";
import ProductsSearch from "../../ProductsSearch";

type Props = {
    block: ContentBlockModel;
    index: number;
    key: number;
    onSubmit: (blockId: number) => void;
};

export default function ProductsSliderBlock(props: Props) {
    const value = props.block.value as ProductsSliderModel | undefined;
    const [products, setProducts] = useState<ProductSliderModel[]>(
        value?.products || [],
    );

    return (
        <div className="mb-10">
            <h4 className="mb-5">Блок с товарами</h4>
            <div>
                <MultiLanguages
                    languageElement={(language) => (
                        <div>
                            <div className="mb-5">
                                <label className="form-label">Заголовок</label>
                                <input
                                    className="form-control"
                                    name={`blocks[${props.index}][value][title][${language}]`}
                                    defaultValue={
                                        value?.title?.[language] || ``
                                    }
                                />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">
                                    Текст кнопки
                                </label>
                                <input
                                    className="form-control"
                                    name={`blocks[${props.index}][value][button_text][${language}]`}
                                    defaultValue={
                                        value?.button_text?.[language] || ``
                                    }
                                />
                            </div>
                        </div>
                    )}
                />
                <div className="mb-5">
                    <label className="form-label">Ссылка для кнопки</label>
                    <input
                        className="form-control"
                        name={`blocks[${props.index}][value][url]`}
                        defaultValue={value?.url || ``}
                    />
                </div>
            </div>
            <div className="rounded border p-10 my-5">
                <h3>Товары</h3>

                {products.map((product: ProductSliderModel, index: number) => (
                    <div
                        key={product.id || `product-${index}`}
                        className="col mb-5"
                    >
                        <ProductsSearch
                            inputName={`blocks[${props.index}][value][product_ids][${index}]`}
                            productId={product.id}
                            productTitle={product.title}
                            onChange={(value, label): void =>
                                setProducts(
                                    products.map(
                                        (
                                            product: ProductSliderModel,
                                            productIndex: number,
                                        ) => {
                                            if (productIndex === index) {
                                                return {
                                                    id: value || ``,
                                                    title: label || ``,
                                                };
                                            }

                                            return product;
                                        },
                                    ),
                                )
                            }
                        />
                        <div>
                            <a
                                className="card-link text-danger"
                                onClick={(): void =>
                                    setProducts(
                                        products.filter(
                                            (
                                                _p: ProductSliderModel,
                                                productIndex: number,
                                            ): boolean =>
                                                productIndex !== index,
                                        ),
                                    )
                                }
                            >
                                Удалить
                            </a>
                        </div>
                    </div>
                ))}

                <div className="mb-5 d-flex">
                    <div className="me-5">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(): void =>
                                setProducts([
                                    ...products,
                                    {
                                        id: ``,
                                        title: ``,
                                    },
                                ])
                            }
                        >
                            Добавить товар
                        </button>
                    </div>
                </div>
            </div>

            <div>
                <a
                    className="card-link text-danger"
                    onClick={() => props.onSubmit(props.block.id)}
                >
                    Удалить блок
                </a>
            </div>
        </div>
    );
}
