import React from "react";
import { useLanguageContext } from "@admin/components/LanguagesContext";
import { MainBlockBannerModel } from "@admin/domain/uniquePages/models/MainPageModel";

type Props = {
    banner: MainBlockBannerModel;
    index: number;
};

export default function BannerFields({ banner, index }: Props) {
    const { currentLanguage } = useLanguageContext();

    return (
        <div className="card-text mb-3">
            <div>
                <span className="fw-bolder">Заголовок: </span>
                {` `}
                {banner.title[currentLanguage]}
                {Object.entries(banner.title).map(([lang, value]) => (
                    <input
                        key={lang}
                        name={`content[main_block__banners][${index}][title][${lang}]`}
                        value={value}
                        type="hidden"
                    />
                ))}
            </div>

            <div>
                <span className="fw-bolder">Описание: </span>
                {` `}
                {banner.description[currentLanguage]}
                {Object.entries(banner.description).map(([lang, value]) => (
                    <input
                        key={lang}
                        name={`content[main_block__banners][${index}][description][${lang}]`}
                        value={value}
                        type="hidden"
                    />
                ))}
            </div>

            <div>
                <span className="fw-bolder">Текст кнопки: </span>
                {` `}
                {banner.button_text[currentLanguage]}
                {Object.entries(banner.button_text).map(([lang, value]) => (
                    <input
                        key={lang}
                        name={`content[main_block__banners][${index}][button_text][${lang}]`}
                        value={value}
                        type="hidden"
                    />
                ))}
            </div>

            <div>
                <span className="fw-bolder">Ссылка: </span> {banner.url}
                <input
                    name={`content[main_block__banners][${index}][url]`}
                    type="hidden"
                    value={banner.url}
                />
            </div>

            <div>
                <span className="fw-bolder">Тэг: </span>
                {` `}
                {banner.tag[currentLanguage]}
                {Object.entries(banner.tag).map(([lang, value]) => (
                    <input
                        name={`content[main_block__banners][${index}][tag][${lang}]`}
                        type="hidden"
                        value={value}
                        key={lang}
                    />
                ))}
            </div>

            {banner.with_mobile_app_file && (
                <>
                    <div>
                        <span className="fw-bolder">Заголовок для МП: </span>
                        {` `}
                        {banner.mobile_app_title?.[currentLanguage]}
                        {Object.entries(banner.mobile_app_title || {}).map(
                            ([lang, value]) => (
                                <input
                                    name={`content[main_block__banners][${index}][mobile_app_title][${lang}]`}
                                    type="hidden"
                                    value={value}
                                    key={lang}
                                />
                            ),
                        )}
                    </div>

                    <div>
                        <span className="fw-bolder">Описание для МП: </span>
                        {` `}
                        {banner.mobile_app_description?.[currentLanguage]}
                        {Object.entries(
                            banner.mobile_app_description || {},
                        ).map(([lang, value]) => (
                            <input
                                name={`content[main_block__banners][${index}][mobile_app_description][${lang}]`}
                                type="hidden"
                                value={value}
                                key={lang}
                            />
                        ))}
                    </div>

                    <div>
                        <span className="fw-bolder">Ссылка для МП: </span>
                        {` `}
                        {banner.mobile_app_url}
                        <input
                            name={`content[main_block__banners][${index}][mobile_app_url]`}
                            type="hidden"
                            value={banner.mobile_app_url || ``}
                        />
                    </div>
                </>
            )}
        </div>
    );
}
