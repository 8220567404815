import { useState } from "react";

import { type ContentPromotionBasketModel } from "@admin/domain/complexPromotions/content/models/ContentPromotionBasketModel";
import { type ContentPromotionRewardModel } from "@admin/domain/complexPromotions/content/models/ContentPromotionRewardModel";

export type UsePromotionHook = {
    rewardProducts: ReadonlyArray<ContentPromotionRewardModel>;
    basketProducts: ReadonlyArray<ContentPromotionBasketModel>;
    addBasketProduct: () => void;
    deleteBasketProduct: (basketIndex: number) => void;
    addRewardProduct: (isGift: boolean) => void;
    deleteRewardProduct: (rewardIndex: number) => void;
};

export const usePromotionProducts = (): UsePromotionHook => {
    const contentPromotion = window.CMS.contentPromotion || null;

    const [rewardProducts, setRewardProducts] = useState<
        UsePromotionHook[`rewardProducts`]
    >(contentPromotion?.reward_products || []);

    const [basketProducts, setBasketProducts] = useState<
        UsePromotionHook[`basketProducts`]
    >(contentPromotion?.basket_products || []);

    const addBasketProduct = (): void =>
        setBasketProducts([
            ...basketProducts,
            {
                quantity: 1,
            },
        ]);

    const deleteBasketProduct = (basketIndex: number): void =>
        setBasketProducts(
            basketProducts.filter(
                (_, index: number): boolean => basketIndex !== index,
            ),
        );

    const addRewardProduct = (isGift: boolean): void =>
        setRewardProducts([
            ...rewardProducts,
            {
                quantity: 1,
                is_gift: isGift,
            },
        ]);

    const deleteRewardProduct = (rewardIndex: number): void =>
        setRewardProducts(
            rewardProducts.filter(
                (_, index: number): boolean => rewardIndex !== index,
            ),
        );

    return {
        rewardProducts,
        basketProducts,
        addBasketProduct,
        deleteBasketProduct,
        addRewardProduct,
        deleteRewardProduct,
    };
};
