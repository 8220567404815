import React, { useState } from "react";

import { saveNewsArticle } from "@admin/domain/news/api";
import Editor from "@admin/components/Editor";
import ContentBlocks from "@admin/components/Content/ContentBlocks";
import { NewsTextModel } from "@admin/domain/news/models/NewsTextModel";
import { Language } from "@admin/domain/languages/Languages";
import { FileModel } from "@admin/domain/files/model/FileModel";
import PublishedAtInput from "@admin/components/PublishedAtInput";
import Input from "@admin/components/Input";
import MultiLanguages from "@admin/components/MultiLanguage";
import TextArea from "@admin/components/TextArea";
import LoadingImageInput from "@admin/components/LoadingImageInput";
import Select from "@admin/components/Select";
import CheckBox from "@admin/components/CheckBox";
import PageSeoParamsEditor from "@admin/components/PageSeoParamsEditor";

const LIST_URL = `/admin/news` as const;

const NewsArticleEditor: React.FC = () => {
    const article = window.CMS.news || null;
    const admins = window.CMS.admins || [];

    const texts = article?.texts || [];
    const textMap = new Map(
        texts.map((text: NewsTextModel): [Language, NewsTextModel] => [
            text.language,
            text,
        ]),
    );

    const [previewImage, setPreviewImage] = useState<FileModel | null>(
        article?.preview_image || null,
    );

    const execute = async (body: FormData): Promise<void> => {
        await saveNewsArticle(article, body);
    };

    return (
        <Editor backUrl={LIST_URL} onSubmit={execute}>
            <PublishedAtInput defaultValue={article?.published_at} />

            <Input
                label="Символьный код для URL"
                name="path"
                defaultValue={article?.path}
            />

            <MultiLanguages
                languageElement={(language: Language): React.JSX.Element => (
                    <>
                        <input
                            type="hidden"
                            name={`texts[${language}][language]`}
                            defaultValue={language}
                        />

                        <Input
                            label="Заголовок страницы"
                            name={`texts[${language}][title]`}
                            defaultValue={textMap.get(language)?.title}
                        />

                        <TextArea
                            label="Аннотация"
                            name={`texts[${language}][annotation]`}
                            defaultValue={textMap.get(language)?.annotation}
                        />
                    </>
                )}
            />

            <LoadingImageInput
                label="Изображение-превью"
                src={article?.preview_image?.path}
                onChange={setPreviewImage}
                height={300}
            />
            <input
                type="hidden"
                name="preview_image_id"
                value={previewImage?.id || ``}
            />

            <Select
                label="Автор"
                name="author_id"
                options={admins}
                defaultValue={article?.author?.id}
                required
            />

            <CheckBox
                label="Скрыть / Показать"
                name="is_visible"
                defaultChecked={!article || article.is_visible}
            />

            <CheckBox
                label="Показывать для МП"
                name="is_visible_for_mobile_app"
                defaultChecked={!article || article.is_visible_for_mobile_app}
            />

            <CheckBox
                label="Показывать для сайта"
                name="is_visible_for_site"
                defaultChecked={!article || article.is_visible_for_site}
            />

            <PageSeoParamsEditor />

            <ContentBlocks blocks={article?.blocks} />
        </Editor>
    );
};

export default NewsArticleEditor;
